import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: '실내외 건축 구조 및 인프라의 디지털화를 통해 건물 관리와 계획을 혁신적으로 향상시킵니다. 이는 건축 설계, 시설 관리, 안전 모니터링에 중요한 정보를 제공합니다.',
  keywords: 'Digital Twin, Digital Twins, 디지털트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: '디지털트윈 - 건물 및 실내정보 구현 :: 오브로 블로그 ',
  ogUrl: 'https://orbro.io/blog/digitaltwin-buildings',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '디지털트윈 - 건물 및 실내정보 구현',
  date: '2023-12-26',
  tag1: '#디지털트윈',
  tag2: '#디지털빌딩',
  tag3: '#디지털시티',
  tag4: '#가상현실',

  // <br/>
  text: [
    {
      head: '디지털트윈 - 건물 및 실내정보 구현',
      subHead: '',
      desc: 'ORBRO는 건물 및 실내 정보를 혁신적으로 구현하여 건물 관리와 계획을 향상시킵니다. 이를 위해 건축 구조 및 인프라를 디지털화하고, 다양한 정보를 통해 건축 설계, 시설 관리, 안전 모니터링에 필요한 핵심 정보를 제공합니다.<br/><br/>고해상도 이미지와 3D 모델링을 통해 건물의 외관과 구조적 특징을 상세하게 표현하며, 실내 맵은 건물 내부의 레이아웃을 정밀하게 시각화합니다. 이러한 정보는 건물의 시각적 표현과 구조를 명확하게 이해하는 데 도움이 됩니다.<br/><br/>또한 건물 내 인프라 정보 표현, 실시간 장비 상태 확인, 외부 장비 연동은 건물 관리, 유지 보수, 자동화에 중요한 역할을 합니다. 이를 통해 건물 운영 및 관리에 필요한 데이터를 실시간으로 확인하고 효율적으로 관리할 수 있습니다.',
      dot: ''

    },
    {
      youtube: 'https://www.youtube.com/embed/KkvO62eClcU?si=AQmNMfH6nmQ5Aa3Z',
      head: '',
      subHead: '건물의 상세 그래픽 표현',
      desc: 'ORBRO는 고해상도 이미지와 첨단 3D 모델링 기술을 활용하여 건물의 외관과 구조적 특징을 상세하게 표현합니다. 이는 건축가, 엔지니어, 도시 계획가들이 건물의 디자인과 기능성을 평가하는 데 중요한 역할을 합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '실내 맵 표현',
      desc: '건물 내부의 레이아웃을 정밀하게 시각화하여 실내 공간의 활용도를 최대화하고, 안전 및 피난 계획을 세우는 데 필수적인 정보를 제공합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '건물 내 인프라 정보 표현',
      desc: 'HVAC 시스템, 전기 및 배관 시스템 등 건물 내부의 중요 인프라를 디지털화하여, 건물 관리 및 유지 보수에 필수적인 정보를 제공합니다.',
    },
    {
      head: '',
      subHead: '실시간 장비 상태 확인',
      desc: '건물 내 장비 및 시스템의 실시간 모니터링을 통해, 장비의 성능 저하나 고장을 빠르게 감지하고, 즉각적인 유지 보수를 진행합니다.',
    },
    {
      head: '',
      subHead: '외부 장비 연동',
      desc: '스마트 기술 및 IoT 장비와의 연동을 통해 건물 관리 시스템을 더욱 지능화하고 자동화합니다.',
    },
    {
      head: '',
      subHead: '개발자 센터 지원',
      desc: '개발자들이 건물 관련 데이터에 접근하고, 사용자 정의 애플리케이션을 개발할 수 있도록 지원함으로써, 건물 관리 및 운영에 혁신적인 솔루션을 도입합니다.',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/enterprise/logistics/asset'
      }
    ]


  }

}