import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/digitaltwin/video/traffic.mp4";

export default {


    helmet: '| 스마트시티 (Smart City) 구축사례',
    desc: '스마트 시티는 현대 도시에서 빠르게 발전하고 있는 개념으로, 최신 기술과 인프라를 활용하여 효율적인 도시 운영을 추구합니다. 센서, IoT 기술, 빅데이터 등을 통해 교통, 에너지, 환경 등 다양한 분야에서 데이터를 수집하고 분석하여 시민의 삶을 향상시키며, 지속가능한 발전을 목표로 합니다.',
    keywords: '스마트시티, 디지털트윈, 디지털 전환, smart city, digital twin, digital transformation, 실시간 위치 추적, rtls, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: '스마트시티 (Smart City) 구축사례 | 디지털트윈 전문, 오브로',
    ogUrl: 'https://orbro.io/blog/example-of-smart-city',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkFVkNhGYhAjBJLQh-TO_MrDW5dZ9e675klRxgK1f4x67wU6ADQnAzVhSAqDCJ5a6mjIlcP3clmtu92HV05SxhzPTDf1NScGbhZaAOxxAakyFJm0bwewGqWjuE1NJoM86xI5RkwtjQZ4WFYc6d0Hrid-pszPZbQGf1gYPMDqDwORsg5wyGW5bNgU7KKeOTMzrkt78Yr5lYcchyCNmpn2NmzJazzotGsmI9T33V6K3T6xqraKPVNfNkg6odqKHbGpbE7knODw-Ps7LNMLRhHIAZIM9lduTSX896wr7cZscafS5TDVi8cSC0ERUhdb3YwCRgKm0YqSsnVcCJcAbu4evdtoUzdMcjZAIfKR_kw7HgXI42LaA4C-PhxW2jQQKyD9UIxoOFPmJFIh6q7vIHbg3k3cAUc2dxW0Z6hfKLB2k10q0aubzcnWFJnEPSibJNaZJA0fslRcEQTOOSq7qH3K-9HiRproHejfmIxqYsRayvAnKXQPSJyG6cmWTKw2ctEcRwcsQV7cMU2H5VMEc-s5BYkjOqQe6yylbCUXzQ8qYVwls2h-mstfcyt2phYijwZXPHjEY0Xd1KSUErYHTSCWx_A1JKCL30IryNxWX6ejXVr4rzx9VOXpS53IYlHEz2WJTk6LjBccSU2u9lAv_gwAiZTmTSoRX658UQv-7kdx_Et7OVcWSg4GdQpFTDS9mF8huuN39CfL2Tm9qVy2j5jAl-gTQ4o4bnPhaHz9NMoaM-qoTdDt4Q5C1ysaxNReONUY8phy-5RgTgHszfiRXjSpTP_fjzv_dGumhP0V_Rf6ndfluYR6Mg1BHCVhSUG62aDV8bGqdS2oYAmGPYgbN99KcLyQgySVflbWa6wC_fOGkLNeso6n_M6C7otFKqjX9XmB3s6GUBTaS3JiqqQJ-N9qGtjKI86za8HY7i9lxAJEh4iY2FSXw3kIW-m6OTvQbenmldQlgE6hB_Fl8rm6ATKKLAhsxwx5fMKD16IV2hBFuQ9wpnIjLhw1XLzIy0R5Pka1Mygfo6no04iZU4LAApDVkphY4k958mwN4tg_wyl3L63jjST8taRHacU0M-4ADiu0tC2vJzWH1XQwiSedBQQTTUMuydsHQCMYlanG9WhyhybHTxRIA7pUX7-wpNqkFiWCrT9TPNbHg-ON5nQrBGyDUZUb5fC-U4EkUN0a-Pe-CHJJ6bZd1m_oCpzdulGMPnK-4_VjWBJAiQRteQ8QTUKNhmQj2yEnZwCJQZZSTpk1IlGzgHiJ_BsIYyD_a3z-ysShhTuE9hG_57y4aIz8Rf6ZsrXTp1OeEHkNkCvjH7ZnbdABz0u_QyshaMMfU7EKVUbH6tIXiym1yOX6upY9M_6JtOTgtlulM87kVzPjPoy47O2YEq7mcAOwMBRmDb6gfW2J0t7PuaFMgTQAi4XiKpM5Pf_NhIK-wNtRCAhcSD9WCAd1CIxJ-CrcG3xn77PYV4NQRe0Oi2KQCyqG3frUstyWuJuSAeYrdYhBRBkDG6OoVG48EshJwneIwytpeURilKCKLLPt64ls45oiR-ZMwMxmdpfsCMct--L=w2010-h1214',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '스마트시티 (Smart City) 구축사례',
    date: '2024-01-09',
    tag1: '#스마트시티',
    tag2: '#디지털트윈',
    tag3: '#디지털전환',
    tag4: '#미래도시',

    // <br/>
    text: [
        {
            head: '스마트시티 (Smart City) 구축사례',
            subHead: '',
            desc: '스마트 시티는 현대 도시에서 빠르게 발전하고 있는 개념으로, 최신 기술과 인프라를 활용하여 효율적인 도시 운영을 추구합니다. 센서, IoT 기술, 빅데이터 등을 통해 교통, 에너지, 환경 등 다양한 분야에서 데이터를 수집하고 분석하여 시민의 삶을 향상시키며, 지속가능한 발전을 목표로 합니다. 스마트 시티는 글로벌 도시들이 혁신과 협력을 통해 미래 도시의 모델로 발전하는 중입니다.<br/><br/>이에 따라, 전 세계의 스마트 시티 구축사례를 아래에서 살펴보겠습니다.',
            dot: ''
        },
        {
            head: '싱가포르 (Singapore) 스마트 시티',
            subHead: '',
            desc: '싱가포르는 스마트 시티 개념을 체계적으로 구현하는 데 선두주자입니다. 도시 내에 설치된 다양한 센서와 IoT 장치는 교통 체증을 모니터링하고 효율적인 교통 관리를 실현합니다. 또한, 스마트 그리드 기술을 통해 에너지 효율을 높이고, 공공시설의 자동화 및 지능형 CCTV 네트워크를 통해 안전성을 강화하고 있습니다.',
            dot: ''
        },
        {
            head: '도쿄 (Tokyo) 스마트 시티',
            subHead: '',
            desc: '도쿄는 도시의 스마트화를 위한 다양한 프로젝트를 추진하고 있습니다. 스마트 조명 및 건물 자동화를 비롯한 미세먼지 측정 센서와 같은 기술을 도입하여 환경을 모니터링하고, 지능형 교통 시스템은 교통 체증을 감소시키며 도시의 효율성을 높이고 있습니다.',
            dot: ''
        },
        {
            head: '바르셀로나 (Barcelona) 스마트 시티',
            subHead: '',
            desc: '바르셀로나는 스마트 그리드, 무선 센서 네트워크, 대중 교통의 디지털화를 통해 도시의 지속가능한 발전을 추구하고 있습니다. 빅데이터 분석을 활용하여 쓰레기 수거 및 에너지 사용량을 최적화하고, 스마트 시티 플랫폼을 구축하여 도시의 다양한 기능을 효율적으로 관리하고자 노력하고 있습니다.',
            dot: ''
        },
        {
            head: '서울 (Seoul) 스마트 시티',
            subHead: '',
            desc: '서울은 스마트 시티 구축을 통해 도시 인프라를 혁신하고 있습니다. 스마트 그리드 구축과 대중 교통의 디지털화는 에너지 효율과 교통 편의성을 높이는 데 기여하고 있습니다. 또한, 스마트 시티 플랫폼을 통해 다양한 데이터를 통합하고 분석하여 시민들의 편의와 안전을 증진시키는 데 주력하고 있습니다.',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/digital-twin',
            videoDesc: '(영상을 누르면, 오브로 디지털트윈 페이지로 이동합니다.)',
            head: '스마트 시티 구축 방법',
            subHead: '',
            desc: '스마트 시티를 구축하는 데에는 다양한 기술과 전략이 필요합니다. 아래는 스마트 시티를 구축하는 주요 방법에 대한 간략한 설명입니다.',
            dot: {
                a: '<strong> • 인프라 개선 : </strong>스마트 시티를 위해서는 강화된 디지털 인프라가 필요합니다. 고속 브로드밴드 및 5G 네트워크 구축을 통해 실시간 데이터 전송이 가능하도록 하고, 인터넷 연결 가능한 장치들이 효율적으로 작동할 수 있도록 합니다.',
                b: '<strong> • 빅데이터 및 인공지능 활용 : </strong>대량의 데이터를 수집하고 분석하여 도시의 운영을 최적화하는 것이 핵심입니다. 빅데이터와 인공지능 기술을 활용하여 교통, 환경, 에너지 등 다양한 분야에서 발생하는 데이터를 실시간으로 분석하고 예측합니다.',
                c: '<strong> • 스마트 센서 및 IoT 기술 도입 : </strong>도시 내에 다양한 센서 및 IoT 기술을 도입하여 교통, 환경, 에너지 등에 대한 데이터를 실시간으로 수집합니다. 이를 통해 시민들의 편의성을 증진하고 도시 운영을 최적화합니다.',
                d: '<strong> • 스마트 에너지 및 그리드 시스템 : </strong>신재생 에너지의 도입과 스마트 그리드 시스템을 구축하여 에너지 효율을 높이고, 에너지 소비를 최적화합니다. 스마트 에너지 관리 시스템을 통해 에너지 사용량을 최적화하고 지속 가능한 에너지 소비를 도모합니다.',
                e: '<strong> • 대중 교통의 디지털화 : </strong>대중 교통 시스템을 디지털화하여 실시간 정보 제공, 예약 시스템, 자율주행 차량 등을 도입하여 교통 체증을 완화하고 환경에 미치는 영향을 최소화합니다.',
                f: '<strong> • 스마트 시티 플랫폼 구축 : </strong>다양한 데이터를 효율적으로 수집, 관리하고 시민들과 기업들에게 열린 데이터를 제공하는 스마트 시티 플랫폼을 구축합니다. 이는 도시의 운영을 최적화하고 시민들의 참여를 유도하는데 중요한 역할을 합니다.',
                g: '<strong> • 사이버 보안 강화 : </strong>스마트 시티에서는 다양한 데이터가 실시간으로 교환되므로 사이버 보안은 핵심 과제입니다. 강력한 보안 시스템을 도입하여 시스템을 보호하고 시민들의 개인정보를 안전하게 관리합니다.',
            }
        },
        {
            head: '스마트 시티의 장점',
            subHead: '효율적인 도시 운영',
            desc: '스마트 시티는 실시간 데이터 수집과 분석을 통해 교통, 에너지, 수자원, 쓰레기 처리 등 도시 운영을 효율적으로 관리할 수 있습니다. 이로 인해 도시의 기능이 최적화되며 시민들의 일상이 원활하게 진행됩니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '교통 흐름 최적화',
            desc: '대중 교통의 디지털화, 자율주행 기술 도입 등으로 교통 체증이 감소하고 교통 흐름이 최적화됩니다. 이는 시민들의 이동을 원활하게 하고 환경에 미치는 부정적인 영향을 감소시킵니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '에너지 효율과 지속 가능성',
            desc: '스마트 시티는 스마트 그리드, 신재생 에너지, 에너지 효율화 기술 등을 통해 에너지 소비를 최적화하고 지속 가능한 에너지 사용을 촉진합니다. 이로써 환경에 대한 부담을 줄이고 에너지 비용을 절감할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '시민 참여 촉진',
            desc: '스마트 시티는 시민들이 도시 운영에 참여할 수 있는 다양한 수단을 제공합니다. 개방적인 데이터, 모바일 애플리케이션 등을 통해 시민들은 도시의 의사결정에 참여하고 더 나은 환경을 조성할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '안전성 향상',
            desc: '스마트 시티는 지능형 CCTV, 응급 상황 대응 시스템, 예방적인 안전 조치 등을 도입하여 도시의 안전성을 향상시킵니다. 실시간 모니터링과 빅데이터 분석을 통해 범죄 예방과 긴급 상황 대응 능력이 향상됩니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '경제적 발전',
            desc: '스마트 시티 구축은 새로운 기술과 산업을 유치하며 현지 경제를 활성화시킬 수 있습니다. 스마트 시티 기술을 개발하고 적용하는 기업들은 일자리를 창출하고 경제적 발전을 이끌어내는 역할을 합니다.',
            dot: ''
        },
        {
            head: '스마트 시티의 전망',
            subHead: '',
            desc: '스마트 시티의 미래는 더욱 혁신적이고 연결된 도시 환경으로 발전할 것으로 전망됩니다. <br/>빅데이터, 인공지능, 사물인터넷 등의 첨단 기술이 통합되어 도시 운영을 효율적으로 관리할 것으며, 이에 따라 교통 체증 완화, 에너지 효율화, 환경 보호 등 다양한 측면에서 도시의 지속 가능성이 강조될 것입니다. 시민들은 개인화된 서비스와 높은 참여도를 경험하게 되어, 도시 생활의 편의성이 더욱 향상될 것으로 예상됩니다. 또한, 글로벌 수준에서 스마트 시티 구축의 중요성이 부각되며, 전 세계적으로 이러한 도시 모델을 참고하고자 하는 움직임이 늘어날 것입니다. 스마트 건축 및 도시 디자인의 진화도 기대되며, 시민들은 더욱 안전하고 지능적인 도시에서의 생활을 즐길 것으로 전망됩니다.',
            dot: ''
        },
        {
            linkText: '오브로 월드 체험하기',
            link: 'https://home.orbro.io',
            head: '',
            subHead: '',
            desc: '',
            dot: '',
        },
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            }
        ]

    }


}