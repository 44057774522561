import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4"

export default {


    helmet: '| NVR이란?',
    desc: 'NVR이란 Network Video Recorder (NVR)로, IP 카메라나 네트워크 카메라로부터 전송되는 디지털 비디오 스트림을 받아들여 저장하는 역할을 하는 장치입니다.',
    keywords: 'nvr, ip, ptz, rfid, nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'NVR이란? | 위치추적과 디지털트윈 전문 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/nvr',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmL4Stf6lklybaH3OesTetv_ifx8JMPoVd04awuiZ4-02dNTkyV_aHj1fMf2KnR4WC3V8BIU0tp4nnqyMrJ1H3wq2E2MR1v4SC2aV7X0uQuYIPhuSRRExRshekWS5QDY7QauCkENm0TmU7Nd5ybqI8YS9VYjU47WZpMCiPjMURQQed_nvkn0AhE2Yun2VlI0rqIh4wdfyfrV8KCYwxBiuTJu0JyormxuEVYxQX_zzHdRwQHQRmGDKitVvEFslYvVFcRU7zmk1H5su0Nbh0OH-2sr7PH92g1JPUqxCgUlBTxhpLdrL_E6UqQka9fnmGxwy-M42lPeCHvlJ3pjxezqOwNVArbkw4Gtzl8eeAr8IbZ4rZ9wTIRi05-bGw29_Cz-zxphiYy_phH52YKkFQP5NEH11pxn9SDr8Xgj6EZqd95NgevWxTMMi4O1A6iOydRW_EOn4lix025cQB5RehRr0gZlPIVwj_YEku0bG-va9JpG6rAPHUbj_AELcIbLToKJnyJ-DYE-gTASAaIamUNLzKNoXmIGa9phep5L_nIkwEHdA-qzT5dyrmhQDpc4d1ruaYFLHWV-bb-ETaORk4Sdr3Y-aqznGhdsafAvy0lGxYU_DMVM_emH2uyyVFq71xraelVNooi5Qoe-PsPuj8BnSOTSZeLGWmuglDlrgmTuEpFNqsVou185a6VQJO6s71py93y5OpnwMtj3FmATd_eeN9ZY43KUQ2iLDXw125g3abOP4HE4YnRCCsMjz3Vray4TP2_m_9QoRm8OuJBGF4A2CbGJ2M8oLmEQ1Mc3VpNZ9WwdYkGXpBY8yCLEuDwxqeKNMUTkJwbr4YNdpja17MLWg9XHFoVNlG64qk-VkBi4IFSFMDx4LaZD9XsGttmU9bBIIT2jJldP5PYCfTjffwwstwZ4VNG-3bxzqfsCZ3CGeihhAYm1knV-ullCOomsYIaweJwLK2MMRFkxlpRPuT0bbJnFWuCdMoydkNYf3JMh0Bi2kok0mrqpGDEqywp9sccVcp8wZkG1XRVZJPJjwSd2kbiJNR2DfwoneK2ivKwpqhT5kRrl9cpuclYrRgIqnlOuce-kuOj0dJMTozXERXeWUzhXWX4EFywOc-1IoTXFtxiPQL76CyCsHtYImgIoegeus-2_6ZQZtpmxvYL33d95jUw3J_yUPkWETDzHro5RGKOD5RDM41CMUEokOzoWbxmbO0MufhfRGY_PnM5W16XGqSZGRTwenay9yrEB3IPluMmrdpervFylEfCPYJyutGFQigW5iY-tvjM8JVOyzk3OqLzqq9LjnJAyhkCJZ2ZGtPKZPvMvOSlovrdc93ztDGD6dorfc1SWfaxDOi4kQduozkGE8GSEkHRte9hFjdoWMGblqD1Lu4ADPGwr2546UPK93UJbiCtj2SUaSVV8E5R1XD3cy4mKcZ27rsDh_s_os0RaZ8lEQfPHZMmphlOnC2S5AnuXnZqqQWPQJD9b2v8kYV9CS3vW8_Gr3zLg1jcDqGfeBbzTg3g3rtTUPi885LTBUpT_NK2a5Ux-HCwPu1da_Cen1_2-oto=w1984-h1208',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'NVR이란?',
    date: '2024-01-12',
    tag1: '#nvr',
    tag2: '#카메라',
    tag3: '#보안카메라',
    tag4: '#위치추적',

    // <br/>
    text: [
        {
            head: 'NVR이란?',
            subHead: '',
            desc: 'NVR이란 Network Video Recorder (NVR)로, IP 카메라나 네트워크 카메라로부터 전송되는 디지털 비디오 스트림을 받아들여 저장하는 역할을 하는 장치입니다. 주로 감시 및 보안 시스템에서 사용됩니다. 이는 기존의 디지털 비디오 레코더 (DVR)와 구별되며, 주로 디지털 카메라와 함께 작동합니다. <br/><br/>NVR은 주로 네트워크를 통해 다양한 위치에서 비디오를 관리하고 접근할 수 있도록 설계되었습니다. 이것은 특히 큰 규모의 시설이나 여러 지역에 걸친 감시가 필요한 경우에 유용하며, 더 높은 해상도 및 고급 기능을 지원하여 뛰어난 성능을 제공합니다. 기능적으로는 NVR은 여러 카메라로부터 동시에 비디오 스트림을 받아들이고, 이를 안전하게 저장합니다. 저장된 비디오는 나중에 검색, 재생, 또는 분석을 위해 사용될 수 있습니다. 또한, 많은 NVR 시스템은 이메일 알림, 원격 모니터링, 또는 모션 감지와 같은 고급 기능을 제공하여 효율적인 감시를 가능케 합니다.<br/>이러한 특성으로 NVR은 보안 요구사항이 높은 환경에서 널리 사용되며, 네트워크 기반의 현대적인 감시 시스템의 핵심 구성 요소 중 하나로 자리매김하고 있습니다.',
            dot: ''
        },
        {
            video: video,
            link: '/enterprise/retail/exhibition',
            head: 'NVR 기능',
            subHead: '카메라 관리',
            desc: '',
            dot: {
                a: ' • 다수의 디지털 카메라를 지원하고 효과적으로 관리할 수 있습니다.',
                b: ' • 카메라 그룹화 및 개별 설정을 통해 유연한 구성이 가능합니다.',
            }
        },
        {
            head: '',
            subHead: '고해상도 및 압축',
            desc: '',
            dot: {
                a: ' • 고해상도 비디오를 지원하며, 압축 알고리즘을 통해 스토리지 효율성을 유지합니다.',
                b: ' • H.264, H.265 등의 압축 표준을 지원하여 대역폭과 스토리지 사용을 최적화합니다.',
            }
        },
        {
            head: '',
            subHead: '네트워크 연결',
            desc: '',
            dot: {
                a: ' • 네트워크를 통해 다양한 위치에서 실시간 또는 기록된 비디오에 접근할 수 있습니다.',
                b: ' • 원격 관리 및 모니터링이 가능하며, 다중 사용자 지원으로 효율적인 협업이 가능합니다.',
            }
        },
        {
            head: '',
            subHead: '알림 및 이벤트 관리',
            desc: '',
            dot: {
                a: ' • 모션 감지, 비디오 손실, 카메라 커버, 일시 중단 등과 같은 이벤트를 감지하고 관리할 수 있습니다.',
                b: ' • 알림 및 경고를 설정하여 중요한 사건에 대한 신속한 대응이 가능합니다.',
            }
        },
        {
            head: '',
            subHead: '비디오 검색 및 재생',
            desc: '',
            dot: {
                a: ' • 특정 시간대 또는 이벤트에 기반하여 비디오를 검색하고 재생할 수 있습니다.',
                b: ' • 비디오 분할, 확대/축소, 프레임 단위로의 정밀한 조절이 가능합니다.',
            }
        },
        {
            head: '',
            subHead: '저장 관리',
            desc: '',
            dot: {
                a: ' • 스토리지를 효율적으로 관리하고, 다양한 저장 옵션을 제공합니다.',
                b: ' • 일정에 따라 오래된 비디오를 자동으로 삭제하거나 다른 저장 장치로 이전할 수 있습니다.',
            }
        },
        {
            head: '',
            subHead: '다양한 보안 기능',
            desc: '',
            dot: {
                a: ' • 사용자 권한 관리를 통해 다양한 수준의 접근 권한을 설정할 수 있습니다.',
                b: ' • 안전한 원격 액세스를 위한 암호화 기술을 지원합니다.',
            }
        },

        {
            head: 'NVR 장점',
            subHead: '고해상도 및 화질',
            desc: 'NVR은 주로 디지털 카메라와 함께 사용되어 고해상도 및 높은 품질의 비디오를 지원합니다. 이는 세부사항을 뚜렷하게 보여주며, 필요에 따라 더 정확한 감시가 가능하게 합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '네트워크 기반 구조',
            desc: 'NVR은 네트워크를 통해 카메라와 통신하므로, 비디오를 원격 지역에서 쉽게 액세스할 수 있습니다. 이것은 실시간 감시 및 비디오 검색을 위한 편리한 방법을 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '유연성 및 확장성',
            desc: 'NVR 시스템은 다수의 카메라를 지원하며, 필요에 따라 시스템을 쉽게 확장할 수 있습니다. 새로운 카메라를 추가하거나 시스템을 업그레이드하는 것이 간단하므로, 보안 요구의 변화에 유연하게 대응할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '원격 모니터링 및 알림 기능',
            desc: 'NVR은 원격에서 비디오 모니터링을 지원하며, 모션 감지 또는 이메일 알림과 같은 고급 기능을 통해 사건이 감지되면 즉시 알림을 받을 수 있습니다. 이는 빠른 대응과 효과적인 관리를 도와줍니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '비디오 검색 및 분석 기능',
            desc: '일부 NVR 시스템은 비디오 검색 및 분석 기능을 제공하여 특정 이벤트를 감지하고 필요한 정보를 빠르게 찾을 수 있습니다. 이는 조사나 감시 데이터의 효율적인 활용을 지원합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '효율적인 저장 및 관리',
            desc: 'NVR은 효율적인 비디오 저장 및 관리 기능을 제공합니다. 스토리지를 효율적으로 활용하며, 필요한 기간 동안의 비디오 기록을 안정적으로 보관합니다.',
            dot: ''
        },


        {
            head: 'NVR 과 DVR 차이',
            subHead: '카메라 연결 및 신호 처리',
            desc: 'NVR은 주로 디지털 카메라와 함께 사용되어 고해상도 및 높은 품질의 비디오를 지원합니다. 이는 세부사항을 뚜렷하게 보여주며, 필요에 따라 더 정확한 감시가 가능하게 합니다.',
            dot: {
                a: '<strong> • DVR : </strong>DVR은 주로 아날로그 카메라와 함께 사용되며, 아날로그 신호를 디지털로 변환하여 처리합니다.',
                b: '<strong> • NVR : </strong>NVR은 디지털 카메라 또는 IP 카메라와 함께 사용되며, 이미 디지털 형태로 전송되는 데이터를 처리합니다.',
            }
        },
        {
            head: '',
            subHead: '비디오 품질 및 기능',
            desc: '',
            dot: {
                a: '<strong> • DVR : </strong>일반적으로 더 낮은 비디오 품질을 제공하며, 고급 기능이 제한될 수 있습니다.',
                b: '<strong> • NVR : </strong>높은 해상도와 고급 기능을 제공하여 향상된 비디오 품질과 다양한 기능을 지원합니다.',
            }
        },
        {
            head: '',
            subHead: '카메라 지원 및 해상도',
            desc: '',
            dot: {
                a: '<strong> • DVR : </strong>주로 낮은 해상도의 아날로그 카메라를 지원하며, 고해상도 영상에는 적합하지 않을 수 있습니다.',
                b: '<strong> • NVR : </strong>디지털 카메라를 지원하며, 고해상도 및 멀티메가픽셀 카메라와의 호환성이 뛰어납니다.',
            }
        },
        {
            head: '',
            subHead: '네트워크 연결',
            desc: '',
            dot: {
                a: '<strong> • DVR : </strong>주로 로컬 네트워크에 연결되어 사용되며, 원격 액세스가 더 제한적일 수 있습니다.',
                b: '<strong> • NVR : </strong>네트워크를 통해 데이터를 송수신하므로, 원격 지역에서 쉽게 액세스할 수 있습니다.',
            }
        },
        {
            head: '',
            subHead: '확장성',
            desc: '',
            dot: {
                a: '<strong> • DVR : </strong>일반적으로 특정 수의 카메라만 지원하며, 시스템의 확장이 더 어려울 수 있습니다.',
                b: '<strong> • NVR : </strong>다수의 카메라를 지원하며, 시스템을 필요에 따라 쉽게 확장할 수 있습니다.',
            }
        },
        {
            head: '',
            subHead: '설치 및 유지보수',
            desc: '',
            dot: {
                a: '<strong> • DVR : </strong>아날로그 시스템은 설치 및 유지보수가 간단할 수 있지만, 전체 시스템의 성능은 낮을 수 있습니다.',
                b: '<strong> • NVR : </strong>디지털 시스템은 복잡할 수 있지만, 고급 기능 및 성능을 제공하며 유지보수가 편리합니다.',
            }
        },
    ],

    link: {
        page: 'camera',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.indoor.peopleTracking.title,
                title: productPurchase.purchase.indoor.peopleTracking.title,
                serial: productPurchase.purchase.indoor.peopleTracking.serial,
                desc: '카메라를 통하여 실내 사람들의 이동경로를 분석 할 수 있습니다.',
                img: productImg.product.peopleTracking.thumbnail
            },
        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/enterprise/office/asset'
            }
        ]

    }


}