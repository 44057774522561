
import { outdoorOff, outdoorOn, indoorOn, indoorOff, IconAlarm, IconAnalyze, IconSDK, IconExcel, IconImage, IconTracking, IconLocation, IconHeatmap } from '@assets/images/main/index';
import {
    CameraFunctions1D, CameraFunctions1M, CameraFunctions1T,
    CameraFunctions2D, CameraFunctions2M, CameraFunctions2T,
    CameraFunctions3D, CameraFunctions3M, CameraFunctions3T,
    CameraFunctions4D, CameraFunctions4M, CameraFunctions4T,
    CameraFunctions5D, CameraFunctions5M, CameraFunctions5T,
    CameraFunctions6D, CameraFunctions6M, CameraFunctions6T,
    CameraFunctions7D, CameraFunctions7M, CameraFunctions7T
} from '@assets/images/main/camera/main/functions';
import { IconOsOff, IconOsOn } from '@assets/images/main/icon';


export default {

    mainPage: {
        product: {
            title: '제품 구성 알아보기',
            desc: '혁신적인 위치추적 제품 구성을 확인 해 보세요.',
            outdoor: {
                title: '실외 카메라',
                desc: '카메라를 통하여 사람, 차량, 등 다양한 객체의<br/>위치 및 이상 행동을 감지하고 관리 할 수 있습니다.',
            },
            indoor: {
                title: '실내 카메라',
                desc: '카메라를 통하여 실내 사람들의<br/>이동경로를 분석 할 수 있습니다.',

            }
        }

    },

    banner: {
        title: '오브로 AI를 이용한 카메라 위치 추적',
        desc: '오브로 AI 카메라는 사람, 차량 등 다양한 객체의 위치 및 이상행동을 관리합니다.',

        schemeTitle: '오브로가 제안하는 카메라 시스템.',
        schemeDesc: '결정하는데 도움이 필요하신가요? ',
        schemeDesc2: '로 연락 주시면<br/>출입 전문가가 도와드리도록 하겠습니다.',
        phone: '1522-9928',
    },

    bannerData: [
        {
            icon: indoorOn,
            iconInv: indoorOff,
            image: '',
            iconText: '실내 카메라',
            sub: '',
            link: '/camera/indoor'
        },
        {
            icon: outdoorOn,
            iconInv: outdoorOff,
            image: '',
            iconText: '실외 카메라',
            sub: 'New',
            link: '/camera/outdoor'
        },
        {
            icon: IconOsOn,
            iconInv: IconOsOff,
            image: '',
            iconText: 'ORBRO OS',
            sub: 'New',
            link: '/os'
        },

    ],


    video: {
        title: 'ORBRO 인공지능 카메라 시스템',
        desc: [
            { text: '야외 공간에 있는 차량과 사람의 위치 정보를 수집하고 분석합니다.' },
            { text: '실내 공간에서 이동하는 사람들의 위치 정보를 파악합니다.' },
            { text: '공용 CCTV와 같은 기존에 설치된 카메라 인프라와 연동이 가능합니다.' },
            { text: '필요한 카메라 장비를 손쉽게 추가하여 사용할 수 있습니다.' },
        ]

    },


    func: {
        title: '카메라 기능 알아보기',
        desc: '카메라의 다양한 기능을 확인해 보세요.',

        data: [
            {
                img: IconHeatmap,
                title: '히트맵',
                desc: '실시간으로 데이터를 누적하여 시간대별 히트맵을 제공합니다.'

            },
            {
                img: IconLocation,
                title: '실시간 위치 추적',
                desc: 'Orbro의 위치추적 시스템은 실시간으로 위치를 추적합니다. 정말 분석된 기능으로 생동감있게 현장을 모니터링 할 수 있습니다.'

            },
            {
                img: IconAnalyze,
                title: '분석 도구',
                desc: '수많은 위치 데이터를 모아 데이터 통계와 인사이트를 얻을 수 있습니다.'

            },
            {
                img: IconAlarm,
                title: '알림 설정',
                desc: '사람이 출입 하면 안되는 구역에 위험 구역을 설정하여 알림을 받을 수 있습니다.'

            },
            {
                img: IconImage,
                title: '객체 인식',
                desc: '촬영한 이미지를 분석하여 여러종류의 객체를 인식합니다.'

            },
            {
                img: IconTracking,
                title: 'SDK 제공',
                desc: '태그단말기 없이 공간을 분석하고 이상 징후를 감지 합니다.'

            },
            {
                img: IconExcel,
                title: '엑셀 다운로드',
                desc: '필요한 데이터를 정제하여 엑셀 데이터를 다운 받을 수 있습니다.'

            },
            {
                img: IconSDK,
                title: 'SDK 제공',
                desc: 'Orbro의 위치 추적시스템은 SDK를 제공하여 고객사의 니즈에 맞는 개발을 지원합니다.'

            },
        ]

    },


    functions: {

        title: 'AI기술을 이용한 디지털트윈 위치추적 기술',
        desc: '오브로의 AI 카메라 시스템은 높은 정확도와 안정성을 자랑합니다.<br/>실내와 실외에서 사람과 차량의 위치를 파악하고 분석하여 사용자에게 필요한 데이터를 제공합니다.',

        line: 3,
        card: [
            {
                size: '3',
                tag: '핵심기능',
                title: '영상으로 감지된 물체가<br/>지도에서 실시간으로 표현',
                label: '관리자',
                img: {
                    desktop: CameraFunctions1D,
                    tablet: CameraFunctions1T,
                    mobile: CameraFunctions1M
                },
            },
            {
                color: '#fff',
                size: '1',
                tag: '모바일 서비스',
                title: '모든 공간,<br/>모바일 사용성 제공',
                label: '공통 제공',
                img: {
                    desktop: CameraFunctions2D,
                    tablet: CameraFunctions2T,
                    mobile: CameraFunctions2M
                },
            },
            {
                size: '2',
                tag: '기존 CCTV 호환',
                title: '모든 영상정보,<br/>모두 호환',
                label: '관리자',
                img: {
                    desktop: CameraFunctions3D,
                    tablet: CameraFunctions3T,
                    mobile: CameraFunctions3M
                },
            },
            {
                size: '2',
                tag: '사람, 차량 객체인식',
                title: '사람과 차량검출,<br/>높은 인식률',
                label: '관리자',
                img: {
                    desktop: CameraFunctions4D,
                    tablet: CameraFunctions4T,
                    mobile: CameraFunctions4M
                },
            },
            {
                size: '2',
                tag: '현장서버 구축',
                title: '가장 확실하고<br/>안정적인 보안체계',
                label: 'On-Premise',
                img: {
                    desktop: CameraFunctions5D,
                    tablet: CameraFunctions5T,
                    mobile: CameraFunctions5M
                },
            },
            {
                size: '1',
                tag: '야외 주차장 등 엔터프라이즈급 설치',
                title: '대규모 주차시설에도<br/>적용 가능한 설치',
                label: '엔터프라이즈',
                img: {
                    desktop: CameraFunctions6D,
                    tablet: CameraFunctions6T,
                    mobile: CameraFunctions6M
                },
            },
            {
                size: '3',
                tag: '대시보드',
                title: '데이터 인사이트,<br/>데이터 기반의 의사결정',
                label: '관리자',
                img: {
                    desktop: CameraFunctions7D,
                    tablet: CameraFunctions7T,
                    mobile: CameraFunctions7M
                },
            },
        ]
    },



    FAQ: [
        {
            title: '서버 한대에 최대 몇대의 카메라 설치가 가능한가요?',
            description: '카메라를 이용한 위치추적은 주로 컴퓨터 비전 기술을 활용하여 객체를 인식하고, 해당 객체의 위치를 추적하는 기술입니다. 이를 위해 카메라로 촬영한 이미지를 실시간으로 분석하여 객체를 감지하고, 이후에 객체의 움직임을 추적합니다.<br/><br/>이미지은 분석 상당한 컴퓨팅 파워를 요구하는 작업입니다. 각 카메라가 촬영한 영상을 처리하고 객체추적을 위한 계산을 수행하는데 적절한 성능을 유지하기위해 서버 한대당 최대 8대의 카메라를 권장합니다.<br/><br/>'
        },
        {
            title: 'NVR은 필수로 구매 해야하나요?',
            description: '카메라를 이용한 위치추적에 NVR은 필수가 아닙니다.<br/><br/>다만, ORBRO OS는 이미지를 분석해 객체의 위치를 추적하는 기능에 최적화된 소프트웨어로, NVR의 고유 기능을 제공하지 않습니다.<br/><br/>실시간으로 CCTV 영상을 모니터링 하고, 영상을 저장해 활용하고 싶으시다면 NVR을 사용하시는 것을 권장드립니다.<br/><br/>',
        },
        {
            title: '기존 CCTV를 사용하여 시스템 운영이 가능한가요?',
            description: '네, 기존에 설치된 CCTV를 활용하여 시스템 운영이 가능합니다.<br/><br/>다만 기존 CCTV의 성능이 ORBRO OS에서 제안하는 스펙과 동일해야 하며, 일반적으로 구축되어있는 CCTV는 위치추적이 목적이 아닌, 보안을 목적으로 설치되었기 때문에, 보다 완성도 있는 위치추적 인프라를 구축하고 싶으시다면, 별도로 설치하는 것을 권장드립니다.<br/><br/>',
        },
        {
            title: '자사 서비스 데이터 연동이 가능한가요?',
            description: '네, 카메라를 이용한 위치추적 데이터는 ORBRO OS라는 소프트웨어를 이용해 위치를 분석하고 저장하게 되어있으며, 원하신다면 ORBRO OS에서 관리하고 있는 데이터를 호출해 데이터를 연동하는 것이 가능합니다.<br/><br/>',
        }
    ],





}