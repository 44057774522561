

export default {


  data: [

    {
      title: '건설',
      page: [
        { name: '작업자 안전관리', link: '/enterprise/construction/safety' },
        { name: '건설자재 위치 추적 및 관리', link: '/enterprise/construction/asset' },
        { name: '중장비 관리', link: '/enterprise/construction/equipment' },
      ]
    },
    {
      title: '물류',
      page: [
        { name: '재고 위치 추적 및 관리', link: '/enterprise/logistics/asset' },
        { name: '창고 작업자 안전 관리', link: '/enterprise/logistics/safety' },
      ]
    },
    {
      title: '오피스',
      page: [
        { name: '자산관리', link: '/enterprise/office/asset' },
      ]
    },
    {
      title: '의료',
      page: [
        { name: '환자 위치 추적', link: '/enterprise/medical/patient' },
        { name: '의료 폐기물 및 자산 관리', link: '/enterprise/medical/waste' },
      ]
    },
    {
      title: '제조',
      page: [
        { name: '실시간 공정 관리', link: '/enterprise/manufacturing/process' },
        { name: '실시간 재고 관리', link: '/enterprise/manufacturing/asset' },
      ]
    },
    {
      title: '리테일',
      page: [
        { name: '전시관 관람객 분석', link: '/enterprise/retail/exhibition' },
      ]
    },
    {
      title: '다운로드',
      page: [
        { name: 'iOS', link: 'https://apps.apple.com/us/app/orbro-service/id1673356996', backLink: '1' },
        { name: 'Android', link: 'https://play.google.com/store/apps/details?id=com.orbro.mobile', backLink: '1' },
      ]
    },
    {
      title: '지원',
      page: [
        { name: '기술', link: '/enterprise/technology/uwb' },
        { name: '블로그', link: '/blog' },
        { name: '영업문의', link: '/request' },
        { name: '회사소개', link: '/company' },
      ]
    },
    {
      title: '파트너',
      page: [
        { name: '오브로 홈', link: '/' },
        { name: '콩체크', link: 'https://check.kong-tech.com/', backLink: '1' },
        { name: '플루토콘', link: 'https://plutocon.io/', backLink: '1' },
      ]
    },
    {
      title: '고객 센터',
      page: [
        { name: '전화 : 1522 - 9928' },
        { name: '이메일 : pr@orbro.io' },
      ]
    },

  ]


}