import React, { useEffect, useMemo, useRef, useState } from 'react';
import product from '@src/lang/ko/main/product/productImg';
import {
  StyledProductImgBig, StyledProductImgSide, StyledProductImgBox,
  StyledProductBtnOptions, BtnLabel, StyledProductBtn, StyledProductImgBig2,
  StyledProductImgOutlook, DownloadLayout, SpacingBtn, DownloadRow, PDFimg, DownloadDate, DownloadPara, YoutubeIframe
} from "./Product.style";
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Spacing } from '@pages/CommonStyle/common.style';
import { StyledHeading, StyledParagraph } from '@components/Text';
import { IconPDF } from '@assets/images/main/icon';
import 'firebase/storage';
import videoOut from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4";
import videoIn from "@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4";
import videoBLE from "@src/assets/images/solution/manufacturingAsset/video/rtlsVideo.mp4";
import videoUWB from "@src/assets/images/solution/medicalPatient/video/rtlsVideo.mp4";


import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { VideoBox, VideoPlay } from '../Video/Video.style';
import i18n from '@pages/i18n';
import { Div100flex, DivAllCenter } from '@pages/CommonStyle/commonDiv.style';

const firebaseConfig = {
  apiKey: 'AIzaSyAF1a8WJ-QZuBBwY8VamSK9MlrqkKeMVh4',
  authDomain: 'orbro-webpage.firebaseapp.com',
  projectId: 'orbro-webpage',
  storageBucket: 'orbro-webpage.appspot.com',
  messagingSenderId: '',
  appId: '',
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);



interface ProductProps {
  productName: string;
  imgGroupIndex: number;
}

const Product: React.FC<ProductProps> = ({ productName, imgGroupIndex }) => {


  const imgPath = useMemo(() => product.product[productName], [productName]);
  const path = useMemo(() => product.product[productName], [productName]);

  let key1, key2, key3, key4, outlook, download;


  if (imgPath.length > 0) {
    key1 = imgPath[imgGroupIndex].key1;
    key2 = imgPath[imgGroupIndex].key2;
    key3 = imgPath[imgGroupIndex].key3;
    if (imgPath[imgGroupIndex].key4) { key4 = imgPath[imgGroupIndex].key4; }
    if (imgPath[imgGroupIndex].outlook) { outlook = imgPath[imgGroupIndex].outlook; }
    if (imgPath[imgGroupIndex].download) { download = imgPath[imgGroupIndex].download; }
  } else {
    key1 = path.key1;
    key2 = path.key2;
    key3 = path.key3;
    if (path.key4) { key4 = path.key4; }
    if (path.outlook) { outlook = path.outlook; }
    if (path.download) { download = path.download; }
  }


  const [btnIndex, setBtnIndex] = useState(0);
  const btnControl = (i: number) => {
    setBtnIndex(i)
  }


  if (!outlook && btnIndex === 1) setBtnIndex(0)


  const [productText, setProductText] = useState<string>("제품 디자인");
  const [outlookText, setOulookText] = useState<string>("제품 외형");
  const [documentText, setDocumentText] = useState<string>("문서");
  const [msg, setMsg] = useState<string>('');
  const [isVideo, setVideo] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/en')) {
      setProductText('Product');
      setOulookText('Appearance');
      setDocumentText('Document');
      setMsg('We apologize for the inconvenience due to server error. Please contact us at pr@orbro.io, and we will send you the necessary information.');
    } else if (location.pathname.startsWith('/jp')) {
      setProductText('製品デザイン');
      setOulookText('製品の外观');
      setMsg('サーバーエラーによりご迷惑をおかけして申し訳ありません。お困りの方は、pr@orbro.io までお問い合わせいただければ、必要な情報をお送りいたします。');
      setDocumentText('文書');
    } else if (location.pathname.startsWith('/cn')) {
      setProductText('产品设计');
      setOulookText('产品外观');
      setDocumentText('文件');
      setMsg('由于服务器错误，我们为造成的不便致以歉意。请通过pr@orbro.io与我们联系，我们将发送您需要的信息。');
    } else {
      setProductText('제품 소개');
      setOulookText('제품 외형');
      setDocumentText('문서');
      setMsg('서버 오류로 불편을 드려 죄송합니다. pr@orbro.io 로 문의주시면 필요한 자료를 보내드리도록 하겠습니다.');
    }
    if (location.pathname.includes('camera/') || location.pathname.includes('rtls/')) {
      setVideo(true);
    } else setVideo(false);
  }, [location.pathname]);


  useEffect(() => {
    setBtnIndex(0);
  }, [productName])


  const handleDownload = (name: string) => {

    getDownloadURL(ref(storage, `gs://orbro-webpage.appspot.com/${name}.pdf`))
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          // Create a link element and set the download attribute
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `${name}.pdf`;

          // Append the link element to the document and trigger a click
          document.body.appendChild(a);
          a.click();

          // Clean up
          document.body.removeChild(a);
          URL.revokeObjectURL(a.href);
        };
        xhr.open('GET', url);
        xhr.send();

        // Or inserted into an <img> element
        const img = document.getElementById('myimg');
        img?.setAttribute('src', url);
      })
      .catch((error) => {
        alert(msg);
      });

  };

  const getPath = () => {
    let lang;
    let product;

    if (location.pathname.includes('en/')) {
      lang = '/en'
    } else if (location.pathname.includes('jp/')) {
      lang = '/jp'
    } else if (location.pathname.includes('cn/')) {
      lang = '/cn'
    } else {
      lang = ' '
    }

    if (location.pathname.includes('outdoor')) {
      product = '/construction/equipment';
    } else if (location.pathname.includes('indoor')) {
      product = '/retail/exhibition';
    } else if (location.pathname.includes('uwb')) {
      product = '/medical/patient';
    } else if (location.pathname.includes('ble')) {
      product = '/manufacturing/process';
    }

    return lang + '/enterprise' + product;

  }
  const getVideo = () => {
    if (location.pathname.includes('outdoor')) {
      return videoOut
    } else if (location.pathname.includes('indoor')) {
      return videoIn
    } else if (location.pathname.includes('uwb')) {
      return videoUWB
    } else if (location.pathname.includes('ble')) {
      return videoBLE
    }
  }


  const [windowWidth, setInnerWidth] = useState<number>(1920);
  useEffect(() => {
    if (typeof window !== 'undefined') {
    }
    const handleResize = () => {
      const { innerWidth } = window;
      setInnerWidth(innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ratio = 1.65;

  return (
    <>

      {/* 제품 디자인 페이지 */}
      <>
        <StyledProductBtnOptions>
          <StyledProductBtn onClick={() => btnControl(0)} index={0} selectedIndex={btnIndex} >
            <BtnLabel index={0} selectedIndex={btnIndex} size={'medium'}>
              <Trans i18nKey={productText} />
            </BtnLabel>
          </StyledProductBtn>


          {outlook &&
            <StyledProductBtn onClick={() => btnControl(1)} index={1} selectedIndex={btnIndex} >
              <BtnLabel index={1} selectedIndex={btnIndex} size={'medium'}>
                <Trans i18nKey={outlookText} />
              </BtnLabel>
            </StyledProductBtn>
          }


          {download &&
            <StyledProductBtn onClick={() => btnControl(2)} index={2} selectedIndex={btnIndex} >
              <BtnLabel index={2} selectedIndex={btnIndex} size={'medium'}>
                <Trans i18nKey={`download.submit`} />
              </BtnLabel>
            </StyledProductBtn>
          }
        </StyledProductBtnOptions>

        {btnIndex == 0 && <>
          {path.design ?
            <> {path.design.map((item, i) => (
              <>
                {item.video &&
                  <>
                    <Spacing pixel='48' />
                    <StyledHeading size={'small'}>
                      <Trans i18nKey={`Overview`} />
                    </StyledHeading>
                    <Spacing pixel='32' />
                    <YoutubeIframe
                      title="YouTube Video"
                      src={i18n.language === 'ko' ? `${item.video}?autoplay=1` : `${item.videoEng}?autoplay=1`}
                      frameBorder="0"
                      allowFullScreen
                    />
                  </>}

                <Spacing pixel='48' />
                <StyledHeading size={'small'}>
                  <Trans i18nKey={`productImg.product.${productName}.design.${i}.title`} />
                </StyledHeading>
                <Spacing pixel='4' />
                <StyledParagraph size={'medium'}>
                  <Trans i18nKey={`productImg.product.${productName}.design.${i}.desc`} />
                </StyledParagraph>

                {item.img1 &&
                  <StyledProductImgBig src={item.img1} alt="RTLS product" />}

                {item.img2 && item.img3 && (
                  <StyledProductImgBox>
                    <StyledProductImgSide src={item.img2} alt="RTLS product" />
                    <StyledProductImgSide src={item.img3} alt="RTLS product" />
                  </StyledProductImgBox>
                )}

                {item.img4 &&
                  <StyledProductImgBig src={item.img4} alt="RTLS product" />}

              </>
            ))}</>
            :
            <>
              {key1 && <StyledProductImgBig src={key1} alt='RTLS Product' />}

              <StyledProductImgBox>
                <StyledProductImgSide src={key2} alt='RTLS Product' />
                <StyledProductImgSide src={key3} alt='RTLS Product' />
              </StyledProductImgBox>

              {key4 && <> <Spacing pixel='32' /> <StyledProductImgBig2 src={key4} alt='RTLS Product' /></>}


            </>}


          {isVideo &&
            <a href={`${getPath()}`} rel="noopener noreferrer">
              <Spacing pixel='24' />
              <VideoBox>
                <VideoPlay autoPlay loop muted playsInline>
                  <source src={getVideo()} type="video/mp4" />
                </VideoPlay>
              </VideoBox>
            </a>
          }

        </>}


        {/* 제품 외형 페이지 */}
        {btnIndex == 1 &&
          <>
            {imgPath.out ?
              <>
                {imgPath.out.map((data, i) => (
                  <>
                    <Spacing pixel='48' />
                    <StyledHeading size={'small'}>
                      <Trans i18nKey={data.title} />
                    </StyledHeading>
                    <Spacing pixel='4' />
                    <StyledParagraph size={'medium'}>
                      <Trans i18nKey={data.desc} />
                    </StyledParagraph>
                    <Spacing pixel='32' />
                    <StyledProductImgOutlook src={data.img} alt='RTLS Product' />
                  </>
                ))}
              </>
              :
              <>
                <StyledProductImgOutlook src={outlook} alt='RTLS Product' />
              </>}
          </>
        }




        {/* 다운로드 */}
        {btnIndex == 2 && path.download &&
          <>
            <DownloadLayout>
              <SpacingBtn />
              <StyledHeading size='xxsmall'>
                <Trans i18nKey={documentText} />
              </StyledHeading>
              <Spacing pixel='32' />
              {download.map((text, i) => (
                <DownloadRow key={i}>
                  <DownloadPara size='medium' >
                    <Trans i18nKey={text.name} />
                  </DownloadPara>
                  <DownloadDate size='medium'>
                    <Trans i18nKey={text.date} />
                  </DownloadDate>
                  <PDFimg src={IconPDF} onClick={() => handleDownload(text.data)} alt='download icon' />
                </DownloadRow>
              ))}
            </DownloadLayout>
          </>}

      </>
    </>
  );
};

export default Product;
