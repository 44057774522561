import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: '실시간 위치 데이터와 3D 시각화를 통합하여 도시 내 개인 및 차량의 움직임을 효과적으로 관리하고, 교통 시스템의 안전과 효율성을 증진시키는 첨단 기술을 제공합니다.',
  keywords: 'Digital Twin, Digital Twins, 디지털트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: '디지털트윈 - 사람과 차량의 실시간 움직임 연결 :: 오브로 블로그 ',
  ogUrl: 'https://orbro.io/blog/digitaltwin-tracking',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '디지털트윈 - 사람과 차량의 실시간 움직임 연결',
  date: '2023-12-26',
  tag1: '#디지털트윈',
  tag2: '#RTLS',
  tag3: '#디지털시티',
  tag4: '#가상현실',

  // <br/>
  text: [
    {
      head: '디지털트윈 - 사람과 차량의 실시간 움직임 연결',
      subHead: '',
      desc: 'ORBRO는 위치 데이터와 3D 시각화를 통합하여 도시 내 개인 및 차량의 움직임을 효과적으로 관리하고 있습니다. 이를 위해 다양한 기술과 시스템을 활용하여 교통 시스템의 안전성과 효율성을 높이고 있습니다.<br/><br/>RTLS(Real-Time Location System) 기술은 사람들의 위치와 움직임을 실시간으로 추적하며, 영상 기술은 차량의 움직임을 모니터링합니다. 3D 시각화를 통해 사람과 차량의 동적인 움직임을 시각적으로 표현하고, 존(Zone) 영역을 지정하여 특정 지역 내의 움직임을 효율적으로 관리합니다.<br/><br/>디지털전환 대시보드는 수집된 데이터를 집계하여 통찰력 있는 정보를 제공하며, 스마트 도시 통합은 도시 운영의 자동화와 효율성을 증진시킵니다. 이를 통해 도시의 교통 흐름과 인프라 사용을 최적화하며 스마트 시티의 구현을 지원합니다.',
      dot: ''

    },
    {
      youtube: 'https://www.youtube.com/embed/g0Kv05InEtI?si=7EnfmOzAOOi-3ds2',
      head: '',
      subHead: 'RTLS 기술을 이용한 사람의 정보 수집',
      desc: 'ORBRO는 고도의 정밀도를 가진 실시간 위치 추적 시스템(RTLS)을 사용하여 사람들의 위치와 움직임을 추적합니다. 이는 특히 대규모 공공 장소나 긴급 상황에서 사람들의 안전과 보안을 보장하는 데 중요합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '영상 기술을 이용한 차량의 정보 수집',
      desc: '다양한 영상 캡처 기술을 활용하여 도시 내 차량의 움직임을 실시간으로 모니터링하고 분석합니다. 이는 교통 흐름의 최적화와 교통 사고 예방에 기여합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '3D로 실시간 표현',
      desc: '3D 모델링을 통해 사람과 차량의 동적인 움직임을 시각화하며, 이를 통해 도시 계획, 교통 관리, 안전 강화 등에 필요한 데이터를 제공합니다.',
    },
    {
      head: '',
      subHead: '존 영역 지정',
      desc: '특정 지역에 대한 존(zone)을 설정하여 그 영역 내의 사람과 차량의 움직임을 효과적으로 관리하고 분석합니다. 이는 공공 안전, 행사 관리, 교통 조절에 유용합니다.',
    },
    {
      head: '',
      subHead: '효과적인 대시보드',
      desc: '사람과 차량의 움직임 데이터를 집계하여 교통 관리, 도시 계획, 공공 안전 등에 필요한 통찰력 있는 대시보드를 제공합니다. 이는 의사 결정자들이 도시의 운영 및 관리를 보다 효과적으로 수행할 수 있도록 지원합니다.',
    },
    {
      head: '',
      subHead: '스마트 도시 통합',
      desc: '위치 데이터와 분석을 스마트 도시 시스템에 통합하여 도시 운영의 자동화와 효율성을 높입니다. 이는 에너지 관리, 교통 시스템, 공공 서비스의 최적화에 기여합니다.',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/enterprise/logistics/asset'
      }
    ]


  }

}