import { navImgAccess, navImgBeacon, navImgCamera, navImgIOT, navImgOS, navImgRTLS, navImgSensor, navImgWorld } from "@assets/images/nav";

export default {


    product: [

        {
            icon: navImgRTLS,
            title: '실시간 위치추적',
            link: '/rtls',
            path: [
                {
                    text: '고정밀(UWB) 위치추적',
                    link: '/rtls/uwb',
                },
            ]
        },
        {
            icon: navImgBeacon,
            title: '비콘 위치추적',
            link: 'none',
            path: [
                {
                    text: '블루투스(BLE) 위치추적',
                    link: '/beacon/ble',
                },
                {
                    text: '산업용 비콘',
                    link: '/beacon/industry',
                },
                {
                    text: '소형 비콘',
                    link: '/beacon/tag',
                },
                {
                    text: '자산용 태그',
                    link: '/beacon/asset-tag',
                },
            ]
        },
        {
            icon: navImgAccess,
            title: '출입 시스템',
            link: '/access',
            path: [
                {
                    text: '자동문 출입',
                    link: '/access/auto',
                },
                {
                    text: '얼굴 인식 출입',
                    link: '/access/face',
                },
                {
                    text: '출입 액세서리',
                    link: '/access/accessory',
                }
            ]
        },

        {
            icon: navImgCamera,
            title: 'AI 위치추적',
            link: '/camera',
            path: [
                {
                    text: '실내 카메라',
                    link: '/camera/indoor',
                },
                {
                    text: '실외 카메라',
                    link: '/camera/outdoor',
                },
            ]
        },
        {
            icon: navImgSensor,
            title: '산업용 IoT',
            link: 'none',
            path: [
                {
                    text: 'LoRa 중계기',
                    link: '/sensor/LoRaGateway',
                },
                {
                    text: 'LoRa 센서',
                    link: '/sensor/LoRaSensor',
                },
                {
                    text: '공기품질 측정기',
                    link: '/sensor/air',
                },
                {
                    text: '일산화탄소 측정기',
                    link: '/sensor/co',
                }
            ]
        },
        {
            icon: navImgWorld,
            title: '연동 시스템',
            link: '/integration',
            path: [
                {
                    text: '로봇 시스템',
                    link: '/integration/robot',
                },
            ]
        },


    ],

    OS: {
        icon: navImgOS,
        title: "ORBRO OS",
        desc: '출입 시스템부터 실시간 위치추적, 다양한 IoT 제어까지, ORBRO OS는 공간을 똑똑하게 만드는 모든 방법을 하나로 통합한 서비스입니다. 지금바로 스마트한 미래를 경험하세요.',
        more: '더 알아보기',
        link: '/os',
    },


    solution: [
        {
            title: '건설',
            path: [
                {
                    text: '작업자 안전 관리',
                    link: '/enterprise/construction/safety'
                },
                {
                    text: '건설 자재 관리',
                    link: '/enterprise/construction/asset'
                },
                {
                    text: '중장비 관리',
                    link: '/enterprise/construction/equipment'
                },
            ]
        },
        {
            title: '물류',
            path: [
                {
                    text: '물류 재고 관리',
                    link: '/enterprise/logistics/asset'
                },
                {
                    text: '창고 작업자 안전 관리',
                    link: '/enterprise/logistics/safety'
                },
            ]
        },
        {
            title: '병원',
            path: [
                {
                    text: '환자 위치 추적',
                    link: '/enterprise/medical/patient'
                },
                {
                    text: '의료 폐기물 및 자산 관리',
                    link: '/enterprise/medical/waste'
                },
            ]
        },
        {
            title: '제조',
            path: [
                {
                    text: '실시간 공정 관리',
                    link: '/enterprise/manufacturing/process'
                },
                {
                    text: '실시간 재고 관리',
                    link: '/enterprise/manufacturing/asset'
                },
            ]
        },
        {
            title: '오피스',
            path: [
                {
                    text: '오피스 자산 관리',
                    link: '/enterprise/office/asset'
                },
            ]
        },
        {
            title: '리테일',
            path: [
                {
                    text: '방문객 관리 및 분석',
                    link: '/enterprise/retail/exhibition'
                },
            ]
        }

    ],

    world: {
        icon: navImgWorld,
        title: "ORBRO Digital Twin",
        desc: '현실 세계의 사람, 물건, 빌딩 그리고 도시의 데이터를 받아서, 디지털 화면 속 가상 현실에서 실시간으로 모니터링하고 관리할 수 있습니다. 이를 통해 생산성 향상과 문제 예방이 가능하며, 주로 산업과 제조 분야에서 활용됩니다.',
        more: '더 알아보기',
        link: '/digital-twin',
    },


    support: [
        {
            title: '고객 지원',
            path: [
                {
                    text: '회사 소개',
                    link: '/company'
                },
                {
                    text: '영업 문의',
                    link: '/request'
                },
                {
                    text: '대리점 문의',
                    link: '/partnership'
                }
            ]
        },

        {
            title: '기술 소개',
            path: [
                {
                    text: 'UWB',
                    link: '/enterprise/technology/uwb'
                },
                {
                    text: 'BLE',
                    link: '/enterprise/technology/ble'
                },
                {
                    text: 'Stereo Vision',
                    link: '/enterprise/technology/stereo-vision'
                },
                {
                    text: 'Monocular Vision',
                    link: '/enterprise/technology/monocular-vision'
                },
                {
                    text: 'TDoA',
                    link: '/enterprise/technology/tdoa'
                },
                {
                    text: 'TWR',
                    link: '/enterprise/technology/twr'
                },
                {
                    text: '지오펜스',
                    link: '/enterprise/technology/what-is-geofence'
                },
                {
                    text: '디지털트윈',
                    link: '/enterprise/technology/what-is-digital-twin'
                },
            ]
        },

        {
            title: '소프트웨어',
            link: 'new',
            path: [
                {
                    text: 'iOS',
                    link: 'https://apps.apple.com/us/app/orbro-service/id1673356996'
                },
                {
                    text: 'Android',
                    link: 'https://play.google.com/store/apps/details?id=com.orbro.mobile'
                },
                {
                    text: 'ORBRO World',
                    link: 'https://home.orbro.io'
                },
                {
                    text: '릴리즈 노트',
                    link: '/release-note'
                },
            ]
        },
        {
            title: '소셜',
            link: 'new',
            path: [
                {
                    text: 'Linkedin',
                    link: 'https://kr.linkedin.com/company/kongtech'
                },
                {
                    text: 'Naver',
                    link: 'https://blog.naver.com/orbro_platform'
                },
                {
                    text: 'Medium',
                    link: 'https://medium.com/@orbro'
                },
            ]
        },
    ],

    footerSupport: [

        {
            title: '지원',
            path: [
                { text: "블로그", link: "/blog" },
                { text: "영업 문의", link: "/request" },
                { text: '대리점 문의', link: '/partnership' },

            ]
        },
        {
            title: '소프트웨어',
            path: [
                {
                    text: 'iOS',
                    link: 'https://apps.apple.com/us/app/orbro-service/id1673356996'
                },
                {
                    text: 'Android',
                    link: 'https://play.google.com/store/apps/details?id=com.orbro.mobile'
                },
                {
                    text: 'ORBRO World',
                    link: 'https://home.orbro.io'
                },
                {
                    text: '릴리즈 노트',
                    link: '/release-note'
                },
            ]
        },
        {
            title: '다운로드',
            path: [
                { text: "iOS 앱 다운로드", link: "https://apps.apple.com/us/app/orbro-service/id1673356996" },
                { text: "Android 앱 다운로드", link: "https://play.google.com/store/apps/details?id=com.orbro.mobile" },
            ]
        },
        {
            title: '소셜',
            path: [
                { text: "LinkedIn", link: "https://kr.linkedin.com/company/kongtech" },
                { text: "Naver Blog", link: "https://blog.naver.com/orbro_platform" },
            ]
        },
    ]


}