import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import React, { useEffect, useState } from "react";
import { BtnBox, CheckBox, IndustryIcon, NextButton, PricingPage, PricingParagraph, PricingSpacingBottom, ProgressBar, ProgressBarBox, ProgressNumber, SelectBox, SelectLayout } from "./Pricing.style";
import { StyledDisplay, StyledHeading } from "@components/Text";
import { Trans } from "react-i18next";
import { StyledSpacing24 } from "@pages/Enterprise/Technology/StereoVision/components/SVContent/StereoVision.style";
import pricing from "@src/lang/ko/pricing/pricing";
import { IconCheckBoxEmpty, IconCheckBoxOn } from "@assets/images/icon";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import seo from '@src/lang/ko/seo';
import seoEN from '@src/lang/en/seo';
import seoJP from '@src/lang/jp/seo';
import seoCN from '@src/lang/cn/seo';
import SEO from '@pages/SEO';
import i18n from "@pages/i18n";
import { ProgressedBarAnimation1_2 } from "./BarAnimation";
import { HeadingColor, ImgWidth, ImgWidth100, LabelColor, Spacing } from "@pages/CommonStyle/common.style";


const Pricing1 = (): React.ReactElement => {

  const location = useLocation();
  const currentDateTime = new Date();

  const isEnglishPage = location.pathname.includes('/en/');
  const isJapanPage = location.pathname.includes('/jp/');
  const isChinaPage = location.pathname.startsWith('/cn/');
  const navigate = useNavigate();

  useEffect(() => {
    if (isEnglishPage) {
      i18n.changeLanguage("en").then(() => {
        navigate('/en/pricing/1', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: location.state && !location.state.forward ? false : true } });
      });
    } else if (isJapanPage) {
      i18n.changeLanguage("jp").then(() => {
        navigate('/jp/pricing/1', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: location.state && !location.state.forward ? false : true } });
      });
    } else if (isChinaPage) {
      i18n.changeLanguage("cn").then(() => {
        navigate('/cn/pricing/1', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: location.state && !location.state.forward ? false : true } });
      });
    } else {
      i18n.changeLanguage("ko").then(() => {
        navigate('/pricing/1', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: location.state && !location.state.forward ? false : true } });
      });
    }
  }, [location.pathname]);

  const [ipAddress, setIp] = useState();

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        setIp(res.data.state)
      })
  }, [])


  useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then((res) => {
      })
  }, [])


  let langPath;

  let seoPath;

  if (location.pathname.includes('/en/')) {
    seoPath = seoEN;
    langPath = 'en';
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
    langPath = 'jp';
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
    langPath = 'cn';
  } else {
    seoPath = seo;
    langPath = 'ko';
  }
  const helmetTitle = seoPath.pricing.title;
  const ogKey = seoPath.pricing.key;
  const ogDesc = seoPath.pricing.desc;
  const ogImg = seoPath.pricing.img;
  const ogUrl = seoPath.pricing.url;
  const currentLang = i18n.language;


  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const selectedOptions = location.state && location.state.selectedOptions ? location.state.selectedOptions : [];
  const selectedProducts = location.state && location.state.selectedProducts ? location.state.selectedProducts : [];
  const email = location.state && location.state.email ? location.state.email : '';
  const name = location.state && location.state.name ? location.state.name : '';
  const phone = location.state && location.state.phone ? location.state.phone : '-';
  const memo = location.state && location.state.memo ? location.state.memo : '-';
  const schedule = location.state && location.state.schedule ? location.state.schedule : '-';
  let personalID = location.state && location.state.personalID ? location.state.personalID : '-';
  const forward = location.state && !location.state.forward ? false : true;

  const handleSelectBoxClick = (index) => {
    if (selectedIndexes.includes(index)) {
      // 이미 선택된 경우, 해당 인덱스 제거
      setSelectedIndexes(selectedIndexes.filter((i) => i !== index));
    } else {
      // 선택되지 않은 경우, 해당 인덱스 추가
      setSelectedIndexes([...selectedIndexes, index]);
    }
  };

  const handleNextStep = () => {
    if (selectedIndexes.length > 0) {
      getPersonalId();
      notionSubmit(personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule);
      if (currentLang === 'ko') { navigate('/pricing/2', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: true } }); }
      else { navigate(`/${currentLang}/pricing/2`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: true } }); }
    }
  }

  useEffect(() => {
    const updateIndex = location.state && location.state.selectedIndexes ? location.state.selectedIndexes : [];
    if (updateIndex.length > 0) {
      setSelectedIndexes(updateIndex);
    }
  }, [location.pathname])


  const getPersonalId = () => {

    const personalIDInt = parseInt(personalID);

    if (personalIDInt > 0) {
      return;
    } else {
      const randomValue = (Math.floor(Math.random() * 10000) + 1).toString();
      personalID = randomValue + ` / ` + langPath;
    }

  }


  // 데이터를 Notion에 전송하는 함수
  const notionSubmit = async (personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule) => {

    try {
      const response = await fetch("https://orbro.io/pricingNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          personalID: personalID,
          industries: convertString(selectedIndexes),
          products: convertString2(selectedProducts),
          demand: convertString3(selectedProducts, selectedOptions),
          email: email,
          name: name,
          phone: phone,
          memo: memo,
          schedule: schedule,
          ipAddress: ipAddress,
          type: '-',
          currentTime: currentDateTime.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // 24시간 형식
            timeZone: 'Asia/Seoul', // 한국 시간대
          })
        })
      });

    } catch (error) {
      console.log('error', error);
    }
  }

  const convertString = (selectedIndexes) => {
    const selectedIndustries = selectedIndexes.map(index => {
      return pricing.step1.industry[index].title;
    });
    return selectedIndustries.join(', ');
  }

  const convertString2 = (selectedProducts) => {
    const selectedProduct = selectedProducts.map(index => {
      return pricing.step2.product[index].title;
    });
    return selectedProduct.join(', ');
  }


  const convertString3 = (selectedProducts, selectedOptions) => {
    let combinedText = ''; // 문자열을 저장할 변수 초기화

    selectedProducts.forEach((i) => {
      selectedOptions[i].forEach((jData, j) => {
        jData.forEach((value, k) => {
          if (
            value === 1 &&
            j < pricing.step3.question[i].parts.length &&
            k < pricing.step3.question[i].parts[j].option.length
          ) {
            // 문자열을 추가
            combinedText += pricing.step3.question[i].parts[j].option[k].text + ' ';
            if (i !== selectedProducts.length - 1) {
              // i 값이 마지막이 아니면 쉼표를 추가
              combinedText += ', ';
            }
          }
        });
      });
    });

    return combinedText; // 결합된 문자열 반환
  };

  const [count, setCount] = useState(forward ? 0 : 38);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count !== 0) {
        // forward 값에 따라 증가 또는 감소
        setCount(count + (forward ? 0 : -1));
      }
    }, 22); // 100ms 간격으로 증가

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 타이머 정리
  }, [count, forward]);


  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "pr@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }

  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />
      <Spacing pixel="64" />
      <StyledGridContainer>

        <StyledGridRow>
          <StyledGridColumn desktop={2} tablet={1} mobile={0}>

          </StyledGridColumn>
          <StyledGridColumn desktop={8} tablet={6} mobile={4}>

            <PricingPage>
              {/** 타이틀 **/}
              <StyledDisplay size={"small"}>
                <Trans i18nKey={`pricing.step1.title`} />
              </StyledDisplay>
              <StyledSpacing24 />
              <StyledSpacing24 />

              {/** 진행 바 **/}
              <ProgressBarBox>
                <ProgressNumber>
                  <HeadingColor size="xxsmall" color='#2A70F0'>
                    {count + "%"}
                  </HeadingColor>
                </ProgressNumber>
                <ProgressBar>
                  {!forward && <ProgressedBarAnimation1_2 />}
                </ProgressBar>

              </ProgressBarBox>


              {/** 옵션 선택 **/}
              <PricingSpacingBottom />
              <SelectLayout>
                {pricing.step1.industry.map((data, i) => (
                  <>
                    <SelectBox key={i} onClick={() => handleSelectBoxClick(i)} isSelected={selectedIndexes.includes(i)} >
                      <IndustryIcon isSelected={selectedIndexes.includes(i)}>
                        <ImgWidth src={data.icon} alt="orbro solution" width={"24"} />
                      </IndustryIcon>
                      <Spacing pixel="8" />
                      <StyledHeading size="xxxsmall">
                        <Trans i18nKey={`pricing.step1.industry.${i}.title`} />
                      </StyledHeading>
                      <Spacing pixel="16" />
                      <HeadingColor size="xxxxsmall" color='#818181'>
                        <Trans i18nKey={`pricing.step1.common`} />
                      </HeadingColor>
                      <PricingParagraph size={"small"}>
                        <Trans i18nKey={`pricing.step1.industry.${i}.example`} />
                      </PricingParagraph>
                      <Spacing pixel="16" />
                      <CheckBox margin={false}>
                        <ImgWidth100 src={selectedIndexes.includes(i) ? IconCheckBoxOn : IconCheckBoxEmpty} alt="checkbox" />
                      </CheckBox>

                    </SelectBox>
                  </>
                ))}

              </SelectLayout>


              {/** 다음 버튼 **/}
              <Spacing pixel="64" />
              <NextButton>
                <BtnBox isOk={selectedIndexes.length > 0} onClick={() => handleNextStep()}>
                  <LabelColor size="large" color='#FFFFFF' >
                    <Trans i18nKey={`pricing.step1.next`} />
                  </LabelColor>
                </BtnBox>
              </NextButton>
              <Spacing pixel="64" />


            </PricingPage>


          </StyledGridColumn>


        </StyledGridRow>
      </StyledGridContainer>

    </>
  )
};

export default Pricing1;


//
//<Product/>