import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import React, { useEffect, useState } from "react";
import {
  BoxLayout, BtnBox, BtnCancel, ChoiceBlueDot, ChoiceBox, ChoiceCircle,
  ChoiceLayout,
  ChoiceSquare,
  HeadingCenter,
  OptionHeading,
  OptionTitleDiv,
  PricingPage, PricingSpacingBottom, ProgressBar, ProgressBarBox, ProgressNumber,
} from "./Pricing.style";
import { StyledDisplay, StyledLabel } from "@components/Text";
import { Trans } from "react-i18next";
import { StyledSpacing24 } from "@pages/Enterprise/Technology/StereoVision/components/SVContent/StereoVision.style";
import pricing from "@src/lang/ko/pricing/pricing";
import { IconCheckBoxOn } from "@assets/images/icon";
import { useLocation, useNavigate } from "react-router-dom";
import seo from '@src/lang/ko/seo';
import seoEN from '@src/lang/en/seo';
import seoJP from '@src/lang/jp/seo';
import seoCN from '@src/lang/cn/seo';
import SEO from '@pages/SEO';
import i18n from "@pages/i18n";
import axios from "axios";

import { ProgressedBarAnimation3_1, ProgressedBarAnimation3_2 } from "./BarAnimation";
import { HeadingColor, LabelColor, Spacing } from "@pages/CommonStyle/common.style";


const Pricing3 = (): React.ReactElement => {

  const [ipAddress, setIp] = useState();

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        setIp(res.data.state)
      })
  }, [])

  const location = useLocation();
  const currentDateTime = new Date();
  const selectedIndexes = location.state && location.state.selectedIndexes ? location.state.selectedIndexes : [];
  const selectedProducts = location.state && location.state.selectedProducts ? location.state.selectedProducts : [];

  const name = location.state && location.state.name ? location.state.name : ''; const email = location.state && location.state.email ? location.state.email : '';
  const personalID = location.state && location.state.personalID ? location.state.personalID : '-';

  const phone = location.state && location.state.phone ? location.state.phone : '-';
  const memo = location.state && location.state.memo ? location.state.memo : '-';
  const schedule = location.state && location.state.schedule ? location.state.schedule : '-';
  const forward = location.state && location.state.forward ? location.state.forward : false;


  for (let i = 0; i < selectedProducts.length; i++) {
    //alert(pricing.step2.product[selectedIndexes[i]].title);
  }

  const initialSelectedOptions = Array(pricing.step3.question.length).fill(0).map((_, i) =>
    Array(6).fill(0).map((_, j) =>
      Array(10).fill(0).map((_, k) =>
        (k === 0) ? 1 : 0
      )
    )
  );

  const [selectedOptions, setSelectedOptions] = useState<number[][][]>(initialSelectedOptions);

  const toggleOption = (i: number, j: number, k: number) => {
    if (selectedOptions[i][j][k] !== 1) {

      const updatedSelectedOptions = [...selectedOptions];

      if (pricing.step3.question[i].parts[j].type.includes('multi')) {
        updatedSelectedOptions[i][j][k] = updatedSelectedOptions[i][j][k] === 1 ? 0 : 1;
      } else {
        // Multi 선택이 불가능한 경우, 다른 옵션 초기화
        for (let x = 0; x < updatedSelectedOptions[i][j].length; x++) {
          updatedSelectedOptions[i][j][x] = 0;
        }
        updatedSelectedOptions[i][j][k] = 1;
      }
      setSelectedOptions(updatedSelectedOptions);

    } else if (selectedOptions[i][j].filter(item => item === 1).length >= 2) {
      // 선택을 해제할 때
      const updatedSelectedOptions = [...selectedOptions];
      updatedSelectedOptions[i][j][k] = 0;
      setSelectedOptions(updatedSelectedOptions);
    }
  };


  const navigate = useNavigate();
  const handleNextStep = () => {
    if (selectedProducts.length > 0) {
      notionSubmit(personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule);
      if (currentLang === 'ko') { navigate('/pricing/4', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: true } }); }
      else { navigate(`/${currentLang}/pricing/4`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: true } }); }

    }
  }
  const handlePrevStep = () => {
    if (currentLang === 'ko') { navigate('/pricing/2', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: false } }); }
    else { navigate(`/${currentLang}/pricing/2`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: false } }); }
  }

  useEffect(() => {
    const updateSelectedOptions = location.state && location.state.selectedOptions ? location.state.selectedOptions : [];
    if (updateSelectedOptions.length > 0) {
      setSelectedOptions(updateSelectedOptions);
    }
  }, [location.pathname])

  // 데이터를 Notion에 전송하는 함수
  const notionSubmit = async (personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule) => {

    try {
      const response = await fetch("https://orbro.io/pricingNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          personalID: personalID,
          industries: convertString(selectedIndexes),
          products: convertString2(selectedProducts),
          demand: convertString3(selectedProducts, selectedOptions),
          email: email,
          name: name,
          phone: phone,
          memo: memo,
          schedule: schedule,
          ipAddress: ipAddress,
          type: '-',
          currentTime: currentDateTime.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // 24시간 형식
            timeZone: 'Asia/Seoul', // 한국 시간대
          })
        })
      });

    } catch (error) {
      console.log('error', error);
    }
  }



  const convertString = (selectedIndexes) => {
    const selectedIndustries = selectedIndexes.map(index => {
      return pricing.step1.industry[index].title;
    });
    return selectedIndustries.join(', ');
  }

  const convertString2 = (selectedProducts) => {
    const selectedProduct = selectedProducts.map(index => {
      return pricing.step2.product[index].title;
    });
    return selectedProduct.join(', ');
  }

  const convertString3 = (selectedProducts, selectedOptions) => {
    let combinedText = ''; // 문자열을 저장할 변수 초기화

    selectedProducts.forEach((i) => {
      combinedText += pricing.step3.question[i].title + ' -> ';
      selectedOptions[i].forEach((jData, j) => {
        jData.forEach((value, k) => {
          if (
            value === 1 &&
            j < pricing.step3.question[i].parts.length &&
            k < pricing.step3.question[i].parts[j].option.length
          ) {
            // 문자열을 추가
            combinedText += pricing.step3.question[i].parts[j].title + ' : ';
            combinedText += pricing.step3.question[i].parts[j].option[k].text + ' / ';

          }
        });
      });
    });

    return combinedText; // 결합된 문자열 반환
  };


  const [count, setCount] = useState(forward ? 38 : 89);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count !== 62) {
        // forward 값에 따라 증가 또는 감소
        setCount(count + (forward ? 1 : -1));
      }
    }, forward ? 36 : 34); // 100ms 간격으로 증가

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 타이머 정리
  }, [count, forward]);


  let seoPath;

  if (location.pathname.includes('/en/')) {
    seoPath = seoEN;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
  }
  const helmetTitle = seoPath.pricing.title;
  const ogKey = seoPath.pricing.key;
  const ogDesc = seoPath.pricing.desc;
  const ogImg = seoPath.pricing.img;
  const ogUrl = seoPath.pricing.url;
  const currentLang = i18n.language;
  const jsonLd = {
    "@context": "s",
    "@type": "Organization",
    "name": "ORBRO",
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "pr@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }


  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />
      <Spacing pixel="64" />
      <StyledGridContainer>

        <StyledGridRow>
          <StyledGridColumn desktop={2} tablet={1} mobile={0}>

          </StyledGridColumn>
          <StyledGridColumn desktop={8} tablet={6} mobile={4}>

            <PricingPage>
              {/** 타이틀 **/}
              <StyledDisplay size={"small"}>
                <Trans i18nKey={`pricing.step3.title`} />
              </StyledDisplay>
              <StyledSpacing24 />
              <StyledSpacing24 />

              {/** 진행 바 **/}
              <ProgressBarBox>
                <ProgressNumber>
                  <HeadingColor size="xxsmall" color='#2A70F0'>
                    {count + "%"}
                  </HeadingColor>
                </ProgressNumber>
                <ProgressBar>
                  {forward ? <ProgressedBarAnimation3_1 /> : <ProgressedBarAnimation3_2 />}
                </ProgressBar>

              </ProgressBarBox>

              {/** 옵션 선택 **/}
              {pricing.step3.question.map((data, i) => (
                <>
                  {/** 기본 선택 **/} {/** 사물인터넷 스킵 (i=4) **/}
                  {(selectedProducts.includes(i) && i !== 5) &&
                    <>
                      <PricingSpacingBottom />
                      {/** 제품 질문 타이틀 **/}
                      <HeadingCenter size="xxsmall">
                        <Trans i18nKey={`pricing.step3.question.${i}.title`} />
                      </HeadingCenter>

                      <Spacing pixel="32" />

                      {data.parts.map((ask, j) => (
                        <>
                          {ask.type.includes('choice') && <>

                            {/** 세부 질문 타이틀 **/}
                            <HeadingCenter size="xxxxsmall">
                              <Trans i18nKey={`pricing.step3.question.${i}.parts.${j}.title`} />
                            </HeadingCenter>
                            <Spacing pixel="24" />

                            {/** 선택 사항들 **/}
                            <ChoiceLayout i={parseInt(ask.type.slice(-1), 10)}>
                              {ask.option.map((text, k) => (
                                <>

                                  <ChoiceBox key={j} onClick={() => toggleOption(i, j, k)} isSelected={1 === selectedOptions[i][j][k]}>
                                    {ask.type.includes('multi') ?
                                      <>
                                        {1 === selectedOptions[i][j][k] ?
                                          <ChoiceSquare src={IconCheckBoxOn} alt="checkbox" />
                                          :
                                          <ChoiceCircle radius={"4"} />
                                        }
                                      </>
                                      :
                                      <ChoiceCircle radius={"16"} >
                                        {1 === selectedOptions[i][j][k] && <ChoiceBlueDot />}
                                      </ChoiceCircle>
                                    }
                                    <OptionTitleDiv>
                                      <OptionHeading size="xxxsmall" i={selectedOptions[i][j][k]}>
                                        <Trans i18nKey={`pricing.step3.question.${i}.parts.${j}.option.${k}.text`} />
                                      </OptionHeading>
                                    </OptionTitleDiv>
                                  </ChoiceBox>
                                </>
                              ))}
                            </ChoiceLayout>
                            <Spacing pixel="32" />
                          </>
                          }
                        </>
                      ))}

                    </>}

                </>
              ))}

              {/** 다음 버튼 **/}
              <Spacing pixel="64" />
              <BoxLayout>
                <BtnCancel onClick={() => handlePrevStep()}>
                  <StyledLabel size="large">
                    <Trans i18nKey={`pricing.step2.prev`} />
                  </StyledLabel>
                </BtnCancel>
                <BtnBox isOk={selectedProducts.length > 0} onClick={() => handleNextStep()}>
                  <LabelColor size="large" color='#FFFFFF'>
                    <Trans i18nKey={`pricing.step2.next`} />
                  </LabelColor>
                </BtnBox>
              </BoxLayout>
              <Spacing pixel="64" />

            </PricingPage>
          </StyledGridColumn>

        </StyledGridRow>
      </StyledGridContainer>

    </>
  )
};

export default Pricing3;

