import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import React, { useEffect, useState } from "react";
import { BoxLayout, CallButton, PricingPage, } from "./Pricing.style";
import { StyledDisplay, StyledParagraph } from "@components/Text";
import { Trans } from "react-i18next";
import { StyledSpacing24 } from "@pages/Enterprise/Technology/StereoVision/components/SVContent/StereoVision.style";
import { Icon3dCheck } from "@assets/images/icon";
import { useLocation, useNavigate } from "react-router-dom";

import seo from '@src/lang/ko/seo';
import seoEN from '@src/lang/en/seo';
import seoJP from '@src/lang/jp/seo';
import seoCN from '@src/lang/cn/seo';
import SEO from '@pages/SEO';
import i18n from "@pages/i18n";
import axios from "axios";
import { ImgWH, LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivAllCenter } from "@pages/CommonStyle/commonDiv.style";



const Pricing6 = (): React.ReactElement => {
  const [ipAddress, setIp] = useState();

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        setIp(res.data.state)
      })
  }, [])

  const location = useLocation();
  const personalID = location.state && location.state.personalID ? location.state.personalID : [];


  const selectedIndexes = location.state && location.state.selectedIndexes ? location.state.selectedIndexes : [];
  const selectedOptions = location.state && location.state.selectedOptions ? location.state.selectedOptions : [];
  const selectedProducts = location.state && location.state.selectedProducts ? location.state.selectedProducts : [];

  const name = location.state && location.state.name ? location.state.name : '';
  const email = location.state && location.state.email ? location.state.email : '';
  const phone = location.state && location.state.phone ? location.state.phone : '-';
  const memo = location.state && location.state.memo ? location.state.memo : '-';
  const schedule = location.state && location.state.schedule ? location.state.schedule : '-';

  const navigate = useNavigate();
  const handleNextStep = () => {
    if (currentLang === 'ko') { navigate('/schedule', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule } }); }
    else { navigate(`/${currentLang}/schedule`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule } }); }
  }

  let seoPath;

  if (location.pathname.includes('/en/')) {
    seoPath = seoEN;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
  }
  const helmetTitle = seoPath.pricing.title;
  const ogKey = seoPath.pricing.key;
  const ogDesc = seoPath.pricing.desc;
  const ogImg = seoPath.pricing.img;
  const ogUrl = seoPath.pricing.url;
  const currentLang = i18n.language;
  const jsonLd = {
    "@context": "s",
    "@type": "Organization",
    "name": "ORBRO",
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "pr@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }

  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />
      <Spacing pixel="64" />
      <StyledGridContainer>

        <StyledGridRow>
          <StyledGridColumn desktop={2} tablet={1} mobile={0}>

          </StyledGridColumn>
          <StyledGridColumn desktop={8} tablet={6} mobile={4}>

            <PricingPage>

              {/** 이미지 **/}
              <DivAllCenter>
                <ImgWH src={Icon3dCheck} w={"80"} h={"80"} alt="check icon" />
                <Spacing pixel="32" />

                {/** 타이틀 **/}
                <StyledDisplay size={"small"}>
                  <Trans i18nKey={`pricing.step6.title`} />
                </StyledDisplay>

                {/** 설명 **/}
                <Spacing pixel="20" />
                <StyledParagraph size={"medium"} >
                  <Trans i18nKey={`pricing.step6.desc`} />
                </StyledParagraph>

                {/** 버틈 **/}
                <StyledSpacing24 />
                <StyledSpacing24 />
                <BoxLayout>
                  <CallButton onClick={() => handleNextStep()} >
                    <LabelColor size="large" color={"#FFF"}>
                      <Trans i18nKey={`pricing.step6.btn`} />
                    </LabelColor>
                  </CallButton>
                </BoxLayout>
                <Spacing pixel="64" />

              </DivAllCenter>


            </PricingPage>


          </StyledGridColumn>


        </StyledGridRow>
      </StyledGridContainer>

    </>
  )
};

export default Pricing6;


//
//<Product/>