import { TechUseCaseIcon3D, TechUseCaseIconBuilding } from "@assets/images/technology/common";
import { IconAnalytics, IconBattery, IconCar, IconChartInv, IconHeart, IconHome, IconLocation, IconRulerInv, IconSecurity, IconTagInv } from "@assets/images/icon";
import video from "@src/assets/images/solution/constructionSafety/video/rtlsVideo.mp4";

export default {

    seo: {
        title: '지오펜스 (Geofence)란? | 오브로',
        desc: '지오펜스는 지리적인 영역을 가상으로 설정하고, 해당 영역 내에서 발생하는 사건을 감지하는 기술을 말합니다. 이는 위치 기반 서비스와 센서 기술을 결합하여 실시간으로 특정 지역에서의 사건을 감지하고 대응할 수 있게 해줍니다.',
        keywords: 'geofence, rtls, 지오펜스, 실시간 위치추적, digital twin, 디지털 전환, 디지털트윈, 스마트 빌딩, 가상현실, 위치추적, 비콘, orbro, 오브로, 콩테크',
        img: '',
        url: 'https://orbro.io/enterprise/technology/what-is-geofence',
        type: 'website',
    },

    tag: 'What is Geofence?',
    title: '지오펜스 (Geofence)란?',
    desc: '',

    text: [
        {
            head: '지오펜스 (Geofence)란?',
            desc: '지오펜스는 지리적인 영역을 가상으로 설정하고, 해당 영역 내에서 발생하는 사건을 감지하는 기술을 말합니다. 이는 위치 기반 서비스와 센서 기술을 결합하여 실시간으로 특정 지역에서의 사건을 감지하고 대응할 수 있게 해줍니다.',
            subHead: '',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/enterprise/construction/safety',
            head: '지오펜스 기술',
            desc: '일반적으로 지오펜스는 GPS(Global Positioning System)를 기반으로 하지만, 최근에는 RTLS(Real-Time Location System) 기술을 활용하는 추세가 있습니다. RTLS는 GPS보다 정확한 위치 정보를 제공하며 실내에서도 뛰어난 성능을 보입니다. 비콘, RFID, 센서 네트워크 등을 통해 실시간 위치를 추적함으로써 지오펜스를 효과적으로 구현할 수 있습니다.',
            subHead: [
                {
                    title: 'RTLS란?',
                    desc: 'RTLS(Real-Time Location System)는 실시간으로 대상의 위치를 추적하는 기술로, GPS와는 달리 실내 환경에서도 정확한 위치 정보를 제공합니다. 이는 비콘, RFID, 센서 네트워크 등을 활용하여 대상의 위치를 실시간으로 감지하고 기록하는 시스템입니다. RTLS는 주로 차량 추적, 자동화, 보안 등 다양한 산업 분야에서 활용됩니다.'
                },
                {
                    title: 'RTLS 장점',
                    desc: 'RTLS의 주요 장점은 뛰어난 정확성으로, GPS와는 달리 실내 및 복잡한 환경에서도 정확한 위치 추적이 가능합니다. 높은 실시간 감지 능력을 통해 대상의 움직임을 실시간으로 모니터링할 수 있으며, 이는 빠른 대응과 효율적인 자동화를 가능케 합니다. 또한, 다양한 기술을 결합하여 적용할 수 있어 다양한 업종에서 활용할 수 있습니다. RTLS는 특히 산업 현장에서의 생산성 향상과 보안 강화에 기여하는 효과적인 솔루션으로 평가받고 있습니다.'
                },
                {
                    title: 'RTLS 적용',
                    desc: 'RTLS 기술을 설치하는 과정은 다양한 구성 요소를 통합하여 진행됩니다. 먼저, 대상을 식별하는데 사용되는 비콘, RFID 센서, 또는 다른 위치 추적 기술을 선택하고 배치합니다. 그 후, 설치된 기술들은 네트워크와 연결되어 실시간 위치 데이터를 수집하게 됩니다. 중앙 제어 시스템을 설정하여 데이터를 처리하고 효율적으로 활용할 수 있도록 합니다. 마지막으로, 사용자 인터페이스를 구성하여 위치 정보를 모니터링하고 필요한 조치를 취할 수 있도록 합니다'
                },
            ],
            dot: ''
        },
        {
            tag: 'noBottomLine',
            head: '지오펜스의 적용 분야',
            desc: '지오펜스는 다양한 분야에서 활용되며 주로 다음과 같은 적용 분야가 있습니다:',
            subHead: [
                {
                    title: '보안 시스템',
                    desc: '기업 빌딩, 주거지, 공공 장소 등 다양한 지역에서 지오펜스는 무단 침입을 감지하고 경고를 제공함으로써 보안을 강화합니다. 위험 지역에서의 출입 제어 및 사전 경고 시스템으로 활용될 수 있습니다.'
                },
                {
                    title: '차량 추적',
                    desc: '자동차나 화물 운송업체에서는 지오펜스를 사용하여 차량의 움직임을 실시간으로 모니터링하고, 특정 지역에서 벗어나면 경보를 울리도록 설정할 수 있습니다.'
                },
                {
                    title: '스마트 도시 및 IoT',
                    desc: '스마트 도시 프로젝트에서 공공 장소에서의 사람의 이동을 모니터링하여 도시 인프라를 최적화하는 데 활용됩니다. 또는 지능형 교통 시스템에서 교통 흐름 분석 및 정체 상황 감지에 활용됩니다.'
                },
                {
                    title: '레저 및 엔터테인먼트',
                    desc: '관광지나 테마파크에서 방문자의 이동을 추적하여 향상된 서비스를 제공합니다. 또한, 이동 가능한 경계를 설정하여 특정 구역에서의 활동을 지원하거나 제한할 수 있습니다.'
                },
            ],
            dot: ''
        },
        {
            videoDesc: '(건설현장 안전관리, 클릭하면 이동합니다)',
            tag: 'noBottomLine',
            head: '지오펜스 기술 전망',
            desc: '지오펜스 기술은 계속해서 진화하며 미래에는 더욱 확장될 것으로 전망됩니다. 기술의 발전으로 인해 정밀한 위치 추적과 실시간 감지 능력이 향상되면서 보안 및 안전 분야에서의 적용이 더욱 강화될 것으로 예상됩니다.<br/>또한, 실내 위치 추적 기술의 발전으로 인해 건물 내부에서도 정확하게 대상의 위치를 파악할 수 있게 되어 차량 추적, 산업 자동화, 스마트 도시 등 다양한 분야에서의 응용이 더욱 확대될 것으로 예측됩니다. 또한, 협력 시스템과의 통합을 통해 다양한 센서 및 기술과 연계하여 더욱 효과적인 지오펜스 솔루션을 개발하는 노력이 진행될 것으로 보입니다. 이러한 기술의 진보는 보안, 물류, 스마트 시티 등의 다양한 산업 분야에서 혁신적인 변화를 가져올 것으로 예상됩니다.',
            subHead: [
            ],
            dot: ''
        },
        {
            tag: 'card',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
        {
            tag: 'advantage',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
    ],



    card: {
        title: '지오펜스 사용 사례',
        desc: '지오펜스 기술은 다양한 분야에서 효과적으로 활용되고 있습니다. 아래는 몇 가지 대표적인 지오펜스 사용 사례입니다.',
        cards: [
            {
                icon: IconSecurity,
                title: '보안 및 알림 시스템',
                desc: [
                    {
                        title: '건물 및 시설 보호',
                        desc: '지오펜스를 사용하여 건물 또는 시설 주변에 가상 경계를 설정하고, 무단 침입 시 실시간으로 경보를 발생시켜 보안을 강화합니다.',
                    },
                    {
                        title: '자동차 및 운송 보호',
                        desc: '자동차나 운송 수단에 지오펜스를 적용하여 도난 방지 및 안전한 운행을 지원합니다.',
                    }
                ]
            },
            {
                icon: IconHome,
                title: '물류 및 창고 관리',
                desc: [
                    {
                        title: '실시간 화물 추적',
                        desc: '물류 네트워크에 지오펜스를 도입하여 화물의 실시간 위치를 모니터링하고, 효율적인 창고 관리에 기여합니다.',
                    },
                    {
                        title: '배송 및 운송 최적화',
                        desc: '지오펜스를 활용하여 배송 및 운송 경로를 최적화하여 비용을 절감하고 빠른 서비스를 제공합니다.',
                    }
                ]
            },
            {
                icon: IconHeart,
                title: '의료 시설 보안',
                desc: [
                    {
                        title: '환자 위치 추적',
                        desc: '병원이나 의료 시설에서 지오펜스를 사용하여 환자의 위치를 실시간으로 추적하고 응급 상황에 빠른 대응을 지원합니다.',
                    },
                    {
                        title: '의료 장비 관리',
                        desc: '의료 기기에 지오펜스를 도입하여 장비의 위치와 상태를 모니터링하여 정확하고 효율적인 의료 서비스를 제공합니다.',
                    }
                ]
            },
            {
                icon: TechUseCaseIconBuilding,
                title: '스마트 도시 안전',
                desc: [
                    {
                        title: '공공 장소 감시',
                        desc: '도시의 주요 지점에 지오펜스를 적용하여 공공 장소의 안전을 강화하고 범죄 예방을 지원합니다.',
                    },
                    {
                        title: '교통 흐름 관리',
                        desc: '도로 및 교통 네트워크에 지오펜스를 도입하여 교통 흐름을 모니터링하고 효율적인 교통 계획을 수립합니다.',
                    }
                ]
            },
            {
                icon: IconLocation,
                title: '교육 시설 보안',
                desc: [
                    {
                        title: '학교 및 대학 캠퍼스 보호',
                        desc: '학교나 대학 내에 지오펜스를 구축하여 학생 및 교직원의 안전을 보장하고 무단 출입을 방지합니다.',
                    },
                    {
                        title: '교육 자원 추적',
                        desc: '교육 장비 및 자원에 지오펜스를 적용하여 효율적인 자원 관리를 지원하고 분실 방지에 기여합니다.',
                    }
                ]
            },
            {
                icon: IconAnalytics,
                title: '환경 모니터링',
                desc: [
                    {
                        title: '자연 환경 감시',
                        desc: '자연 환경에서의 지오펜스 활용으로 대기질, 수질, 기후 등의 환경 요소를 실시간으로 모니터링하여 환경 보전에 기여합니다.',
                    },
                    {
                        title: '산업 환경 관리',
                        desc: '산업 시설 내에서 환경 파라미터를 감시하고 정확한 데이터를 통해 친환경적인 운영에 기여합니다.',
                    }
                ]
            }
        ]
    },


    advantage: {
        title: '지오펜스 주요 장점',
        cards: [
            {
                icon: IconRulerInv,
                title: '실시간 위치 감지 및 경보',
                desc: '지오펜스는 대상의 실시간 위치를 감지하고 특정 지역에서의 이탈 또는 진입을 감지하여 즉각적인 경보를 발생시킵니다. 이는 보안 시스템에서 빠른 대응과 정확한 경보를 가능케 하여 안전을 강화합니다.',
            },
            {
                icon: IconTagInv,
                title: '다양한 활용을 위한 시뮬레이션',
                desc: '지오펜스는 가상 경계를 설정하여 다양한 시나리오를 시뮬레이션하고 해당 지역에서의 상황을 사전에 파악할 수 있습니다. 이는 물류 최적화, 교통 흐름 관리 등 다양한 분야에서 효과적인 응용을 가능케 합니다.',
            },
            {
                icon: IconChartInv,
                title: '비용 절감 및 자산 수명 연장',
                desc: '지오펜스를 활용하면 물리적인 대상의 위치와 상태를 실시간으로 파악하여 유지보수 일정을 최적화할 수 있습니다. 이는 불필요한 비용을 절감하고 자산의 수명을 연장시키는 데 도움이 됩니다.',
            },

        ]
    }



}