export default {
  data: [
    {
      title: 'Construction',
      page: [
        { name: 'Worker Safety Management', link: '/en/enterprise/construction/safety' },
        { name: 'Construction Asset Tracking and Management', link: '/en/enterprise/construction/asset' },
        { name: 'Heavy Equipment Management', link: '/en/enterprise/construction/equipment' },
      ]
    },
    {
      title: 'Logistics',
      page: [
        { name: 'Asset Tracking and Management', link: '/en/enterprise/logistics/asset' },
        { name: 'Warehouse Worker Safety Management', link: '/en/enterprise/logistics/safety' },
      ]
    },
    {
      title: 'Office',
      page: [
        { name: 'Asset Management', link: '/en/enterprise/office/asset' },
      ]
    },
    {
      title: 'Medical',
      page: [
        { name: 'Patient Tracking', link: '/en/enterprise/medical/patient' },
        { name: 'Medical Waste Management', link: '/en/enterprise/medical/waste' },
      ]
    },
    {
      title: 'Manufacturing',
      page: [
        { name: 'Real-time Process Management', link: '/en/enterprise/manufacturing/process' },
        { name: 'Real-time Asset Management', link: '/en/enterprise/manufacturing/asset' },
      ]
    },
    {
      title: 'Retail',
      page: [
        { name: 'Exhibition Visitor Analysis', link: '/en/enterprise/retail/exhibition' },
      ]
    },
    {
      title: 'Download',
      page: [
        { name: 'iOS', link: '/en/enterprise/logistics/safety' },
        { name: 'Android', link: '/en/enterprise/logistics/safety' },
      ]
    },
    {
      title: 'Support',
      page: [
        { name: 'Technology', link: '/en/enterprise/technology' },
        { name: 'Blog', link: '/blog' },
        { name: 'Contact', link: '/request' },
        { name: 'Company', link: '/company' },
      ]
    },
    {
      title: 'Partner',
      page: [
        { name: 'ORBRO Home', link: '/' },
        { name: 'Kongcheck', link: '/en/enterprise/logistics/safety' },
        { name: 'Plutocon', link: '/en/enterprise/logistics/safety' },
      ]
    },
    {
      title: 'Customer Service',
      page: [
        { name: 'Phone: (+82)1522 - 9928' },
        { name: 'Email: pr@orbro.io' },
      ]
    },
  ]
}
