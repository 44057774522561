import { constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";
import digitaltwin from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {


    helmet: '| 디지털 전환(Digital Transformation)이란?',
    desc: '디지털 전환은 기존의 비즈니스와 사회를 디지털 기술로 혁신하는 과정을 말합니다. 즉, 전통적인 방식에서 디지털 기술을 도입하여 업무, 서비스, 소통 등을 효율적이고 혁신적으로 개선하는 과정이라고 할 수 있습니다.',
    keywords: '스마트시티, 디지털트윈, 디지털 전환, smart city, digital twin, digital transformation, 실시간 위치 추적, rtls, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: '디지털 전환(Digital Transformation)이란? | 오브로',
    ogUrl: 'https://orbro.io/blog/what-is-digital-transformation',
    ogKey: '',
    ogImg: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '디지털 전환(Digital Transformation)이란?',
    date: '2024-01-10',
    tag1: '#디지털전환',
    tag2: '#디지털트윈',
    tag3: '#DX',
    tag4: '#스마트시티',

    // <br/>
    text: [
        {
            head: '디지털 전환(Digital Transformation)이란?',
            subHead: '',
            desc: '디지털 전환은 기존의 비즈니스와 사회를 디지털 기술로 혁신하는 과정을 말합니다. 즉, 전통적인 방식에서 디지털 기술을 도입하여 업무, 서비스, 소통 등을 효율적이고 혁신적으로 개선하는 과정이라고 할 수 있습니다. 이는 주로 빅데이터, 클라우드 컴퓨팅, 인공지능, 자동화 등의 기술을 활용하여 비즈니스 모델과 프로세스를 새롭게 구성하고, 이를 통해 생산성을 높이고 경쟁력을 강화하는 것을 목표로 합니다. 따라서 디지털 전환은 단순히 기술적인 변화뿐만 아니라 조직 문화와 업무 방식에도 영향을 미치며, 미래에 걸친 지속적인 변화의 과정으로 이해할 수 있습니다.',
            dot: ''
        },
        {
            head: '디지털 전환의 주요 특징',
            subHead: '데이터 중심의 경영 (Data-Driven Management)',
            desc: '기업은 대량의 데이터를 수집하고 분석하여 정확한 비즈니스 의사 결정을 내립니다. 이는 빅데이터 기술과 분석을 통해 고객 행동을 예측하고 전략을 수립하는 데 도움이 됩니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '자동화 및 인공지능 (Automation and Artificial Intelligence)',
            desc: '업무 프로세스의 자동화는 기업의 생산성 향상을 이끌어내며, 인공지능 기술을 도입하여 예측 분석, 자연어 처리, 이미지 인식과 같은 복잡한 작업을 효율적으로 수행합니다. 이는 인간 업무 부담을 감소시키는 효과를 가져옵니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '클라우드 컴퓨팅 (Cloud Computing)',
            desc: '클라우드 기술은 유연한 IT 자원 활용을 가능하게 하여 기업이 인프라 비용을 절감하고, 필요에 따라 자원을 확장하거나 축소할 수 있도록 합니다. 이로써 업무 프로세스가 더욱 유동적으로 운영될 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '사물인터넷 (Internet of Things, IoT)',
            desc: '사물인터넷은 물리적인 기기들이 연결되어 데이터를 교환하고 상호작용하는 개념입니다. 이는 제조업에서의 생산라인 최적화부터 스마트 홈의 편리한 제어, 건강 관리 분야에서의 모니터링에 이르기까지 다양한 분야에서 적용되어 효율성을 높이고 혁신을 이끌어냅니다.',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/digital-twin',
            videoDesc: '(영상을 누르면, 오브로 디지털트윈 페이지로 이동합니다.)',
            head: '디지털 전환의 사례',
            subHead: '',
            desc: '디지털 전환은 다양한 산업과 기업에서 이루어지고 있으며, 각각의 사례에서는 특정한 디지털 기술을 도입하여 비즈니스를 혁신하거나 프로세스를 최적화하는 방식이 다르게 나타납니다. 아래는 몇 가지 대표적인 디지털 전환 사례입니다.',
            dot: {
                a: '<strong> • 금융 서비스 업계 - FinTech : </strong>금융 서비스 업계에서의 디지털 전환은 FinTech 기업들이 전통적인 은행체계를 도전하고 있습니다. 모바일 뱅킹, 전자 지불 시스템, 투자 로봇 등의 기술을 도입하여 고객들에게 더 편리하고 저렴한 서비스를 제공하고 있습니다. 모바일 앱을 통한 은행 업무, 온라인 투자 플랫폼, 개인 금융 관리 앱 등이 FinTech의 주요 사례로 언급됩니다.',
                b: '<strong> • 제조업 - 스마트 팩토리 : </strong>제조업에서의 디지털 전환은 스마트 팩토리의 개념으로 나타납니다. 사물인터넷 (IoT), 빅데이터, 인공지능 기술을 활용하여 생산 라인을 최적화하고 실시간 생산 데이터를 분석하여 효율성을 높입니다. 센서와 IoT 기술을 통한 생산 라인 모니터링, 빅데이터 분석을 통한 유지보수 예측, 로봇과 자동화를 통한 생산 과정 최적화 등이 스마트 팩토리의 사례입니다.',
                c: '<strong> • 소매업 - 온라인 쇼핑 및 개인화 마케팅 : </strong>소매업에서의 디지털 전환은 온라인 쇼핑 플랫폼과 개인화 마케팅을 강조합니다. 데이터 분석을 기반으로 한 개인화된 추천 시스템, 가상 시창 및 실시간 인벤토리 관리 등이 소매업체의 디지털 전환 사례입니다. 고객의 이전 구매 기록을 분석하여 맞춤형 제품 추천, 가상 피팅룸을 통한 온라인 쇼핑 경험, 지점 내 디지털화된 결제 시스템 등이 이에 해당합니다.',
                d: '<strong> • 의료 분야 - 전자 건강 기록 : </strong>의료 분야에서의 디지털 전환은 전자 건강 기록 (EHR) 시스템 도입 및 텔레메디슨 기술을 통한 원격 진료와 모니터링에 집중합니다. 환자 데이터를 디지털로 관리하고 의료 서비스를 온라인으로 제공하여 효율성을 높입니다. 전자 건강 기록 시스템 도입, 원격 진료를 위한 비디오 콜 플랫폼, 환자의 건강 상태를 실시간으로 모니터링하는 센서 등이 의료 분야의 디지털 전환 사례로 볼 수 있습니다.',
            }
        },
        {
            head: '디지털 전환의 핵심 기술',
            subHead: '인공지능 (Artificial Intelligence, AI)',
            desc: 'AI는 컴퓨터 시스템이 인간과 유사한 학습, 추론, 문제 해결 능력을 갖도록 하는 기술을 포함합니다. 머신 러닝, 딥 러닝, 자연어 처리 등이 AI의 하위 분야로 사용되며, 이를 활용하여 데이터 분석, 예측 분석, 자동화 등 다양한 작업을 수행할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '빅데이터 (Big Data)',
            desc: '빅데이터는 대규모의 다양한 종류의 데이터를 수집, 저장, 분석하는 기술입니다. 대량의 데이터를 활용하여 통계적 패턴, 트렌드, 인사이트를 발견하고 비즈니스 의사 결정을 지원합니다. 클라우드 컴퓨팅과 연계하여 대용량 데이터를 처리하고 저장합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '클라우드 컴퓨팅 (Cloud Computing)',
            desc: '클라우드 컴퓨팅은 인터넷을 통해 컴퓨터 자원(서버, 스토리지, 네트워크 등)을 제공하는 기술로, 필요에 따라 자원을 유연하게 조절할 수 있습니다. 기업은 클라우드를 통해 인프라 비용을 절감하고 빠르게 서비스를 제공할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '인터넷 of Things (IoT)',
            desc: 'IoT는 사물에 센서와 통신 기능을 내장하여 인터넷을 통해 상호 연결되게 만드는 기술입니다. 이를 통해 다양한 디바이스들이 데이터를 수집하고 서로 소통하며, 생활 및 업무 환경을 지능화할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '로봇공학 및 자동화 (Robotics and Automation)',
            desc: '로봇공학과 자동화 기술은 기계가 인간의 업무를 대체하거나 협업하여 수행하는 기술을 의미합니다. 이는 제조업뿐만 아니라 서비스 산업에서도 활용되어 생산성과 효율성을 향상시킵니다.',
            dot: ''
        },
        {
            head: '디지털 전환의 장점',
            subHead: '경쟁 우위 확보',
            desc: '디지털 전환은 새로운 비즈니스 모델의 도입과 기존 프로세스의 최적화를 통해 경쟁 우위를 확보할 수 있습니다. 효율적인 데이터 활용과 더 나은 서비스 제공은 시장에서 더 큰 인지도와 긍정적인 평가를 가져올 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '고객 경험 개선',
            desc: '고객 중심의 디지털 전환은 고객 경험을 향상시킵니다. 개인화된 서비스, 편리한 디지털 인터페이스, 신속한 응답 등은 고객들에게 더 나은 경험을 제공하고 이는 충성도를 높이는데 기여합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '비용 절감과 효율성 향상',
            desc: '자동화, 클라우드 컴퓨팅, 데이터 분석 등의 디지털 기술 도입은 업무 프로세스를 효율적으로 만들어 비용을 절감하고 생산성을 향상시킵니다. 더 빠르고 정확한 업무 수행은 인력 및 자원을 효과적으로 활용할 수 있도록 합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '시장 대응 능력 강화',
            desc: '디지털 전환은 급변하는 시장 환경에 대응하는 데 도움을 줍니다. 유연한 비즈니스 모델과 데이터 기반의 의사 결정은 변화에 신속하게 대응할 수 있도록 도와줍니다. 또한 새로운 기술의 도입과 기존 비즈니스 모델의 변화는 혁신을 촉진하고 새로운 기회를 창출합니다. 이는 새로운 시장 진입, 제품 혁신, 파트너십 형성 등 다양한 방식으로 나타날 수 있습니다.',
            dot: ''
        },

    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            }
        ]

    }


}