import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4';

export default {

    desc: 'AI 카메라 위치 추적 솔루션은 인공지능 기술을 활용하여 카메라로부터 받은 영상 데이터를 분석하고 처리하여 사람과 차량의 위치를 실시간으로 추적합니다.',
    keywords: '스마트 주차장, AI Camera, 인공지능 카메라, 디지털트윈, 디지털 전환, digital twin, orbro, beacon, rtls, uwb, ble, ai camera, 오브로, 콩테크',

    ogSite: 'AI 카메라 위치추적 솔루션 | 디지털트윈 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/ai-camera',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkc4tGW9Sjxkfqer33MMng2ket3NNLJzV7Iz2LLirs_1Vbk4Lhm63aO_z8WWd2dIov4g4YuW4_LF8fiQn1v8CAQMxvfMbY58snCZaz5-tmYX7MljahebCQULlqUN3wwe4XqTP-9XdR2jE5QpHWOWD2ddnpV3jfnK3qIltSnKR3JEpPdmrECN49rXQbSa7jNtNAFPC71gTTRBtV-koIsqQYpX0un1kLVJJZxwlqQNH5_tGeA9HHAmQRhtGxmF0IkPCicaA2wexfmrwboGdPR9_tkKklNF54F6EulnMKypq_qaskVAEZvDlYArvBFhnatgUVy0Uh4yo-mw9wek4fNrsUOfIb2QjUoGzYVul27F9GFN5eXULvD3zPSTOeZHF_Fy7_VBfp_A0ugMGL_EuF2olRd-eBnVJt3xGOvMOIfgcA6AfXHOmhu7sbHo1yBoJ6cyTvJQzLMafMxmStxH5CKMzD_3FKEw0pJ25EuuAy7I5e8Pp_LxdZg_GjN8cj5qXbhIpnxCfSmd5KOol3tgfS1jCnXsAbDJ_XSVp4EBn_zgXhTswmP02m9R2XxafKdWMa-ZhZE5l4o_T_ZBOoWKwRfJXJtMcTJspT2tOfode7mVpQFhU5pa6KtAqcJtVuS1i2r4rjQDEI92DQCh0CDmnkM041jtKKL9oFXlO0GQLOX0j_Z7O1R3qZ0awsO_O7gBkBGK0CJ0PlT2_JW3eGFtPu5zROSwOKF-7rcJMH1f6R29Nf1bhejjB2zRRyISB5J6Psjf7wk3LffQaSUsmJy1Wl_J11fQUBTzMAl-mUnwqquWh2eEijlvT-y4Ixu8zeRJSM7Fk61_nWCu6F48eu4Ge_4geMR2DOjfQOnLqKKfSWY_XdzxDDeACmf6PTAgeWFDzx7MZJ6vN7yp75G8Ez9xTKMQGpcfn4qwI8oA2W3qx_NtVJ6AFT0dD7vLS87ML4D7eHI8uB2pjBlqb6v4rbxp41pQGAQ_VuldNTZNUZH8q2ddGnDJ1PVh_eKBB7uoGEPUO2Lbvws6VRw93FJDgIzqXUkPwDHMVOhhjsFBWBcBQtOh2fbVWyT1k9_jRFfeo2zJ3hPWat4kTns5Ew671LTXXGJwXGHGQB0bqGCqwhwBJGjwlPS7NFxhhDYocq8P-a1r6dyKzlEmh18hbWIc-Rz7z8h0_Fv-a4EeOmoNwTQMQl1QaWugyzqkqs1wUDKtJb7dHQBcMSkzJe-fylN3_peFQAyizDmhXmlZmjYqOOCHEXHuEyxbL8ILOK_8KxHyahhYwMSk13KrKVMR0ThHJkCcsfyA-hS9jzo4Ww8q3ARcr0R7Yb-361rI8aluKtbzPbGZHWwOQCMnDv8itfAOTiVU8l3vEfE6TPQUytUs03-_CGTmMrgpoxFxRDPkJFNwEa0671QbXV5AwJNTviSSe9q27H-I59Id0McwUZGQBn0z8XOjoJyCBZ27zRD9tSbBg-B1s1crfHk5RU8bIvgfwCsov_1oVufbxVy223DtdDzlernhw9DYzq5IUYKPoL-lZXZiS4er28lhBzPfXz4feDNHNSzB0cP_-4p-UlNtXaQtKzLGIu3WX-VqHW-=w2059-h1270',
    ogType: 'website',

    title: 'AI 카메라 위치추적 솔루션',
    date: '2024-02-07',
    tag1: '#ai카메라',
    tag2: '#위치추적',
    tag3: '#차량추적',
    tag4: '#디지털트윈',

    // <br/>
    text: [
        {
            head: 'AI 카메라란?',
            subHead: '',
            desc: 'AI 카메라 위치 추적 솔루션은 인공지능 기술을 활용하여 카메라로부터 받은 영상 데이터를 분석하고 처리하여 사람과 차량의 위치를 실시간으로 추적합니다. 이를 통해 보안, 교통 관리, 비디오 모니터링 등 다양한 분야에서 활용될 수 있습니다. <br/>즉, 기존의 일반적인 카메라와는 달리 영상 데이터를 분석하고 처리하여 더욱 지능적으로 작동할 수 있습니다.',
            dot: ''
        },
        {
            head: "AI 카메라 주요 기능",
            subHead: "실시간 위치 추적",
            desc: "AI 카메라는 사람과 차량의 움직임을 빠르고 정확하게 실시간으로 추적하여, 그들의 현재 위치를 식별하고 기록합니다. 이를 통해 보안 시스템에서는 잠재적인 위협을 신속하게 파악하고, 교통 관리 시스템에서는 교통 흐름을 실시간으로 모니터링하며 상황에 따른 조치를 취할 수 있습니다."
        },
        {
            head: "",
            subHead: "영상 분석 및 인식",
            desc: "AI 카메라는 영상 분석 및 객체 인식을 통해 영상 속에서 다양한 객체를 자동으로 식별하고 분류할 수 있습니다. 이를 통해 보안 시스템에서는 침입자나 이상 행동을 신속하게 감지하고, 교통 관리 시스템에서는 교통 상황을 정확히 분석하여 문제를 예방하고 효율적으로 관리할 수 있습니다."
        },
        {
            head: "",
            subHead: "다중 카메라 지원",
            desc: "AI 카메라는 다수의 카메라를 통합하여 넓은 영역을 동시에 모니터링할 수 있습니다. 이를 통해 보다 넓은 지역을 커버하고, 다양한 각도에서의 정보를 효과적으로 수집할 수 있습니다. 또한, 다중 카메라 지원 기능을 활용하여 교차로나 큰 규모의 건물 등 다양한 환경에서 보안 및 안전 관리에 보다 강력한 솔루션을 제공할 수 있습니다."
        },
        {
            head: "",
            subHead: "사용자 정의 기능",
            desc: "AI 카메라는 사용자가 필요에 따라 원하는 기능을 추가하고 설정할 수 있습니다. 사용자 정의 기능은 다양한 환경에 맞추어 유연하게 조정할 수 있으며, 특정한 요구사항에 따라 정확하고 효과적인 모니터링을 제공할 수 있습니다. 또한, 사용자 정의 기능을 통해 AI 카메라의 성능을 최적화하고 업그레이드할 수 있습니다."
        },
        {
            video: video,
            link: '/enterprise/construction/equipment',
            head: "AI 카메라 기술",
            subHead: "영상 처리 및 분석",
            desc: "AI 카메라는 카메라가 촬영한 영상 데이터를 빠르고 정확하게 실시간으로 분석합니다. 이를 위해 최신의 인공지능 알고리즘이 사용되며, 객체 검출, 추적, 분류 등의 주요 요소가 포함됩니다. 이러한 기술을 통해 AI 카메라는 영상 데이터를 신속하게 처리하고, 사람과 차량 등의 객체를 정확하게 식별하고 추출할 수 있습니다."
        },
        {
            head: "",
            subHead: "객체 검출",
            desc: "AI 카메라는 영상 속에서 사람과 차량 등의 객체를 빠르게 검출합니다. 최신의 딥러닝 기술을 활용하여 객체를 정확하게 식별하고 추출할 수 있습니다. 이를 통해 AI 카메라는 다양한 환경에서 객체를 신속하게 감지하고 이를 기반으로 추가적인 분석이나 조치를 취할 수 있습니다."
        },
        {
            head: "",
            subHead: "추적 및 위치 파악",
            desc: "검출된 객체의 움직임을 실시간으로 추적하여 그들의 위치를 파악합니다. 이를 통해 AI 카메라는 사람과 차량의 움직임을 지속적으로 모니터링하고, 필요한 경우 신속하게 대응할 수 있습니다. 또한, 추적된 객체의 이동 경로를 분석함으로써 추가적인 인사이트를 제공할 수도 있습니다."
        },
        {
            head: "",
            subHead: "데이터 분석 및 저장",
            desc: "추적된 데이터는 분석 및 저장됩니다. 이를 통해 AI 카메라는 추적된 객체의 이동 패턴이나 행동을 분석할 수 있으며, 필요한 경우 데이터베이스에 저장되어 나중에 검토하거나 보고서를 작성하는 데 사용될 수 있습니다. 이를 통해 보안 시스템에서는 사건의 원인과 경과를 분석하여 보다 효과적으로 대응할 수 있습니다."
        },
        {
            head: "적용 분야",
            subHead: "보안 시스템",
            desc: "AI 카메라는 건물 내부나 주차장 등과 같이 보안이 필요한 장소에서 사람과 차량의 이동을 실시간으로 추적하여 보안을 강화할 수 있습니다. 또한, 이러한 데이터를 분석하여 보다 정확한 위험 예측 및 대응을 제공할 수 있습니다."
        },
        {
            head: "",
            subHead: "교통 관리",
            desc: "도로나 교차로와 같은 교통 관련 시설에서 AI 카메라를 활용하여 교통 상황을 실시간으로 모니터링하고 관리할 수 있습니다. 이를 통해 교통 체증이나 사고의 발생을 미리 예방하고, 교통 흐름을 최적화할 수 있습니다."
        },
        {
            head: "",
            subHead: "상업용",
            desc: "상점이나 백화점과 같은 상업 시설에서는 AI 카메라를 통해 고객의 이동 경로와 행동을 추적하여 매장 레이아웃을 최적화하거나 마케팅 전략을 수립할 수 있습니다. 또한, 이를 통해 고객의 선호도를 분석하고 개선할 수 있습니다."
        },
        {
            head: "",
            subHead: "스마트 시티",
            desc: "도시 내 다양한 위치에 설치된 AI 카메라를 통해 시민의 이동 패턴을 파악하고, 도시의 인프라를 효율적으로 관리할 수 있습니다. 이를 통해 도시의 안전성과 편의성을 향상시키고 스마트 시티 구축에 기여할 수 있습니다."
        },
        {
            head: '결론',
            subHead: '',
            desc: 'AI를 활용한 카메라 위치 추적 솔루션은 사람과 차량의 위치를 실시간으로 추적하여 다양한 분야에서 활용될 수 있는 효율적인 기술입니다. 보안, 교통 관리, 상업용 등 다양한 산업 분야에서 그 활용 가능성을 확인할 수 있으며, 더 나은 안전과 효율성을 제공할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: '',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/enterprise/construction/equipment'
            }
        ]


    }


}