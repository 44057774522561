import { TechUseCaseIcon3D, TechUseCaseIconBuilding } from "@assets/images/technology/common";
import { IconBattery, IconCar, IconChartInv, IconHeart, IconHome, IconRulerInv, IconTagInv } from "@assets/images/icon";
import video from "@src/assets/images/solution/manufacturingProcess/video/rtlsVideo.mp4";

export default {

    seo: {
        title: '디지털트윈(Digital Twin)이란? | 디지털트윈 전문 플랫폼',
        desc: '디지털트윈은 현실의 데이터를 실시간으로 수집하여 이를 가상 화면에 반영하여 동일하게 구현하는 기술입니다. 이렇게 수집된 데이터는 클라우드 기반의 플랫폼에서 저장, 분석되어 다양한 정보를 생성하게 됩니다.',
        keywords: 'digital twin, 디지털 전환, 디지털트윈, 스마트 빌딩, 가상현실, rtls, 위치추적, 비콘, orbro, 오브로, 콩테크',
        img: 'https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238',
        url: 'https://orbro.io/enterprise/technology/what-is-digital-twin',
        type: 'website',
    },

    tag: 'What is Digital Twin?',
    title: '디지털트윈(Digital Twin)이란?',
    desc: '',

    btnText: '오브로 디지털트윈',
    btnLink: '/digital-twin',

    text: [
        {
            head: '디지털트윈(Digital Twin)이란?',
            desc: '디지털트윈은 현실의 데이터를 실시간으로 수집하여 이를 가상 화면에 반영하여 동일하게 구현하는 기술입니다. 이렇게 수집된 데이터는 클라우드 기반의 플랫폼에서 저장, 분석되어 다양한 정보를 생성하게 됩니다. 이러한 정보는 물리적인 대상의 상태, 성능, 유지보수 예측 등 다양한 측면에서 가치를 창출하며, 의사 결정을 지원하는 데 활용됩니다.<br/><br/>예를 들어, 제조업에서 디지털트윈은 생산 과정의 상태를 정확하게 모니터링하고, 예측 분석을 통해 장비의 유지보수 일정을 최적화할 수 있습니다. 이는 생산 시간을 줄이고 생산품의 품질을 향상시키는 데 도움이 됩니다.<br/>즉, 디지털트윈은 실제 시스템의 운영 상태를 실시간으로 모니터링합니다. 물리적인 대상의 상태 변화나 이상 징후를 빠르게 감지하여 조치를 취할 수 있어 시스템의 안정성을 높이고 문제를 사전에 예방할 수 있습니다. 또한, 디지털트윈은 시뮬레이션을 통해 다양한 시나리오를 실험하고 최적의 운영 방식을 찾을 수 있는 기회를 제공합니다. 이를 통해 새로운 기술 도입이나 프로세스 개선 등의 의사 결정이 더욱 정확하고 효과적으로 이루어질 수 있습니다.<br/><br/>디지털트윈은 또한 수명 주기 관리, 유지보수 예측, 성능 최적화, 제품 개발 등 다양한 영역에서 적용되며, 기업들은 이를 통해 비용 절감과 생산성 향상을 이룰 수 있습니다. 이러한 장점들은 디지털트윈이 현대 산업의 핵심 기술로 부상하게 된 주요 이유 중 하나입니다.',
            subHead: '',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/enterprise/manufacturing/process',
            head: '디지털트윈의 장점',
            desc: '',
            subHead: [
                {
                    title: '실시간 모니터링 및 분석',
                    desc: '디지털트윈은 물리적인 대상의 실시간 데이터를 수집하고 분석하여 운영 상태를 정확하게 모니터링할 수 있습니다. 이는 문제를 빠르게 감지하고 조치를 취함으로써 생산성을 향상시키고 시스템의 안전성을 높입니다.'
                },
                {
                    title: '시뮬레이션과 최적화',
                    desc: '디지털트윈을 사용하면 가상 모델을 활용하여 다양한 시나리오를 시뮬레이션하고 최적의 운영 방식을 찾을 수 있습니다. 이는 비용을 절감하고 효율을 극대화할 수 있는 기회를 제공합니다.'
                },
                {
                    title: '유지보수 및 수명 주기 관리',
                    desc: '물리적인 대상의 상태를 정확하게 파악하면 유지보수 일정을 최적화하고 장비의 수명 주기를 효과적으로 관리할 수 있습니다. 이는 비용 절감과 시스템의 신뢰성을 높이는 데 도움이 됩니다.'
                },
            ],
            dot: ''
        },
        {
            head: '디지털트윈 유형',
            desc: '디지털트윈은 다양한 유형으로 나눌 수 있으며, 이는 산업 분야, 응용 분야, 물리적 대상의 특성 등에 따라 다양하게 구분될 수 있습니다. 여러 유형 중에서 몇 가지 주요한 디지털트윈의 유형은 다음과 같습니다:',
            subHead: [
                {
                    title: '제품 트윈(Product Twin)',
                    desc: '제품 트윈은 제품의 생명 주기 동안 발생하는 데이터를 사용하여 제품의 상태와 성능을 디지털적으로 모델링한 것입니다. 제조업에서는 제품 트윈을 사용하여 제품의 설계, 제조, 운영, 유지보수 등 모든 단계에서 정보를 추적하고 최적화할 수 있습니다.',
                },
                {
                    title: '프로세스 트윈(Process Twin)',
                    desc: '프로세스 트윈은 제조 공정이나 시설의 운영과 같은 복잡한 프로세스를 디지털화한 것입니다. 이는 생산 과정의 최적화, 에너지 효율성 향상, 공정 안전성 등을 감시하고 개선하는 데 사용됩니다.',
                },
                {
                    title: '시설 트윈(Facility Twin)',
                    desc: '시설 트윈은 건물이나 시설의 물리적인 특성을 디지털로 표현한 것입니다. 에너지 소비, 유지보수 일정, 환경 요소 등을 실시간으로 모니터링하여 건물의 효율성을 최적화합니다.',
                },
                {
                    title: '도시 트윈(City Twin)',
                    desc: '도시 트윈은 도시의 인프라, 교통, 환경 등을 디지털 모델로 표현한 것입니다. 스마트 시티 개념에서는 도시 트윈을 활용하여 교통 흐름 최적화, 에너지 효율성 개선, 공공 안전 강화 등 다양한 도시 관리 측면에서 활용됩니다.',
                },
                {
                    title: '생체 트윈(Biomechanical Twin)',
                    desc: '의료 분야에서는 환자의 생체 정보를 수집하여 생체 트윈을 만들어 환자의 건강 상태를 디지털로 표현합니다. 이를 통해 의료진은 환자의 상태를 실시간으로 모니터링하고 진단에 활용할 수 있습니다.',
                },
                {
                    title: '디지털트윈의 서비스(Twin of a Service)',
                    desc: '제품이나 프로세스뿐만 아니라, 서비스에 대한 디지털트윈도 등장하고 있습니다. 서비스의 운영 및 성능을 디지털화하여 효율적으로 관리하고 개선하는 데 활용됩니다',
                },
            ],
            dot: ''
        },
        {
            videoDesc: '(제조시설 디지털트윈, 클릭하면 이동합니다)',
            tag: 'noBottomLine',
            head: '디지털트윈 기술 작동 방식',
            desc: '디지털트윈은 물리적인 대상의 디지털화를 통해 현실 세계를 정확하게 반영하는 가상의 모델입니다. 디지털트윈의 작동 방식은 다음과 같은 단계로 요약될 수 있습니다:',
            subHead: [
                {
                    title: '1. 센서 및 데이터 수집',
                    desc: '디지털트윈의 작동은 물리적인 대상에서 발생하는 다양한 데이터를 수집하는 것으로 시작합니다. 이를 위해 센서, IoT(사물 인터넷) 장치, 카메라 등이 사용됩니다. 이러한 센서들은 물리적인 대상의 상태, 위치, 성능 등에 관한 정보를 수집합니다.',
                },
                {
                    title: '2. 데이터 전송 및 저장',
                    desc: '수집된 데이터는 클라우드 기반의 플랫폼이나 중앙 데이터베이스로 전송되어 저장됩니다. 이 데이터는 대량이며 다양한 형식일 수 있으며, 실시간으로 전송되거나 일정 주기로 업데이트될 수 있습니다.',
                },
                {
                    title: '3. 가공 및 모델링',
                    desc: '저장된 데이터는 가공되어 가상 모델에 반영됩니다. 이 과정에서 머신러닝 알고리즘이나 다양한 분석 기술이 활용될 수 있습니다. 가상 모델은 물리적인 대상의 특성을 정확하게 모사하고 상태를 디지털로 표현합니다.',
                },
                {
                    title: '4. 실시간 업데이트',
                    desc: '센서 데이터의 실시간 업데이트는 디지털트윈이 물리적인 대상의 현재 상태를 정확하게 반영할 수 있도록 합니다. 이로써 디지털트윈은 실제 세계의 변화에 빠르게 대응할 수 있습니다.',
                },
                {
                    title: '5. 시뮬레이션 및 최적화',
                    desc: '디지털트윈은 시뮬레이션을 통해 다양한 시나리오를 실험하고 최적의 운영 방식을 찾을 수 있습니다. 예를 들어, 특정 조건에서의 성능을 예측하거나 변경된 환경에서의 시뮬레이션을 통해 의사 결정에 도움을 줍니다.',
                },
                {
                    title: '6. 인터페이스 및 시각화',
                    desc: '디지털트윈의 결과물은 사용자 인터페이스를 통해 시각화되어 사용자들이 쉽게 이해하고 모니터링할 수 있습니다. 이는 실제 물리적인 대상의 상태, 성능, 예측 등에 대한 정보를 직관적으로 제공합니다.<br/><br/>이러한 방식으로 디지털트윈은 물리적인 대상을 디지털 화면 상에 옮겨놓음으로써 현실 세계를 정확하게 반영하고, 이를 통해 생산성을 향상시키고 문제 해결에 기여합니다.',
                },
            ],
            dot: ''
        },
        {
            tag: 'card',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
        {
            head: '디지털트윈 구축 방법',
            desc: '디지털트윈을 구축하는 과정은 산업 분야와 적용 도메인에 따라 다양할 수 있지만, 일반적으로 다음 단계를 포함합니다:',
            subHead: '',
            dot: [
                {
                    title: '1. 목표 및 범위 정의:',
                    desc: [
                        {
                            text: ' • 어떤 물리적인 대상을 디지털트윈으로 모델링할지 결정합니다.',
                        },
                        {
                            text: ' • 목표는 성능 향상, 유지보수 간소화, 시뮬레이션 및 최적화 등에 중점을 둘 수 있습니다.',
                        },
                    ]
                },
                {
                    title: '2. 센서 및 데이터 수집 계획 수립:',
                    desc: [
                        {
                            text: ' • 필요한 센서와 데이터 수집 방법을 결정합니다.',
                        },
                        {
                            text: ' • 데이터 수집 주기, 형식, 보안 및 프라이버시 고려 사항 등을 고려하여 계획을 수립합니다.',
                        },
                    ]
                },
                {
                    title: '3. 데이터 인프라 구축:',
                    desc: [
                        {
                            text: ' • 수집된 데이터를 저장하고 처리하기 위한 데이터 인프라를 구축합니다.',
                        },
                        {
                            text: ' • 클라우드 기반의 플랫폼이나 온프레미스 솔루션을 선택하고 구현합니다.',
                        }
                    ]
                },
                {
                    title: '4. 가상 모델링 및 시뮬레이션:',
                    desc: [
                        {
                            text: ' • 수집된 데이터를 사용하여 물리적인 대상의 가상 모델을 만듭니다.',
                        },
                        {
                            text: ' • 이 모델은 물리적인 대상의 특성을 정확하게 반영하고, 시뮬레이션을 통해 다양한 시나리오를 실험합니다.',
                        }
                    ]
                },
                {
                    title: '5. 데이터 분석과 모델 학습:',
                    desc: [
                        {
                            text: ' • 수집된 데이터를 분석하고 머신러닝 기술을 활용하여 가상 모델을 개선합니다.',
                        },
                        {
                            text: ' • 예측 모델이나 문제 해결을 위한 알고리즘을 구현하여 학습시킵니다.',
                        },
                    ]
                },
                {
                    title: '6. 실시간 모니터링 및 업데이트:',
                    desc: [
                        {
                            text: ' • 센서로부터 실시간 데이터를 수집하고, 디지털트윈을 실시간으로 업데이트하여 현실 세계의 변화에 빠르게 대응합니다.'
                        },
                        {
                            text: ' '
                        },
                    ]
                },
                {
                    title: '7. 보안 및 개인정보 보호 강화:',
                    desc: [
                        {
                            text: ' • 민감한 데이터를 적절히 보호하기 위해 보안 메커니즘을 구현합니다.',
                        },
                        {
                            text: ' • 개인정보 보호와 관련된 규정과 규제를 준수합니다.',
                        }
                    ]
                },
                {
                    title: '8. 사용자 인터페이스 및 시각화:',
                    desc: [
                        {
                            text: ' • 디지털트윈의 결과를 사용자가 쉽게 이해하고 모니터링할 수 있는 사용자 인터페이스를 개발합니다.',
                        },
                        {
                            text: ' • 시각화 도구를 통해 가상 모델의 상태 및 성능을 시각적으로 확인할 수 있도록 합니다.',
                        }
                    ]
                },
                {
                    title: '9. 지속적인 개선과 최적화:',
                    desc: [
                        {
                            text: ' • 운영 중에 성능 및 효과를 지속적으로 모니터링하고, 필요에 따라 디지털트윈을 개선하고 최적화합니다.',
                        },
                        {
                            text: ' • 새로운 데이터 소스나 기술의 도입을 통해 시스템을 업그레이드합니다.',
                        }
                    ]
                }
            ]
        },
        {
            tag: 'advantage',
            head: '',
            desc: '',
            subHead: '',
            dot: ''
        },
    ],



    card: {
        title: '디지털트윈 사용 사례',
        desc: '디지털트윈은 다양한 산업 분야에서 다양하게 활용되고 있습니다. 아래는 몇 가지 대표적인 디지털트윈 사용 사례입니다',
        cards: [
            {
                icon: TechUseCaseIcon3D,
                title: '제조시설 디지털트윈',
                desc: [
                    {
                        title: '설비 모니터링 및 최적화',
                        desc: '생산 라인과 설비를 디지털트윈으로 모델링하여 실시간으로 상태를 모니터링하고, 예측 분석을 통해 유지보수 일정을 최적화합니다.',
                    },
                    {
                        title: '제품 개발과 시뮬레이션',
                        desc: '제품의 디지털트윈을 생성하여 설계 단계부터 생산 단계까지의 과정을 시뮬레이션하고 최적의 디자인을 찾습니다.',
                    }
                ]
            },
            {
                icon: IconHome,
                title: '건설 및 부동산 디지털트윈',
                desc: [
                    {
                        title: '건축물 모니터링',
                        desc: '건물이나 인프라 구조물의 건강 상태를 실시간으로 모니터링하여 구조물의 유지보수 일정을 최적화하고 안전성을 향상시킵니다.',
                    },
                    {
                        title: '도시 계획 및 시뮬레이션',
                        desc: '도시의 트래픽, 에너지 사용, 환경 영향 등을 디지털트윈으로 시뮬레이션하여 효율적인 도시 계획을 수립합니다.',
                    }
                ]
            },
            {
                icon: IconBattery,
                title: '에너지 관리 솔루션',
                desc: [
                    {
                        title: '스마트 그리드 운영',
                        desc: '전력 공급 네트워크를 디지털트윈으로 모델링하여 에너지의 흐름을 최적화하고, 장애 감지 및 예방을 통해 전력 시스템을 효율적으로 운영합니다.',
                    },
                    {
                        title: '',
                        desc: '',
                    }
                ]
            },
            {
                icon: IconHeart,
                title: '헬스케어 디지털트윈',
                desc: [
                    {
                        title: '환자 모니터링 및 진단',
                        desc: '환자의 건강 상태를 디지털트윈으로 표현하여 의료진이 실시간으로 모니터링하고, 진단에 도움을 주는데 활용됩니다.',
                    },
                    {
                        title: '의료 기기 개발',
                        desc: '의료 기기의 개발 단계에서 디지털트윈을 사용하여 시뮬레이션을 수행하고 기기의 성능을 향상시킵니다.',
                    }
                ]
            },
            {
                icon: IconCar,
                title: '자동차 및 운송 디지털트윈',
                desc: [
                    {
                        title: '자동차 디자인 및 테스트',
                        desc: '자동차의 개발 단계에서 디지털트윈을 사용하여 자동차의 성능을 향상시키고, 시뮬레이션을 통해 안전성을 검증합니다.',
                    },
                    {
                        title: '물류 및 운송 최적화',
                        desc: '물류 네트워크를 디지털트윈으로 모델링하여 물류 및 운송 과정을 최적화하고 비용을 절감합니다.',
                    }
                ]
            },
            {
                icon: TechUseCaseIconBuilding,
                title: '스마트 빌딩 디지털트윈',
                desc: [
                    {
                        title: '스마트 미터링 시스템',
                        desc: '전기, 가스, 수도 등의 유틸리티 네트워크를 디지털트윈으로 모델링하여 실시간으로 모니터링하고 소비자들에게 에너지 사용 정보를 제공합니다.',
                    },
                    {
                        title: '환경 모니터링',
                        desc: '환경의 상태를 디지털트윈으로 표현하여 대기질, 수질, 기후 등의 환경 요소를 지속적으로 감시하고, 환경 관리 결정에 활용됩니다.',
                    }
                ]
            },
        ]
    },

    advantage: {
        title: '디지털트윈 주요 특징',
        cards: [
            {
                icon: IconRulerInv,
                title: '실시간 모니터링 및 대응능력',
                desc: '디지털트윈은 물리적인 대상의 상태를 실시간으로 모니터링할 수 있어 문제가 발생하거나 예측할 수 있을 때 빠르게 대응할 수 있습니다. 이는 생산 라인, 설비, 건물 등 다양한 대상에서 중요한 역할을 합니다. 빠른 대응 덕분에 시스템의 안전성을 향상시키고 잠재적인 문제를 최소화할 수 있습니다.',
            },
            {
                icon: IconTagInv,
                title: '시뮬레이션 및 최적화 기능',
                desc: '디지털트윈은 가상 모델을 활용하여 다양한 시나리오를 시뮬레이션하고 최적의 운영 방식을 찾을 수 있습니다. 이를 통해 기업은 생산 프로세스, 제품 디자인, 에너지 사용 등 다양한 측면에서 효율성을 극대화할 수 있습니다. 또한, 변경된 환경에서의 시뮬레이션을 통해 사전에 의사 결정을 내릴 수 있어 리스크를 감소시킬 수 있습니다.',
            },
            {
                icon: IconChartInv,
                title: '유지보수 비용 감소 및 수명 주기 관리',
                desc: '디지털트윈을 활용하면 물리적인 대상의 상태를 정확하게 모니터링하고 예측 분석을 통해 유지보수 일정을 최적화할 수 있습니다. 불필요한 유지보수를 방지하고, 기계 또는 설비의 고장을 사전에 예측하여 수리나 교체 작업을 효율적으로 수행할 수 있습니다. 이는 전체적인 유지보수 비용을 감소시키고 자산의 수명을 연장하는 데 도움이 됩니다.',
            },
        ]

    }


}