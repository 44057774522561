import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import React, { useEffect, useState } from "react";
import {
  BoxLayout, BtnBox, BtnCancel,
  InputBox,
  InputLayout50,
  MemoInputDiv,
  Placeholder,
  PlaceholderTextArea,
  PricingPage,
} from "./Pricing.style";
import { StyledDisplay, StyledLabel, StyledParagraph } from "@components/Text";
import { Trans } from "react-i18next";
import pricing from "@src/lang/ko/pricing/pricing";
import { Icon3dCheck } from "@assets/images/icon";
import { useLocation, useNavigate } from "react-router-dom";

import seo from '@src/lang/ko/seo';
import seoEN from '@src/lang/en/seo';
import seoJP from '@src/lang/jp/seo';
import seoCN from '@src/lang/cn/seo';
import SEO from '@pages/SEO';
import i18n from "@pages/i18n";
import emailjs from 'emailjs-com';
import axios from "axios";
import { ImgWH, LabelColor, Spacing } from "@pages/CommonStyle/common.style";
import { DivFlexColumn } from "@pages/CommonStyle/commonDiv.style";

const Pricing5 = (): React.ReactElement => {

  const [ipAddress, setIp] = useState();

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        setIp(res.data.state)
      })
  }, [])

  let placeholder;
  let alertMsg;
  let emptyMsg;
  let holder1;
  let holder2;
  let holder3;
  let successMsg;

  const location = useLocation();
  const currentDateTime = new Date();
  if (location.pathname.startsWith('/en/')) {
    placeholder = 'Please enter an email'
    alertMsg = 'Email format is invalid'
    emptyMsg = 'There are empty values'
    holder1 = 'John Doe'
    holder2 = '123-456-7890'
    holder3 = 'Please enter information.'
    successMsg = `Thank you for your inquiry. We'll get back to you as soon as possible.`

  } else if (location.pathname.startsWith('/jp/')) {
    placeholder = 'メールを入力してください'
    alertMsg = 'メール形式が正しくありません'
    emptyMsg = '空の値があります'
    holder1 = '山田太郎'
    holder2 = '090-1234-5678'
    holder3 = '情報を入力してください。'
    successMsg = 'お問い合わせありがとうございます。 早いうちにご連絡いたします。'

  } else if (location.pathname.includes('/cn/')) {
    placeholder = "请输入电子邮箱。"
    alertMsg = "电子邮箱格式不正确"
    emptyMsg = "存在空值"
    holder1 = '张三'
    holder2 = '138-1234-5678'
    holder3 = '请输入信息。'
    successMsg = '谢谢，我会尽快联系您的。'

  } else {
    placeholder = '이메일을 입력해 주세요.'
    alertMsg = '이메일 형식이 올바르지 않습니다'
    emptyMsg = '비어있는 값이 있습니다'
    holder1 = '홍길동'
    holder2 = '010-1234-5678'
    holder3 = '정보를 입력해주세요.'
    successMsg = '문의 주셔서 감사합니다. 빠른 시일 내로 연락드리겠습니다.'
  }

  const selectedIndexes = location.state && location.state.selectedIndexes ? location.state.selectedIndexes : [];
  const selectedProducts = location.state && location.state.selectedProducts ? location.state.selectedProducts : [];
  const email = location.state && location.state.email ? location.state.email : '';
  const selectedOptions = location.state && location.state.selectedOptions ? location.state.selectedOptions : [];
  const personalID = location.state && location.state.personalID ? location.state.personalID : '-';
  const schedule = location.state && location.state.schedule ? location.state.schedule : '-';

  for (let i = 0; i < selectedProducts.length; i++) {
    //alert(pricing.step2.product[selectedIndexes[i]].title);
  }

  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [memo, setMemo] = useState<string>('');

  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }


  const navigate = useNavigate();
  const handlePrevStep = () => {
    if (email.includes('tech42')) {
      onClickEmailSpam();
      return;
    }
    sendEmail();
    notionSubmit(personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule);
    alert(successMsg);
    if (currentLang === 'ko') { navigate('/', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: false } }); }
    else { navigate(`/${currentLang}/`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: false } }); }
  }

  const handleNextStep = () => {
    if (email.includes('tech42')) {
      onClickEmailSpam();
      return;
    }
    if (name === '' || phone === '') {
      alert(emptyMsg)
      return;
    } else if (selectedProducts.length > 0) {
      notionSubmit(personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule);
      sendEmail();
      if (currentLang === 'ko') { navigate('/pricing/6', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: true } }); }
      else { navigate(`/${currentLang}/pricing/6`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: true } }); }
    }
  }

  const sendEmail = () => {

    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      selectedProducts: convertString2(selectedProducts),
      name: name,
      email: email,
      phone: phone,
      selectedOptions: convertString3(selectedProducts, selectedOptions),
      memo: memo,
      created_time: new Date().toLocaleString()
    }

    // 이메일 보내기
    emailjs.send('service_7yv5b7d', "template_w5y3mmq", mailForm)
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  const onClickEmailSpam = () => {
    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      email: email,
    }

    emailjs.send('service_7yv5b7d', 'template_zt2sf0o', mailForm)
      .then((response) => {
        console.log('이메일 보내기 성공:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });
  }

  const notionSubmit = async (personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule) => {
    try {
      const response = await fetch("https://orbro.io/pricingNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          personalID: personalID,
          industries: convertString(selectedIndexes),
          products: convertString2(selectedProducts),
          demand: convertString3(selectedProducts, selectedOptions),
          email: email,
          name: name,
          phone: phone,
          memo: memo,
          schedule: schedule,
          ipAddress: ipAddress,
          type: '견적 문의',
          currentTime: currentDateTime.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // 24시간 형식
            timeZone: 'Asia/Seoul', // 한국 시간대
          })
        })
      });

    } catch (error) {
      console.log('error', error);
    }
  }

  const convertString = (selectedIndexes) => {
    const selectedIndustries = selectedIndexes.map(index => {
      return pricing.step1.industry[index].title;
    });
    return selectedIndustries.join(', ');
  }

  const convertString2 = (selectedProducts) => {
    const selectedProduct = selectedProducts.map(index => {
      return pricing.step2.product[index].title;
    });
    return selectedProduct.join(', ');
  }


  const convertString3 = (selectedProducts, selectedOptions) => {
    let combinedText = ''; // 문자열을 저장할 변수 초기화
    try {
      selectedProducts.forEach((i) => {
        selectedOptions[i].forEach((jData, j) => {
          jData.forEach((value, k) => {
            if (
              value === 1 &&
              j < pricing.step3.question[i].parts.length &&
              k < pricing.step3.question[i].parts[j].option.length
            ) {
              // 문자열을 추가
              combinedText += pricing.step3.question[i].parts[j].option[k].text + ' ';
              if (i !== selectedProducts.length - 1) {
                // i 값이 마지막이 아니면 쉼표를 추가
                combinedText += ', ';
              }
            }
          });
        });
      });
      return combinedText; // 결합된 문자열 반환
    }
    catch {
      return "제품 선택 없음"; // 결합된 문자열 반환
    }

  };

  let seoPath;

  if (location.pathname.includes('/en/')) {
    seoPath = seoEN;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
  }
  const helmetTitle = seoPath.pricing.title;
  const ogKey = seoPath.pricing.key;
  const ogDesc = seoPath.pricing.desc;
  const ogImg = seoPath.pricing.img;
  const ogUrl = seoPath.pricing.url;
  const currentLang = i18n.language;
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "pr@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }

  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />
      <Spacing pixel="64" />
      <StyledGridContainer>

        <StyledGridRow>
          <StyledGridColumn desktop={2} tablet={1} mobile={0}>

          </StyledGridColumn>
          <StyledGridColumn desktop={8} tablet={6} mobile={4}>

            <PricingPage>
              {/** 이미지 **/}
              <BoxLayout>
                <ImgWH src={Icon3dCheck} w={"80"} h={"80"} alt="check icon" />
              </BoxLayout>
              <Spacing pixel="32" />

              {/** 타이틀 **/}
              <BoxLayout>
                <StyledDisplay size={"small"}>
                  <Trans i18nKey={`pricing.step5.title`} />
                </StyledDisplay>
              </BoxLayout>
              <Spacing pixel="20" />
              <BoxLayout>
                <StyledParagraph size="medium">
                  <Trans i18nKey={`pricing.step5.desc`} />
                </StyledParagraph>
              </BoxLayout>

              <Spacing pixel="48" />
              <BoxLayout>

                <InputLayout50>
                  <StyledLabel size="small">
                    <Trans i18nKey={`pricing.step5.name`} />
                  </StyledLabel>
                  <Spacing pixel="8" />
                  <InputBox>
                    <Placeholder
                      i={name.length}
                      value={name}
                      placeholder={holder1}
                      onInput={(e) => { handleInput(e, setName) }}
                    />
                  </InputBox>

                </InputLayout50>
                <InputLayout50>
                  <StyledLabel size="small">
                    <Trans i18nKey={`pricing.step5.phone`} />
                  </StyledLabel>
                  <Spacing pixel="8" />
                  <InputBox>
                    <Placeholder
                      i={name.length}
                      value={phone}
                      placeholder={holder2}
                      onInput={(e) => { handleInput(e, setPhone) }}
                    />
                  </InputBox>

                </InputLayout50>
              </BoxLayout>

              <Spacing pixel="24" />
              <BoxLayout>

                <DivFlexColumn>
                  <StyledLabel size="small">
                    <Trans i18nKey={`pricing.step5.memo`} />
                  </StyledLabel>
                  <MemoInputDiv>
                    <PlaceholderTextArea
                      i={memo.length}
                      value={memo}
                      placeholder={holder3}
                      onInput={(e) => { handleInput(e, setMemo) }}
                    />
                  </MemoInputDiv>
                </DivFlexColumn>
              </BoxLayout>

              {/** 다음 버튼 **/}
              <Spacing pixel="64" />
              <BoxLayout >
                <BtnCancel onClick={() => handlePrevStep()}>
                  <StyledLabel size="large">
                    <Trans i18nKey={`pricing.step5.prev`} />
                  </StyledLabel>
                </BtnCancel>
                <BtnBox isOk={selectedProducts.length > 0} onClick={() => handleNextStep()}>
                  <LabelColor size="large" color={"#FFF"}>
                    <Trans i18nKey={`pricing.step5.next`} />
                  </LabelColor>
                </BtnBox>
              </BoxLayout>
              <Spacing pixel="64" />

            </PricingPage>
          </StyledGridColumn>

        </StyledGridRow>
      </StyledGridContainer>

    </>
  )
};

export default Pricing5;


//
//<Product/>