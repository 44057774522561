import { logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import digitaltwin from "@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4";

export default {


    helmet: '| 컨테이너 추적 (Container Tracking)',
    desc: '컨테이너 추적 솔루션은 RFID, GPS 또는 RTLS와 같은 기술을 사용하여 컨테이너의 위치와 상태를 실시간으로 추적하고 모니터링합니다. 이를 통해 물류 및 해운 업계에서 컨테이너의 운송 및 보관 과정을 최적화하고 효율성을 향상시키는데 도움이 됩니다.',
    keywords: '항만, 항구, 컨테이너, 컨테이너 추적, 화물 추적, 항만 관리, 디지털트윈, 위치추적, 실시간 위치추적, rtls, orbro, 오브로, 콩테크',

    ogSite: '컨테이너 추적 | 디지털트윈 전문 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/container-tracking',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkZ9sm3FiFSvojymITtsaWizbn8oKQcMwa4MM-G4G-nMKWBXq2F_q-sH0ACGnraTS707A9X3ogEPQhu0wxDNF0RP9I7pkAp7v0fdzFU2A7zFZtXef3IJ8c3TnYnagQc060ySKipQrcBuqB5tFq82ljVxI4ocqiownoure2nWdl7EzNUSFEQTe4NwflqjUms4AQTYBfwiwLp_4T-VPmoBsby9wKS0Lg20NkhGMM5-AKMQmD08MhEjPcjrWnYRjuhhHDJGB7yjVjJJFaC_8Hhzk-ZRhfMFoN0Werqf5YPXyy9n3DKLQuJv4nD4zYfYswWZj57am6eEuIWYdT6NU5VcdfGPuikrcaFNLVqonZVSVokqkKDjLIfFOLZ_jEHpWaiUpzJ4N2AwMlHjbypjZYkkcWPFuZRiOBOSnzAHvamvwRM9-ag0utSPD9dB9kqXM0gG9iIDVJJfeHxii387jBr7qtgEhEzpInbsnTxby1QQ4qGR91AptBAs-efDpG5dpw5aLNanaEtUnjmHLb2Kdx2BptiikLaz60I7qbKj7yt-eOYq030IZRzypf44xRyI-iAv0WxNE_El-NzX1Xl3T7aRWL_uiBNv-RML1K9gcbC5oEs2G9jGWd7uCAO4XzOtfu7tMNk8ENa9Tv6VxD9AqTdSu2HCUXxnqni5WajMTCT0r-C9pXr0DY7boiz-aBXmITz77v3-VtVXpMpHAiJnjaaQQWtsxCsp3JO643BiMaXQ69jdqcWU_tSVlUMBjVx3MwtdBkfpkH1UlOqrYL8lSfAryg4rOuYRlq0Wgd_HYQaWjg6ASY6GsYPQ_3jsk1c16AxmcmFKaYOvB95Una7UyNMZ2E1L0J2E9UDw5_NT0chrXIjUSCfCNoO3_z3t7PNeoNWyGhS5fkcxZ4iZEMJoSHPjO7_Ycj8fGf_TH8iAFx5kzutepCAa0OU4wVEZMJ7w26XLzJjNJY6Sv4e8ZtuB94rz3bII-npzvEPbPLe3-5_ZvinvmuEyUx8MBkei6-GyebkWDRO9A8GSt5cXN5ysTiKCLCgDz-2g_ZnlRafFzaYJM8zCrHpbL2doyBDLbLTr0XLnk_c4aKGCAMlWxt3na5UYPiexaCSHUgbRzHuZQDUNe6QzEVzuZ-ISeaiBOcDzj25ejL36EoeLeVduxzuvIs8XFa8rIO6sQOK0zSwNcAyeohI0waUzbyHJgEhNXe-vz0Ilc_AChIthH4taUvqT5x62hnV41jq-07G--rjyOeS2pG6PdQMkRqfteroupWK_rO_COOQCb_vscco04P8Jla0Lm7NfxsHyo6chs1rki0f_bjkv1NpW2QgqoX0-igIPd6ByzZOXoWVLlNXWkPkTOQKC3gdh8NRAep9vGbGAvQfRfyKFO5adEA0J-MyTdJPuq8gCH3oPCC3CLntoNARciwS31xLFRD-bF3x5iAb8rcK1VMSj4FKB6aTbMmF4J8ENEDRZ2axEdqIuW7mxt1PQl5aRzqvwPeG0rphIczuLwknwPZ-acGokkMnL7bY9ak1NZdEq4H0LeggE-5ryhwpPzWBQofP7HvD5zQx=w2010-h1214',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '컨테이너 추적 (Container Tracking)',
    date: '2024-01-09',
    tag1: '#컨테이너추적',
    tag2: '#화물추적',
    tag3: '#물류추적',
    tag4: '#스마트물류',

    // <br/>
    text: [
        {
            head: '컨테이너 추적 (Container Tracking)',
            subHead: '',
            desc: '컨테이너는 세계 각지에서 이동되는 상품을 안전하게 운반하는 핵심 요소입니다. 그러나 컨테이너의 위치와 상태를 정확하게 파악하는 것은 여전히 고난이도의 과제로 남아 있습니다. 게다가 요즘처럼 불안한 세계 정세는, 관련 산업에 불안정성과 불확실성을 더해가고 있습니다. <br/>컨테이너 추적 솔루션은 RFID, GPS 또는 RTLS와 같은 기술을 사용하여 컨테이너의 위치와 상태를 실시간으로 추적하고 모니터링합니다. 이를 통해 물류 및 해운 업계에서 컨테이너의 운송 및 보관 과정을 최적화하고 효율성을 향상시키는데 도움이 됩니다.',
            dot: ''
        },
        {
            head: '컨테이너 추적 방법',
            subHead: 'RTLS 기술의 적용',
            desc: 'RTLS란, Real-Tiem Location System의 줄임말로 실시간 위치 추적을 의미합니다. RTLS 기술은 다양한 환경에서 컨테이너의 정확한 위치를 파악하는 데 탁월한 성능을 발휘합니다. GPS는 실외에서의 위치 파악에 뛰어나지만, 실내 환경이나 복잡한 도시 지역에서는 정확도가 떨어지는 문제가 있습니다. RTLS는 GPS의 한계를 극복하며, 센서 네트워크, RFID, 초음파 및 무선 통신 기술을 적절히 결합하여 컨테이너의 위치를 건물 내부에서도 정확하게 추적할 수 있습니다.<br/><br/>또한 RTLS 기술은 기존 물류 인프라와의 원활한 통합이 가능합니다. 기존의 창고 자동화 시스템, 운송 관리 소프트웨어, 및 주문 처리 시스템과 연동하여 실시간으로 컨테이너의 위치 정보를 공유하고, 자동화된 프로세스에 신속하게 반영할 수 있습니다. 이는 물류 및 운송 관리의 효율성을 높이고, 인력 및 시간을 절약하는 데 도움이 됩니다.',
            dot: ''
        },
        {
            video: digitaltwin,
            link: 'https://orbro.io/enterprise/logistics/asset',
            videoDesc: '(오브로의 물류 센터 디지털트윈)',
            head: '컨테이너의 보안 강화',
            subHead: '도난 및 손상 예방',
            desc: 'RTLS를 활용한 보안 강화는 컨테이너의 위치를 실시간으로 파악하여 도난 및 손상을 예방하는 데 기여합니다. 특히 교통 중인 컨테이너의 위치를 정확히 파악함으로써 도난 위험을 최소화할 수 있습니다. 또한, 특정 지역에서 예상치 못한 움직임이 감지될 경우 신속한 대응이 가능하여 보안 수준을 향상시킵니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '유휴 시간 보안',
            desc: '컨테이너가 유휴 상태에서 위치가 계속 모니터링되면, 보안 위협에 대한 대비가 가능해집니다. 유휴 시간 동안의 보안 강화는 물류 시스템이 비활성화되는 동안에도 안전을 유지할 수 있도록 합니다. 이는 컨테이너의 보안을 강화하고 물류 네트워크 전반에 걸친 안전성을 향상시킵니다.',
            dot: ''
        },
        {
            head: '실시간 컨테이너 추적의 장점',
            subHead: '물류 프로세스 최적화',
            desc: 'RTLS를 통한 실시간 컨테이너 추적은 물류 프로세스를 최적화하는 데 중요한 역할을 합니다. 실시간으로 컨테이너의 위치를 파악함으로써, 물류 관리자들은 각 단계에서의 효율성을 높일 수 있습니다. 출발지, 중간 경유지, 도착지에서의 물류 경로를 실시간으로 조절하여 운송 시간을 단축하고 비용을 최소화할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '예측 분석과 예외 처리',
            desc: '실시간 정보 제공은 예측 분석에 기반한 전략 수립과 예외 처리에도 도움이 됩니다. 데이터를 분석하여 이동 경로에서 발생할 수 있는 문제를 사전에 예측하고, 물류 네트워크의 효율성을 극대화하기 위한 개선점을 찾을 수 있습니다. 또한, 실시간으로 상황을 감지하고 예외 상황에 대한 빠른 대응을 가능케하여 문제 발생 시간을 최소화합니다.',
            dot: ''
        },
        {
            head: '효율적인 자원 관리',
            subHead: '운송 경로 최적화',
            desc: 'RTLS를 활용한 효율적인 자원 관리는 운송 경로 최적화를 통해 구현됩니다. 컨테이너의 실시간 위치 정보를 활용하여 특정 지역에서 자주 사용되는 컨테이너를 식별하고, 이를 이용하여 최적의 운송 경로를 계획합니다. 이는 연료 소비를 줄이고 운송 비용을 절감할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '물류 자산 최적 배치',
            desc: 'RTLS는 창고 내에서의 물류 자산 관리를 최적화하는 데도 활용됩니다. 센서 기술을 이용하여 창고 내의 물류 자산들을 실시간으로 추적하고, 특정 상품이나 장비가 필요한 지역에 효율적으로 배치할 수 있습니다. 이는 물류 공간을 최대한 활용하며 작업 효율을 높입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '비용 절감 및 자원 최적화',
            desc: 'RTLS를 통한 실시간 컨테이너 추적은 운송 및 물류 관리에서 비용을 효과적으로 절감하고 자원을 최적화하는데 도움을 줍니다. 정확한 위치 정보를 토대로 운송 경로를 최적화하고, 물류 자산을 효율적으로 배치함으로써 연료 비용을 감소시키고 유휴 자원을 최소화할 수 있습니다.',
            dot: ''
        },
        {
            head: '컨테이너 추적의 미래 전망',
            subHead: 'AI 및 머신 러닝의 통합',
            desc: '앞으로는 인공지능(AI) 및 머신 러닝과의 통합이 더욱 강화될 것으로 예상됩니다. RTLS 기술은 수많은 데이터를 생성하고, AI를 활용하여 이 데이터를 분석하면서 예측 모델을 구축할 수 있습니다. 이를 통해 예상치 못한 문제를 사전에 예측하고 물류 프로세스를 더욱 미세하게 최적화할 수 있게 될 것입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '생태계 확장',
            desc: '컨테이너 추적은 현재 물류 산업뿐만 아니라 다양한 산업에서도 활용될 것으로 예상됩니다. 제조, 의료, 소매 및 에너지 산업 등에서도 RTLS를 통한 컨테이너 추적이 더욱 보편화될 것입니다. 이러한 확장은 물류 생태계를 더욱 통합하고, 협업의 기회를 확장시킬 것으로 기대됩니다.<br/><br/>컨테이너 추적 기술은 지속적인 혁신을 통해 산업 전반에 긍정적인 영향을 미칠 것으로 기대되며, 이는 물류 및 공급망 관리에서 효율성과 안정성을 향상시키는 데 주요한 역할을 할 것입니다.',
            dot: ''
        },
        {
            head: '결론',
            subHead: '',
            desc: '실시간 정보를 기반으로 한 예측 분석과 예외 처리는 물류 관리자들이 빠르게 대응하고 물류 프로세스를 지속적으로 개선할 수 있는 기회를 제공합니다. 또한, 운송 경로 최적화와 물류 자산 최적 배치는 운송 비용을 절감하고 물류 네트워크의 효율성을 높이는 데 기여합니다.<br/><br/>지속적인 기술 혁신과 RTLS 기술의 활용 확대는 미래의 물류 산업을 선도하며, 효율성을 향상시키고 경쟁력을 강화하는 데 큰 기여를 할 것으로 기대됩니다. 더 나아가, 다양한 산업 분야에서 RTLS의 적용이 더욱 확대됨에 따라 더욱 혁신적이고 지속 가능한 물류 및 공급망 시스템이 발전할 것으로 기대됩니다.',
            dot: ''
        },

    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            }
        ]

    }


}