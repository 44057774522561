import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {
  helmet: '',
  desc: 'Enhance traffic management and response by real-time monitoring of urban traffic conditions. Effectively manage the transportation system through the integration of traffic information CCTV, inclusion of video information in objects, large-scale video information integration, accident analysis and countermeasure development, monitoring compliance with traffic regulations, and the formulation of efficient traffic management strategies.',
  keywords: 'Digital Twin, Digital Twins, 디지털트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - Traffic CCTV Connection :: ORBRO Blog',
  ogUrl: 'https://orbro.io/en/blog/digitaltwin-cctv',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - Traffic CCTV Connection',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#CCTV',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  text: [
    {
      head: 'Digital Twin - Traffic CCTV Connection',
      subHead: '',
      desc: 'ORBRO provides a service that monitors traffic conditions in real-time, enhancing traffic management and response. Through the integration of traffic information from CCTV, inclusion of visual information in objects, comprehensive integration of large-scale visual information, accident analysis and countermeasure development, monitoring compliance with traffic regulations, and the establishment of efficient traffic management strategies, it effectively manages the traffic system.<br/><br/>To achieve this, CCTV integration observes traffic flow in real-time, and including visual information in objects strengthens traffic management and safety. Comprehensive integration of large-scale visual information accurately assesses traffic conditions in extensive areas, while accident analysis and countermeasure development enhance road safety. Monitoring compliance with traffic regulations ensures traffic safety, and efficient traffic management strategies reduce traffic congestion. Through these measures, ORBRO effectively manages road and traffic environments, maintaining a safe and efficient traffic system.',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/iOZVM1YT_pE?si=MkzatuD-2E4xbzkQ',
      head: '',
      subHead: 'Integration of Traffic Information CCTV',
      desc: 'ORBRO integrates CCTV footage in real-time to monitor road and traffic conditions. This allows traffic managers to observe traffic flow in real-time and respond promptly to situations such as accidents or congestion.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Inclusion of Video Information in Objects',
      desc: 'Identifying objects such as vehicles and pedestrians in CCTV footage and providing additional information contribute to enhancing traffic management and safety. This plays a crucial role in preventing and investigating traffic accidents.',
    },
    {
      head: '',
      subHead: 'Large-Scale Video Information Integration',
      desc: 'Integrating video information from multiple CCTV sources allows for a comprehensive understanding of traffic conditions in wide-ranging areas. This provides essential data for urban traffic management and planning.',
    },
    {
      head: '',
      subHead: 'Accident Analysis and Countermeasure Development',
      desc: 'Utilizing CCTV data to analyze the causes of traffic accidents and developing countermeasures to reduce incidents. This improves road safety and decreases the incidence of accidents.',
    },
    {
      head: '',
      subHead: 'Monitoring Compliance with Traffic Regulations',
      desc: 'Monitoring compliance with traffic regulations, identifying violators, and promoting adherence to rules. This ensures traffic safety and encourages a culture of compliance.',
    },
    {
      head: '',
      subHead: 'Formulation of Efficient Traffic Management Strategies',
      desc: 'Analyzing collected data to improve traffic flow and formulating efficient traffic management strategies. This reduces traffic congestion and enhances the convenience of citizens\' mobility.',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that aids real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]
  }
}
