import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/retailExhibition/video/rtlsVideo.mp4';

export default {

    desc: 'AIoT(인공지능 사물인터넷)는 인공지능(AI)과 사물인터넷(IoT)을 결합한 개념으로, 기계학습, 데이터 분석, 인지 기술 등의 인공지능 기술을 활용하여 사물인터넷 기기들이 스마트하게 상호작용하고 서로 학습하며 지능을 갖추는 것을 의미합니다.',
    keywords: 'AIoT, IoT, AI Camera, 인공지능 카메라, 디지털트윈, 디지털 전환, digital twin, orbro, beacon, rtls, uwb, ble, ai camera, 오브로, 콩테크',

    ogSite: 'AIoT란? | 정의, 기술, 사례, 장점',
    ogUrl: 'https://orbro.io/blog/what-is-aiot',
    ogImg: 'https://lh3.google.com/u/0/d/1beXwJR6Bc3ap2Kb_H7OaW4vPVlq3ovWM=w2000-h7622-iv1',
    ogType: 'website',

    title: 'AIoT란? | 정의, 기술, 사례, 장점',
    date: '2024-05-09',
    tag1: '#AIoT',
    tag2: '#IoT',
    tag3: '#AI',
    tag4: '#인공지능융합기술',

    // <br/>
    text: [
        {
            head: 'AIoT란?',
            subHead: '',
            desc: 'AIoT(인공지능 사물인터넷)는 인공지능(AI)과 사물인터넷(IoT)을 결합한 개념입니다. 이는 기계학습, 데이터 분석, 인지 기술 등의 AI 기술을 활용하여 사물인터넷 기기들이 스마트하게 상호작용하고 서로 학습하며 지능을 갖추는 것을 의미합니다. AIoT는 기존 IoT 기술에 AI 기술을 접목하여 더욱 효율적인 자동화와 데이터 기반의 의사 결정을 가능케 합니다. 이를 통해 생산성 향상과 편리한 서비스 제공이 가능해집니다.',
            dot: ''
        },
        {
            head: 'AIoT 대표 기술',
            subHead: '인공지능(AI)',
            desc: 'AI 기술은 기계학습, 딥러닝, 자연어 처리 등을 포함합니다. 이러한 기술을 활용하여 데이터를 분석하고 패턴을 인식하며, 사물인터넷 기기가 스마트하게 상호작용할 수 있도록 합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '사물인터넷(IoT)',
            desc: 'IoT는 다양한 사물이 네트워크에 연결되어 데이터를 주고받을 수 있는 기술을 의미합니다. 센서, 장비, 가전제품 등의 사물이 인터넷에 연결되어 상호작용하고 데이터를 수집, 전송, 처리합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '센서와 액추에이터',
            desc: '센서는 주변 환경에서 데이터를 수집하고 액추에이터는 외부 환경에 영향을 주는 기기로, 이들은 IoT 기기의 중요한 구성 요소입니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '클라우드 컴퓨팅',
            desc: 'AIoT에서는 대규모의 데이터 처리와 분석을 위해 클라우드 서비스가 활용됩니다. 클라우드 기반의 서버는 데이터를 저장하고 처리하여 사용자에게 의사 결정을 제공합니다.',
            dot: ''
        },
        {
            video: video,
            link: '/enterprise/retail/exhibition',
            head: 'AIoT 사용 사례',
            subHead: '스마트 홈',
            desc: 'AI 기술을 활용하여 주택 내의 다양한 기기들을 연결하여 편리한 생활 환경을 제공합니다. 예를 들어, 스마트 홈 허브는 가정 내의 조명, 난방, 보안 시스템 등을 제어하고, 사용자의 습관을 학습하여 최적의 에너지 소비 방법을 제시할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '스마트 건강 관리',
            desc: '착용형 기기들과 센서를 활용하여 개인의 건강 상태를 모니터링하고, 이를 분석하여 개인 맞춤형 건강 관리 서비스를 제공합니다. 이는 심박수, 수면 패턴, 활동량 등의 데이터를 수집하고, AI 알고리즘을 통해 건강 상태를 예측하거나 질병을 조기에 발견할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '스마트 농업',
            desc: 'IoT 센서를 활용하여 농작물의 토양 수분, 온도, PH 등의 정보를 수집하고, 이를 분석하여 농작물의 상태를 모니터링하고 작물 재배 과정을 최적화합니다. 농부들은 스마트폰 앱을 통해 실시간으로 농작물의 상태를 확인하고 필요한 조치를 취할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '스마트 도시',
            desc: '도시 내의 다양한 시설과 인프라를 연결하여 도시의 효율성을 높이고 시민들의 편의를 증진합니다. 예를 들어, 스마트 조명은 주변 환경을 감지하여 조명을 조절하고, 스마트 교통 시스템은 실시간으로 교통 상황을 모니터링하여 교통 체증을 예방하거나 최적의 경로를 제시합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '스마트 제조',
            desc: '제조업에서는 IoT 센서와 로봇 등을 활용하여 생산 라인을 자동화하고 생산 과정을 모니터링합니다. AI 알고리즘은 생산 데이터를 분석하여 생산량을 최적화하고 불량품을 사전에 예측하여 생산 효율성을 높입니다.',
            dot: ''
        },
        {
            head: 'AIoT의 장점',
            subHead: '지능적인 자동화',
            desc: 'AIoT는 인공지능 기술을 활용하여 사물인터넷 기기들을 스마트하게 제어하고 관리할 수 있습니다. 이를 통해 작업의 자동화와 최적화가 가능해지며, 인간의 개입을 줄여 생산성을 향상시킵니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '데이터 기반의 의사 결정',
            desc: 'AIoT는 대규모의 데이터를 수집하고 분석하여 의사 결정을 내리는 데 활용됩니다. 이를 통해 실시간으로 상황을 파악하고 최적의 조치를 취할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '예측 분석과 유지보수',
            desc: 'AIoT는 센서 데이터와 AI 알고리즘을 활용하여 시스템의 이상 징후를 사전에 감지하고 예측 분석을 수행합니다. 이를 통해 장비의 고장을 예방하고 유지보수 일정을 최적화할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '개인화된 서비스 제공',
            desc: 'AIoT는 사용자의 데이터를 분석하여 개인화된 서비스를 제공할 수 있습니다. 예를 들어, 스마트 홈 시스템은 사용자의 습관과 선호도에 맞춰 조명, 난방 등을 제어하여 편리하고 안전한 환경을 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '에너지 효율성',
            desc: 'AIoT는 에너지 소비를 최적화하는 데 도움을 줍니다. 센서 데이터와 AI 알고리즘을 활용하여 에너지 사용 패턴을 분석하고 최적의 에너지 절약 방법을 제시함으로써 에너지 효율성을 향상시킵니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '시간과 비용 절감',
            desc: 'AIoT는 작업의 자동화와 최적화를 통해 인력과 시간을 절감할 수 있습니다. 또한, 예측 분석을 통해 장비의 고장을 사전에 예방함으로써 비용을 절감할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '',
            desc: 'AIoT 기술은 인공지능과 사물인터넷을 결합하여 지능적인 자동화, 데이터 기반의 의사 결정, 예측 분석, 개인화된 서비스 제공 등 다양한 장점을 제공합니다. 이를 통해 생산성 향상, 비용 절감, 에너지 효율성 향상 등을 이룰 수 있으며, 다양한 산업 및 생활 분야에서 혁신과 효율성을 실현할 수 있습니다.',
            dot: ''
        },
    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/enterprise/construction/equipment'
            }
        ]


    }


}