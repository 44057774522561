import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: '기상 모니터링과 첨단 시뮬레이션으로 기후 변화 영향을 이해하고 적응하는 데 필수적인 기후 분석을 제공합니다. 밤과 낮의 기상환경, 기후환경 및 강수량, 해양 기상 데이터, 전 세계 기상정보, 대기 오염 모니터링, 재난 예측 및 경고 시스템을 통해 광범위한 정보를 제공합니다.',
  keywords: 'Digital Twin, Digital Twins, 디지털트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: '디지털트윈 - 대기와 기상정보 구현 :: 오브로 블로그 ',
  ogUrl: 'https://orbro.io/blog/digitaltwin-weather',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '디지털트윈 - 대기와 기상정보 구현',
  date: '2023-12-26',
  tag1: '#디지털트윈',
  tag2: '#기상정보',
  tag3: '#디지털시티',
  tag4: '#가상현실',

  // <br/>
  text: [
    {
      head: '디지털트윈 - 대기와 기상정보 구현',
      subHead: '',
      desc: 'ORBRO는 디지털트윈을 활용하여 대기와 기상정보를 구현하여 기후 변화에 대한 이해와 적응을 지원합니다. 기상 모니터링과 첨단 시뮬레이션을 통해 기후 분석을 제공하며, 다양한 정보를 통해 광범위한 분야에 활용됩니다.<br/><br/>밤과 낮의 기상환경, 기후환경 및 강수량, 해양 기상 데이터, 전 세계 기상정보, 대기 오염 모니터링, 재난 예측 및 경고 시스템은 기후 연구, 농업 계획, 수자원 관리 등에 필수적인 정보를 제공합니다.<br/><br/>기상 위성 데이터, 일조량 및 태양 위치 정보를 활용하여 낮과 밤의 기상 환경을 실시간으로 시뮬레이션하고, 고해상도 기상 모델과 강수량 데이터를 사용하여 기후 조건을 시뮬레이션합니다. 해양에서의 강우, 전 세계 기상정보 통합, 대기 오염 모니터링, 재난 예측 및 경고 시스템은 해양학, 기후 연구, 공공 보건, 환경 보호에 중요한 역할을 합니다.',
      dot: ''

    },
    {
      youtube: 'https://www.youtube.com/embed/QB2sM1OdrXA?si=X3po5Cxz2Hu3QlGA',
      head: '',
      subHead: '밤과 낮의 기상환경 표현',
      desc: 'ORBRO는 기상 위성 데이터, 일조량 및 태양 위치 정보를 활용해 낮과 밤의 기상 환경을 실시간으로 시뮬레이션합니다. 이는 기후 연구, 재난 관리, 농업 계획, 에너지 관리에 중요한 데이터를 제공합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '기후환경 및 강수량 표현',
      desc: '고해상도 기상 모델과 강수량 데이터를 활용하여 다양한 기후 조건을 실시간으로 시뮬레이션합니다. 이는 기후 연구, 농업 계획, 수자원 관리에 필요한 중요한 데이터를 제공합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '해양에서의 강우 표현',
      desc: '위성 기반 해양 기상 데이터와 해양 모델을 사용하여 해양 지역에서의 강우 현상을 시각화합니다. 이 정보는 해양학, 기후 연구, 해양 교통 및 안전 관리에 필요한 중요한 정보를 제공합니다.',
    },
    {
      head: '',
      subHead: '전 세계 기상정보 통합',
      desc: '전 세계 기상 관측소 및 위성 데이터를 통합하여 광범위한 기상 정보를 수집하고 분석합니다. 이는 글로벌 기후 변화 모니터링 및 국제 재난 대응에 필수적인 역할을 합니다.',
    },
    {
      head: '',
      subHead: '대기 오염 모니터링',
      desc: 'ORBRO는 대기 질과 오염 수준을 추적하고 예측하여 공공 보건 및 환경 보호 정책을 지원합니다. 이는 대기 질 개선과 오염 방지에 중요한 정보를 제공합니다.',
    },
    {
      head: '',
      subHead: '재난 예측 및 경고 시스템',
      desc: '기상 데이터를 활용하여 자연 재난을 예측하고 관련 경고 시스템을 개발합니다. 이 시스템은 재난 대비 및 대응을 개선하고 자연 재난에 대한 신속한 대응을 가능하게 합니다.',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/enterprise/logistics/asset'
      }
    ]


  }

}