import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {


    helmet: '| LoRa란?',
    desc: 'LoRa(Low Range Radio)란, 저전력과 장거리 무선 통신을 위해 설계된 무선 통신 기술 중 하나입니다. 센서 네트워크, 산업 자동화, 특히 IoT 기기 간의 통신에 적합합니다.',
    keywords: 'LoRa, 무선통신, 로라, IoT, 통신기술, RTLS, 실시간 위치 추적, 오브로, ORBRO, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: 'LoRa란? | 위치추적과 디지털트윈 전문, 오브로',
    ogUrl: 'https://orbro.io/blog/what-is-LoRa',
    ogKey: '',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDkGw848hiaZwUj1NzOs30j6aqQAJyNCTeY0oQIeVJFOkeYuNI3kvZhnbc5uEfN7j4eLrviv8mEssXZn4lxH-dLhqXgRoLEtW86etie39T8Z4ZrsMxp-5A2G7W4VIP6BP_qFtyqDEJ_krIVKkxluM5vTPOKBMEyrGtQ_3ydm3DGKWHp7F-ShLBeXFtG1oET_EreJ_iYWOkT3CzAatE2_V77uE1qOcaLPuWCttx2d70Jt6u0a7tKI8nKCOheeIgrZqmaGNHLXtWSyJnPPkS083MmuPZv_AuP5JXZOe4h-xyzZcYCyo1E8vbbzRQZKjDcRj4CXwdfV6isxP_GMeoZXMvMMQR6ZL-CjdMHskmTwCTVRtYSQd-QRWtlRXn5bdxpcE5n2kgYENQIRBn73zqANbo6d7dbmHSgh3vqEzNP7yLk6mMQ-YNVO_04A1nNDlqhOaA9HQ7kkUtvDq1WsmkMAw2i169IEUISWz8pliXgKuRnPM_YOP8EWj0ewhPm6YtPrYizD5n9yo8sGI_B1E63PiHKz9hRNeRPSWKrkFPIlT-Y-adJ1giugNEpETW7dZOnr3u_FsbtzrPm6eOw5gQecQrBtDq14AN5olMMyI3_uGibjXoS3rutDYRvls1YlPMHv2iUBWhe9CmRSI9BsT-_tyscLg6cwWAhwfb9OdbWga3TI7dLo9b7s-8SFXm6s6Yqop9WgsB23-rOl_h4UAZHsjRu93uENXiNTD7YD-5VbvdSubD6Ffj3xkL3f9HdhilG3turAnuWLTcQPQ6f9yePOwuvoIrcmXntkKHwzoChR5WH4VoWk83o-UippkYQ3gzYCJJ8-4yd9JZg-TUA3-n9KHJvWyoaCjlaOTCkcBygN_c4WcWJgu3zui6gzBVZTv3oyuKzJzYYslQ89doq9Lh_T7yohz1vbGyDjOJ7gr-onVsc-yfw0_f3apHYvqfZKsSE_9DLZ6G9uzBBotKH-KPTnvEp3OA-2poPSP5Z-DsbLOymfPnvpMPs5ZsiCGh7Jvomyr5pxJcJL19u6HKf3979fiLresgN9QVAef0DmZTEYu76a10CjiW20Ne3C15KDO6Q2ICYOLpVyObPTZc6gGrvgED_Xr2Ul4We1Nc38VxTaLYoPMhzbg8SIe6AZ_UzExO4tZtDfX1hU-5kzIRBq2lXR1cKGXLvP8B0VUshx8iDhwWXOkUPuCyymV8Ng2j6JQyXZr0H2lyhO-b4WgS3aP5efeg_Ny64v_sUBQVG9wKL2FSxalXpQy5LHTrrbPV3akp2a7BeoMondBr7DVQnCTG8sc1oOJb-ZkAtOR05xmrGM2l4A0PlhelYg6RIyPfBvkGI5PV8lh3yPOUQVQCXBzqm_G6LYCRbqWdX5-3BlFEh4-fQpy6luoA1LossKR8VX0DXX8e9lcSJT17c2nXpV9w1mjyWuW8rYGBCeiiyulrgkobyIG_fSCd3TWmdr10IZU-_4msP81pWVBQ0iqdbEp4g-5cuufyYZ-hJo1dOOQGOsizdKKRg_-ndSZc_0nSCjjoEIoxoIl_wZN08371fZ_3FzYFTjFYkbcO9F=w1834-h1208',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: 'LoRa란?',
    date: '2024-01-15',
    tag1: '#LoRa',
    tag2: '#로라',
    tag3: '#통신기술',
    tag4: '#IoT',

    // <br/>
    text: [
        {
            head: 'LoRa란?',
            subHead: '',
            desc: 'LoRa(Low Range Radio)란, 저전력과 장거리 무선 통신을 위해 설계된 무선 통신 기술 중 하나입니다. 이 기술은 센서 네트워크, 스마트 시티, 농업 자동화, 산업 자동화 등 다양한 응용 분야에서 사용되며, 특히 IoT(Internet of Things) 기기 간의 통신에 적합합니다. 이 기술은 주파수 대역을 효과적으로 활용하여 수십 킬로미터에 이르는 통신 거리를 제공하며, 저전력 소비로 배터리 수명을 연장합니다. 또한, 비용 효율적이고 보안성을 갖춘 특징으로 다양한 응용 분야에서 활용되고 있습니다.',
            dot: ''
        },
        {
            head: 'LoRa 특징',
            subHead: '장거리 통신',
            desc: 'LoRa는 장거리 통신을 지원하여 수십 킬로미터에 이르는 통신 거리를 제공합니다. 이는 주로 농업 지역이나 원격 지역에서 센서 데이터를 수집하거나 모니터링할 때 유용하게 사용됩니다.',
            dot: ''
        },
        {
            head: "",
            subHead: "저전력 소비",
            desc: "LoRa는 저전력 통신을 특징으로 하며, 배터리로 구동되는 센서 기기와 같은 저전력 장치에서 오랜 기간 동안 동작할 수 있습니다. 이는 센서 네트워크의 노드들이 배터리 교체나 충전이 적게 필요하도록 합니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "주파수 대역 활용",
            desc: "LoRa는 주로 ISM(Industrial, Scientific, Medical) 대역인 868MHz(유럽), 915MHz(미국)를 사용합니다. 이 주파수 대역은 무료로 사용할 수 있고, 저전력 장치에 적합한 특성을 가지고 있습니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "저비용",
            desc: "LoRa는 비교적 저렴한 가격에 구현할 수 있습니다. 이로써 대량의 IoT 디바이스를 구축하는 데 있어서도 비용 효율적으로 활용될 수 있습니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "스타 토폴로지",
            desc: "LoRa 네트워크는 주로 스타 토폴로지를 사용하며, 각 디바이스가 게이트웨이에 직접 통신합니다. 이로써 네트워크의 확장이 용이하고, 각 디바이스 간의 통신이 빠르게 이루어집니다.",
            dot: ""
        },
        {
            head: '',
            subHead: '확장성과 유연성',
            desc: 'LoRa는 다양한 응용 분야에 유연하게 적용될 수 있습니다. 또한, 네트워크에 새로운 디바이스를 쉽게 추가하여 확장할 수 있는 특성을 가지고 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '암호화와 보안',
            desc: 'LoRa는 데이터 전송 중에 암호화를 제공하여 보안성을 강화합니다. 이는 중요한 센서 데이터의 안전한 전송을 보장합니다. 또한, LoRa 기술은 개방형 표준으로 개발되었기 때문에 여러 제조사들이 호환되는 디바이스를 제공할 수 있습니다.',
            dot: ''
        },
        {
            head: "LoRa 사용 사례",
            subHead: "농업 자동화",
            desc: "LoRa는 농업 분야에서 센서 네트워크를 구축하여 작물 상태, 토양 조건 등의 데이터를 실시간으로 수집하고 분석함으로써 농업 생산성을 향상시키는 데 활용됩니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "스마트 시티",
            desc: "LoRa는 스마트 시티에서 다양한 센서와 기기 간의 효율적인 통신을 제공하여 도시 인프라 관리, 환경 모니터링, 공공 안전 시스템 등에 적용되어 스마트 시티의 발전을 촉진합니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "산업 자동화",
            desc: "LoRa는 산업 자동화 분야에서 센서와 장비 간의 통신을 지원하여 생산 과정을 최적화하고 모니터링하는 데 사용됩니다. 이로써 생산 효율성이 향상되고 비용이 절감됩니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "환경 모니터링",
            desc: "LoRa는 자연 환경 모니터링에 적용되어 공기 품질, 수질, 기후 등의 데이터를 실시간으로 수집하여 환경 변화를 모니터링하고, 이에 대한 조치를 취하는 데 사용됩니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "지능형 건물 관리",
            desc: "LoRa는 건물 내 다양한 시스템과의 통신을 통해 에너지 사용, 보안 시스템, 냉난방 제어 등을 효율적으로 관리하는 데 활용되어 지능형 건물 구축에 기여합니다.",
            dot: ""
        },
        {
            head: "LoRa 기술의 미래 전망",
            subHead: "데이터 표준화와 규모 확장",
            desc: "LoRa의 데이터 표준화 노력과 규모 확장을 통해 다양한 제조사 및 서비스 제공 업체 간의 상호 운용성이 향상될 것으로 예측됩니다. 이는 LoRa를 활용한 생태계를 더욱 확장시키고 글로벌 IoT 시장에서의 입지를 강화할 것입니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "에지 컴퓨팅과의 결합",
            desc: "LoRa는 에지 컴퓨팅과 결합하여 실시간 데이터 처리와 응용 프로그램 실행을 지원함으로써 지능형 디바이스와 시스템에 대한 더 높은 수준의 자동화 및 지능화를 제공할 것으로 예측됩니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "보안 강화",
            desc: "LoRa는 보안에 대한 더 강력한 기능을 개발하여 디바이스 간의 통신 및 데이터 전송 중 보안을 강화할 것으로 기대됩니다. 이는 민감한 정보를 안전하게 전송하고 보호하는 데 기여할 것입니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "인공지능과의 통합",
            desc: "LoRa는 인공지능과 통합되어 데이터 분석과 의사 결정 프로세스를 강화함으로써 예측 분석, 자율 주행 기술 등 다양한 분야에서 더욱 혁신적인 솔루션을 제공할 것으로 전망됩니다.",
            dot: ""
        }
    ],

    link: {
        page: 'rtls',
        numb: '1',
        product: [
            {
                text: productPurchase.purchase.asset.assetTracking.title,
                title: productPurchase.purchase.asset.assetTracking.title,
                serial: productPurchase.purchase.asset.assetTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.assetTracking.key1
            },
            {

            }

        ],
        solution: [
            {
                img: logisticsAsset.thumb,
                data: 'logisticsAsset',
                link: '/enterprise/logistics/asset'
            },
            {
                img: logisticsSafety.thumb,
                data: 'logisticsSafety',
                link: '/enterprise/logistics/safety'
            },
            {
                img: manufacturingAsset.thumb,
                data: 'manufacturingAsset',
                link: '/enterprise/manufacturing/asset'
            }
        ]

    }


}