export default {
  banner: {
    download: 'Download',
    request: 'Get a Quote',
  },
  modal: {

    title: 'Currently preparing the data',
    desc: 'The data will be updated soon.<br/>We appreciate your patience.',

    download: {
      title: 'Download Data',
      desc: 'Please provide the information below, and we will send the data to your email. For any other inquiries, ',
      phone: 'pr@orbro.io',
      descTail: ' please contact us.',
      time: 'Weekdays (10:00 - 19:00) (KST)',
      ph_email: 'example@orbro.io',

      agree1: '[Required]',
      agree2: 'Privacy Policy',
      agree3: 'I agree to the ',


      confirm: 'Download',
      cancel: 'Cancel'
    },
    subscribe: {
      title: 'Sending Complete',
      desc: '<br/>We have sent the data to your email.<br/>Would you like to subscribe to Orbro\'s newsletter for technology and various information?',
      skip: 'Skip',
      confirm: 'Subscribe'
    },
    done: {
      title: 'Subscription Complete',
      desc: 'Thank you for subscribing to Orbro\'s newsletter. We will send you the latest news and information via email. Discover more information and knowledge with Orbro.',
      confirm: 'Confirm'
    }
  },

  request: {
    title: 'Contact Us',
    desc: `If you need our solution, please fill in the information below and click the 'Submit' button.`,
    name: 'John Doe',
    placeholder: {
      name: 'Name',
    },
  },
  contents: {
    title: 'Explore Various Contents',

    tag: {
      construction: 'Construction',
      logistics: 'Logistics',
      office: 'Office',
      medical: 'Medical',
      manufacturing: 'Manufacturing',
      retail: 'Retail',

    }

  },
  news: {
    title: 'Stay Informed with the Latest News!',
  },
}
