import {
  HomeAccess1D, HomeAccess1M, HomeAccess1T,
  HomeAccess2D, HomeAccess2M, HomeAccess2T,
  HomeAccess3D, HomeAccess3M, HomeAccess3T,
  HomeAccess4D, HomeAccess4M, HomeAccess4T,
  HomeAccess5D, HomeAccess5M, HomeAccess5T,
  HomeBeacon1D, HomeBeacon1M, HomeBeacon1T,
  HomeBeacon2D, HomeBeacon2M, HomeBeacon2T,
  HomeBeacon3D, HomeBeacon3M, HomeBeacon3T,
  HomeBeacon4D, HomeBeacon4M, HomeBeacon4T,
  HomeBeacon5D, HomeBeacon5M, HomeBeacon5T,
  HomeBeacon6D, HomeBeacon6M, HomeBeacon6T,
  HomeRTLS1D, HomeRTLS1M, HomeRTLS1T,
  HomeRTLS2D, HomeRTLS2M, HomeRTLS2T,
  HomeRTLS3D, HomeRTLS3M, HomeRTLS3T,
  HomeSolution1D, HomeSolution1M, HomeSolution1T,
  HomeSolution2D, HomeSolution2M, HomeSolution2T,
  HomeSolution3D, HomeSolution3M, HomeSolution3T,
  HomeSolution4D, HomeSolution4M, HomeSolution4T
} from "@assets/images/main/home";
import { HomeDTBanner } from "@assets/images/main/home/dt";

export default {


  titleBanner: {
    title: '위치추적과 디지털트윈 전문 플랫폼',
    description: '실시간 위치추적, 출입 시스템, 디지털트윈, 산업용 IoT가 가져오는 공간의 혁신'
  },

  productText: {
    title: `디지털트윈, 위치추적 제품`,
    description: `변화를 찾는 당신에게 오브로가 제안하는 새로움`
  },

  os: {
    title: '공간을 가장 잘 이해하는 소프트웨어',
    desc: '오브로 OS는 최신의 통신기술을 이용해<br/>공간의 모든 요소를 실시간으로 정확하게 추적하고 관리합니다.',
  },

  rtls: {

    title: '모든 현장에 적용 가능한 위치추적 라인업',
    desc: '블루투스, UWB통신을 이용한 전파 위치추적과 영상과 AI기술을 이용한 위치추적 라인업을 제공합니다.',

    card: [
      {
        tag: '실시간 위치추적',
        title: '현실을 가상 공간에서',
        link: 'https://orbro.io/rtls',
        img: {
          desktop: HomeRTLS1D,
          tablet: HomeRTLS1T,
          mobile: HomeRTLS1M
        },
      },
      {
        title: '비콘을 통한 위치 추적',
        desc: 'BLE를 통한 위치 추적 시스템',
        link: 'https://orbro.io/beacon/ble',
        img: {
          desktop: HomeRTLS2D,
          tablet: HomeRTLS2T,
          mobile: HomeRTLS2M
        },
      },
      {
        title: 'AI 카메라를 통한 위치추적',
        desc: 'AI기술을 활용한 위치추적 및 이상행동 감지',
        link: 'https://orbro.io/camera',
        img: {
          desktop: HomeRTLS3D,
          tablet: HomeRTLS3T,
          mobile: HomeRTLS3M
        },
      },
    ]
  },

  access: {

    title: '모바일을 이용한 자연스러운 비접촉 출입',
    desc: '모바일, 얼굴인식 기술을 이용한 차세대 출입방식과 정보를 제공합니다.',

    card: [
      {
        tag: '다양한 출입 방식 제공',
        title: 'NFC/Bluetooth/스마트폰<br/>모든 방식의 출입',
        sub: 'NFC카드 출입은 물론,<br/>모바일 출입, 스마트 워치 출입까지 가능합니다.',
        img: {
          desktop: HomeAccess1D,
          tablet: HomeAccess1T,
          mobile: HomeAccess1M
        },
      },
      {
        title: 'Access Pro',
        link: 'https://orbro.io/access/face',
        desc: '얼굴인식, NFC카드, 모바일 모든 출입 방식을 지원',
        img: {
          desktop: HomeAccess2D,
          tablet: HomeAccess2T,
          mobile: HomeAccess2M
        },
      },
      {
        title: 'Access Auto',
        link: 'https://orbro.io/access/auto',
        desc: '모바일 출입의 최적화',
        img: {
          desktop: HomeAccess3D,
          tablet: HomeAccess3T,
          mobile: HomeAccess3M
        },
      },
      {
        title: '액세서리',
        desc: '다양한 출입 액세서리 제공',
        link: 'https://orbro.io/access/accessory',
        img: {
          desktop: HomeAccess4D,
          tablet: HomeAccess4T,
          mobile: HomeAccess4M
        },
      },
      {
        title: 'Access Lite',
        desc: '기본에 충실한 모바일 출입 시스템과 NFC, 애플워치 지원',
        tag: "출시예정",
        img: {
          desktop: HomeAccess5D,
          tablet: HomeAccess5T,
          mobile: HomeAccess5M
        },
      },
    ]
  },

  dt: {
    title: '세계최초 3세대 디지털 전환<br/>디지털트윈 전문 플랫폼',
    desc: '오브로의 디지털트윈 솔루션으로 도시를 가상으로 구현하며, 한층 더 진화된 혁신을 체험해보세요.',
    img: HomeDTBanner
  },


  beacon: {

    title: '산업용 IoT 및 비콘 시스템',
    desc: '산업 현장에서 요구되는 다양한 센서 및 비콘 시스템으로 효율적인 작업 환경을 조성합니다.',

    card: [
      {
        title: 'ALESS-GW',
        desc: 'LoRa 중계기',
        link: 'https://orbro.io/sensor/LoRaGateway',
        img: {
          desktop: HomeBeacon1D,
          tablet: HomeBeacon1T,
          mobile: HomeBeacon1M
        },
      },
      {
        title: 'ALESS-BA',
        desc: 'LoRa통신을 이용한 산업용 센서',
        link: 'https://orbro.io/sensor/LoRaSensor',
        img: {
          desktop: HomeBeacon2D,
          tablet: HomeBeacon2T,
          mobile: HomeBeacon2M
        },
      },
      {
        title: 'VENTAX CO+',
        desc: '일산화탄소 측정기',
        link: 'https://orbro.io/sensor/co',
        img: {
          desktop: HomeBeacon3D,
          tablet: HomeBeacon3T,
          mobile: HomeBeacon3M
        },
      },
      {
        title: 'VENTAX',
        desc: '공기 품질 측정기',
        link: 'https://orbro.io/sensor/air',
        img: {
          desktop: HomeBeacon4D,
          tablet: HomeBeacon4T,
          mobile: HomeBeacon4M
        },
      },
      {
        title: 'Plutocon Pro',
        desc: '산업용 비콘 단말기',
        link: 'https://orbro.io/beacon/industry',
        img: {
          desktop: HomeBeacon5D,
          tablet: HomeBeacon5T,
          mobile: HomeBeacon5M
        },
      },
      {
        title: 'ORBRO Tag',
        desc: '소형 비콘 단말기',
        link: 'https://orbro.io/beacon/tag',
        img: {
          desktop: HomeBeacon6D,
          tablet: HomeBeacon6T,
          mobile: HomeBeacon6M
        },
      },
    ]
  },


  solution: {

    title: '위치추적과 디지털트윈의 융합',
    desc: '건설, 물류, 제조, 의료 등 다양한 산업분야에서 오브로의 첨단 솔루션을 만나보세요.',
    button: '상담 문의',
    buttonLink: 'https://orbro.io/request',
    card: [
      {
        title: '건설',
        desc: '건설현장 안전관리, 재고 관리 등 건설 현장이 스마트하게 변화합니다.',
        link: 'https://orbro.io/enterprise/construction/safety',
        img: {
          desktop: HomeSolution1D,
          tablet: HomeSolution1T,
          mobile: HomeSolution1M
        },
      },
      {
        title: '물류',
        desc: '대형 물류센터, 물류창고에서 실시간 재고 위치 현황 파악과 작업자 안전관리가 가능합니다.',
        link: 'https://orbro.io/enterprise/logistics/asset',
        img: {
          desktop: HomeSolution2D,
          tablet: HomeSolution2T,
          mobile: HomeSolution2M
        },
      },
      {
        title: '제조',
        desc: '실시간 제조 공정 관리가 가능하고 재고를 관리하여 생산성을 향상시킵니다.',
        link: 'https://orbro.io/enterprise/manufacturing/process',
        img: {
          desktop: HomeSolution3D,
          tablet: HomeSolution3T,
          mobile: HomeSolution3M
        },
      },
      {
        title: '의료',
        desc: '병원, 의료시설에서 환자를 추적 및 관리하여 안전을 지켜줍니다.',
        link: 'https://orbro.io/enterprise/medical/patient',
        img: {
          desktop: HomeSolution4D,
          tablet: HomeSolution4T,
          mobile: HomeSolution4M
        },
      },
    ]
  },


  //product.ts 
  product: {
    items: [
      {
        title: '무선 위치 추적',
        description: '무선 위치 추적을 통한<br/>완벽한 자산관리, 정확한 위치 추적',
        link: '/rtls'
      },

      {
        title: '카메라 위치추적',
        description: `AI기술을 활용한 카메라 위치 추적 시스템`,
        link: '/camera'
      },

      {
        title: '출입 시스템',
        description: '더 빠르게, 더 스마트하게.<br/>새로운 출입 시스템',
        link: '/access'
      },
      {
        title: '산업용 센서',
        description: '화재감지부터, 온습도 까지<br/>바로 현장 적용 가능한 센서',
        link: '/sensor/LoRaGateway'
      },
      {
        title: '사물 인터넷',
        description: `단순한 것에서 오는 특별한 변화`,
        link: '/iot/bell'
      },
      {
        title: '비콘 단말기',
        description: '완성형 비콘 시스템',
        link: '/beacon/industry'
      },

    ]


  },

  control: {
    title: `ORBRO를 통한 통합관리`,
    description: `단 하나의 소프트웨어<br/>모바일부터 데스크탑까지 필요한 것들만 심플하게`
  },

  showMore: '더 알아보기'
}