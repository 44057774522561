import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: '도시의 교통 상황을 실시간으로 모니터링하여 교통 관리 및 대응을 강화합니다. 교통정보 CCTV 연동, 오브젝트에 영상정보 포함, 대규모 영상정보 통합, 사고 분석 및 대책 개발, 교통 법규 준수 감시, 효율적인 교통 관리 전략 수립 등을 통해 교통 체계를 효과적으로 관리합니다.',
  keywords: 'Digital Twin, Digital Twins, 디지털트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: '디지털트윈 - 교통 CCTV 연결 :: 오브로 블로그 ',
  ogUrl: 'https://orbro.io/blog/digitaltwin-cctv',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '디지털트윈 - 교통 CCTV 연결',
  date: '2023-12-26',
  tag1: '#디지털트윈',
  tag2: '#CCTV',
  tag3: '#디지털시티',
  tag4: '#가상현실',

  // <br/>
  text: [
    {
      head: '디지털트윈 - 교통 CCTV 연결',
      subHead: '',
      desc: 'ORBRO는 교통 상황을 실시간으로 모니터링하여 교통 관리 및 대응을 향상시키는 서비스를 제공합니다. 교통정보 CCTV 연동, 오브젝트에 영상정보 포함, 대규모 영상정보 통합, 사고 분석 및 대책 개발, 교통 법규 준수 감시, 효율적인 교통 관리 전략 수립을 통해 교통 체계를 효과적으로 관리합니다.<br/><br/>이를 위해 CCTV 연동은 교통 흐름을 실시간으로 관찰하며, 오브젝트에 영상정보 포함은 교통 관리 및 안전을 강화합니다.<br/>대규모 영상정보 통합은 광범위한 지역의 교통 상황을 정확히 파악하고, 사고 분석 및 대책 개발은 도로 안전성을 향상시킵니다. 교통 법규 준수 감시는 교통 안전을 보장하며, 효율적인 교통 관리 전략은 교통 체증을 감소시킵니다. 이를 통해 ORBRO는 도로 및 교통 환경을 효과적으로 관리하여 안전하고 효율적인 교통 체계를 유지합니다.',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/iOZVM1YT_pE?si=MkzatuD-2E4xbzkQ',
      head: '',
      subHead: '교통정보 CCTV 연동',
      desc: 'ORBRO는 도로 및 교통 상황을 모니터링하기 위해 CCTV 영상을 실시간으로 연동합니다. 이를 통해 교통 관리자들은 교통 흐름을 실시간으로 관찰하고, 교통 사고나 체증과 같은 상황에 신속하게 대응할 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '오브젝트에 영상정보 포함',
      desc: 'CCTV 영상 내의 차량, 사람 등의 오브젝트를 식별하여 추가 정보를 제공합니다. 이는 교통 관리 및 안전 강화에 기여하며, 교통 사고 예방 및 조사에 중요한 역할을 합니다.',
    },
    {
      head: '',
      subHead: '대규모 영상정보 통합',
      desc: '여러 CCTV의 영상 정보를 통합하여 광범위한 지역의 교통 상황을 한눈에 파악할 수 있도록 합니다. 이는 도시의 교통 관리 및 계획 수립에 중요한 데이터를 제공합니다.',
    },
    {
      head: '',
      subHead: '사고 분석 및 대책 개발',
      desc: 'CCTV 데이터를 활용하여 교통 사고의 원인을 분석하고, 사고를 줄이기 위한 대책을 개발합니다. 이는 도로 안전을 향상시키고, 사고 발생률을 감소시킵니다.',
    },
    {
      head: '',
      subHead: '교통 법규 준수 감시',
      desc: '교통 법규 위반을 감시하고, 위반자를 식별하여 법규 준수를 촉진합니다. 이는 교통 안전을 보장하고, 규칙을 준수하는 문화를 장려합니다.',
    },
    {
      head: '',
      subHead: '효율적인 교통 관리 전략 수립',
      desc: '수집된 데이터를 분석하여 교통 흐름을 개선하고, 효율적인 교통 관리 전략을 수립합니다. 이는 교통 체증을 감소시키고, 시민들의 이동 편의성을 높입니다.',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/enterprise/logistics/asset'
      }
    ]


  }

}