import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'ORBRO\'s GIS implementation provides a real-time geographic information system that combines precision cartography with 3D terrain simulation. This system supports experts in terrain analysis, infrastructure planning, and disaster management decisions, reflecting ORBRO\'s innovative technological capabilities.',
  keywords: 'Digital Twin, Digital Twins, 디지털트윈, gis, 디지털 시티, RTLS, location tracking system, location tracking technology, sports, manufacturing, logistics, medical, hospital',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - GIS Implementation :: ORBRO Blog',
  ogUrl: 'https://orbro.io/en/blog/digitaltwin-gis',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - GIS Implementation',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#GIS',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  // <br/>
  text: [
    {
      head: 'Digital Twin - GIS Implementation',
      subHead: '',
      desc: `ORBRO's GIS (Geographic Information System) implementation utilizes digital twin technology to provide an innovative real-time geographic information system that integrates precise cartography and 3D terrain simulation. This system supports experts in terrain analysis, infrastructure planning, and disaster management decisions, reflecting ORBRO's cutting-edge technological capabilities.<br/><br/>The 3D terrain model visually represents the accurate shape, slope, and height of the terrain, utilized for environmental analysis, urban planning, and disaster management. Infrastructure data is digitized using 3D modeling and GIS technology, essential for urban development and disaster response.<br/><br/>Building information is modeled using high-resolution photos, architectural design data, and 3D scanning, playing a crucial role in architectural design and urban planning. GIS technology provides essential information for urban planning, traffic flow optimization, and public service deployment, while also playing a key role in disaster response, environmental changes, and protection.`,
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/uUm8my-ogGs?si=UNRDMDxCeI3ZWxyt',
      head: '',
      subHead: 'Representation of Earth-shaped Geography',
      desc: 'ORBRO integrates satellite images, terrain data, and Geographic Information System (GIS) to build high-resolution 3D terrain models. This model visualizes real-time details such as the shape, slope, and height of the terrain, contributing to crucial decision-making in various fields such as environmental analysis, urban planning, disaster management, and rescue planning.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Representation of Terrain and Infrastructure',
      desc: 'Collect infrastructure data from various sources and digitize it through 3D modeling and GIS technology. This information is essential for city and regional development, disaster response, and safety planning.',
      dot: ''
    },
    {
      head: '',
      subHead: '3D Representation of Building Information',
      desc: 'ORBRO precisely models the exterior and interior structure of buildings using high-resolution photos, architectural design data, and 3D scanning technology. This modeling is crucial for architectural design, urban planning, real estate development, and evaluation.',
    },
    {
      head: '',
      subHead: 'Improvement of Urban Planning and Management',
      desc: 'ORBRO\'s GIS technology helps improve planning and management in complex urban environments. It provides essential information for optimizing traffic flow, placing public services, and managing city expansion.',
    },
    {
      head: '',
      subHead: 'Support for Disaster Response and Preparedness',
      desc: 'Utilize GIS technology to support response and preparedness for natural disasters and emergencies. It plays a crucial role in identifying high-risk areas using terrain and infrastructure data, planning evacuation routes, and allocating relief resources.',
    },
    {
      head: '',
      subHead: 'Environmental Change and Protection',
      desc: 'ORBRO\'s GIS is a crucial tool for monitoring and analyzing environmental changes. This technology contributes to understanding ecosystem changes, managing nature reserves, and formulating environmental protection policies.',
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'This product assists real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }
    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]
  }
}
