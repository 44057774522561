import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {

  helmet: '',
  desc: 'ORBRO provides advanced technology that effectively manages the movement of individuals and vehicles within the city by integrating real-time location data and 3D visualization. This enhances the safety and efficiency of the transportation system.',
  keywords: 'Digital Twin, Digital Twins, 디지털트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'Digital Twin - Connecting Real-time Movements of People and Vehicles',
  ogUrl: 'https://orbro.io/en/blog/digitaltwin-tracking',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'Digital Twin - Connecting Real-time Movements of People and Vehicles',
  date: '2023-12-26',
  tag1: '#DigitalTwin',
  tag2: '#RTLS',
  tag3: '#DigitalCity',
  tag4: '#VirtualReality',

  // <br/>
  text: [
    {
      head: 'Digital Twin - Connecting Real-time Movements of People and Vehicles',
      subHead: '',
      desc: 'ORBRO effectively manages the movements of individuals and vehicles within the city by integrating location data and 3D visualization. To achieve this, various technologies and systems are utilized to enhance the safety and efficiency of the transportation system.<br/><br/>RTLS (Real-Time Location System) technology tracks the real-time location and movements of people, while video technology monitors the movements of vehicles. 3D visualization visually represents the dynamic movements of people and vehicles, efficiently managing movements within specified zones.<br/><br/>The digital transformation dashboard aggregates collected data to provide insightful information, and smart city integration enhances the automation and efficiency of city operations. This optimizes traffic flow and infrastructure usage within the city, supporting the implementation of a smart city.',
      dot: ''

    },
    {
      youtube: 'https://www.youtube.com/embed/g0Kv05InEtI?si=7EnfmOzAOOi-3ds2',
      head: '',
      subHead: 'Collecting Information on People Using RTLS Technology',
      desc: 'ORBRO uses a real-time location tracking system (RTLS) with high precision to track the location and movement of people. This is crucial, especially in large public spaces or emergency situations, to ensure the safety and security of people.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Collecting Information on Vehicles Using Video Technology',
      desc: 'Utilizing various video capture technologies to monitor and analyze the movement of vehicles in the city in real-time. This contributes to optimizing traffic flow and preventing traffic accidents.',
      dot: ''
    },
    {
      head: '',
      subHead: 'Real-time Representation in 3D',
      desc: 'Visualizing the dynamic movements of people and vehicles in 3D, providing essential data for city planning, traffic management, safety enhancement, and more.',
    },
    {
      head: '',
      subHead: 'Zone Designation',
      desc: 'Effectively managing and analyzing the movement of people and vehicles within a specific zone by setting up zones. This is useful for public safety, event management, and traffic control.',
    },
    {
      head: '',
      subHead: 'Effective Dashboard',
      desc: 'Providing insightful dashboards by aggregating data on the movement of people and vehicles for traffic management, city planning, public safety, and more. This supports decision-makers in more effectively carrying out city operations and management.',
    },
    {
      head: '',
      subHead: 'Smart City Integration',
      desc: 'Integrating location data and analysis into smart city systems to increase automation and efficiency in city operations. This contributes to optimizing energy management, transportation systems, and public service.',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'A product that assists in real-time location tracking by attaching UWB terminals to workers or equipment.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/en/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/en/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/en/enterprise/logistics/asset'
      }
    ]


  }

}
