import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionSafety, logisticsAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";

export default {


  helmet: '',
  desc: '항공기의 실시간 추적과 운영 정보를 제공하여 항공 이동성을 향상시키고, 여행 및 운영의 안전성을 보장합니다. 항공사 정보, 항공 경로 확인, 공항별 스케줄 확인, 비상 상황 관리, 고객 서비스 개선, 환경 영향 모니터링 등을 통해 항공 여행의 경험을 개선합니다.',
  keywords: 'Digital Twin, Digital Twins, 디지털트윈, gis, 디지털 시티, RTLS, 위치추적시스템, 위치추적기술, 스포츠, 제조, 물류, 의료, 병원',

  ogTitle: '',
  ogDesc: '',
  ogSite: '디지털트윈 - 항공기 정보 연결 :: 오브로 블로그 ',
  ogUrl: 'https://orbro.io/blog/digitaltwin-airplane',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmsHKPo0k1JGpYe5RqcLPvhvPsttOE54okj7zmGDmBsNO7w1O-JK1oTjUeN0SXoXeuVHVRR0yqY597xyclhreNiwqO8zxMhslWsAccGNFJL7Cc4jphNGY1U-ApF8rYUJlkQ29hCvXhs1-kJ7ItJ8yzqul6tD2bkCeeSf24qL0tmKPNG7wJdcdkE8HnxTJ-dAJvm-HQ1tD8-RFPjUfTcllJI-RCb7dzd6jATNYzQbe1GQD7gejTG574T2T6jKa1sIQLT1me2evIreAszenpfb2ZOAOafbrlQEnfJCF577BDuUZxdtYW-PpTWZdcoK_GlrZTVEXWFVIe4zYY4BA9uI-4MvLCytQ19heux-zPZ2uj0Pd-eu04szV3P6TFIG2dqGtdzui7sXRbRh4-9toRM1UEYley9vu4sOHPbxuwV1LN97GHKbtKrmz4OsgOS0jK8VWR0v_KCwr08MXIduU_b2QT5axXUH4CSftoADRRfaDprmZIqjdAdy7RqILvmsg_V7NQAcd9xapuxx4MMoC42nj2n0iwd9yZV--15jzcgqW6daHUiYEZ4ggt7Uy2LoPScqjh7hhOm8vT1XPj6fSf3W1SgBduBXU9h5e0_bG9GKZLWMCy72SHi0Xs0NO0X7FSOgdDU0scaP-F8WlOsTezavpMEhxUlz2fhL_mBIfZVO25KKS-y-Qfuul0bpf2coLNtuVO4yW_hZEZuAzW7FbuIUdoyI04V7I493a1IXz6-A_ZcTH2j2retDHu_nD6RnBrnlcCsY0tnVCMM0ho0k_GLGaGTrs_88IPxii47Y_Qa1XXH-8-wwpGN0UocvGhXB6rdM7S-m7_MIT3ONC4MyvE3Bqjkid-QLeYtThJ2zXLCXfCIaCyi4jttco-MJjx84qBVRrUWpwcvpISJ7E9pYCJukWT1rgVNPsgO1baez2jR2IyH1hMZ4SXB6nHNVoylyuvAjn1uYfjDCfOgRlqmcK8wt3U1GImqc68KthjcbhAjDNmlLNtBl9Pd4Ksg0Xts8R_Z_LbV6Ue834BoCmu5H_jzmEevCkvwPsYoLsmqebwEqmrp2B58f2e32e0YY8L8jPuMPX3UDZyxX98adUK-gekxc1YB5JrqJPlcC4nZA-XLh8EmakSbt83hYYjIkhbHeafd7Hr5M-Em9znV_sFo2DaAwfkAQRoa80pNidI39UxDJVdey6nVP9H6UPL-i_N-H1K6LpiALIwQNKPMlI4up5e-hme40ZgUL9QKbBaKDyfOxk1aqml1cTzHCZJRP_jLw2dOoS1K-EG7OsGJDxmnclokA0StmKuFCPLAbAodPa1K_Btbk2GtITh4kvm07TLOp_LDZBnztDlzA8WuId3bEWiXNRc5qKlfv5UYlEn_k4L03K4A-T49R-Pi75Jp53wW68HhGwDoSbyYjaiI8plzJeyMyGJduXJZtYWh66ETIXdt8IxUDYw5otbzXD-lFsSLdM09U37NH_lU0c07Sjg77a4m1OfI1-mfJmHzqzvn9kksa3H2aYES0HhIq5RPgdd0NyW7b2IvL9b7nDE4tL4D-hf70biexJ_aaBkb=w1923-h1074',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '디지털트윈 - 항공기 정보 연결',
  date: '2023-12-26',
  tag1: '#디지털트윈',
  tag2: '#항공기',
  tag3: '#디지털시티',
  tag4: '#가상현실',

  // <br/>
  text: [
    {
      head: '디지털트윈 - 항공기 정보 연결',
      subHead: '',
      desc: 'ORBRO는 항공기의 실시간 추적과 운영 정보를 통해 항공 이동성을 향상시키고 여행 및 운영의 안전성을 확보합니다. 항공사 정보 제공, 항공 경로 모니터링, 공항별 스케줄 확인, 비상 상황 관리, 고객 서비스 개선, 환경 영향 모니터링 등을 통해 항공 여행의 전반적인 경험을 개선하고 있습니다.<br/><br/>항공사 정보 제공은 승객들이 항공 여행 계획을 세우는 데 필수적인 정보를 제공하여 편리한 여행을 돕습니다. 항공 경로 모니터링은 항공 교통 관리와 안전을 보장하여 원활한 운행을 지원합니다. 공항별 스케줄 제공은 여행객들에게 정확하고 신뢰성 있는 여행 정보를 제공하여 여행 일정을 효과적으로 관리할 수 있도록 돕습니다.<br/><br/>비상 상황 관리는 예기치 못한 상황에 대비하여 승객과 직원의 안전을 최우선으로 고려합니다. 고객 서비스 개선은 승객 대응 및 서비스 품질을 향상시켜 만족도를 높입니다. 환경 영향 모니터링은 지속 가능한 항공 운영 전략을 수립하여 환경적 책임을 다하고 지속 가능성을 추구합니다.ORBRO는 이러한 다양한 기능을 통해 안전하고 편리한 항공 여행을 지원하며, 지속 가능한 운영에 기여합니다.',
      dot: ''
    },
    {
      youtube: 'https://www.youtube.com/embed/w1pKhaleOH4?si=BNCBDwA5fepB03Y3',
      head: '',
      subHead: '항공사 정보 제공',
      desc: 'ORBRO는 각 항공사의 항공기 유형, 노선, 스케줄 등의 정보를 실시간으로 제공합니다. 이 정보는 승객들이 항공 여행 계획을 수립하는 데 필요하며, 항공사와 공항 운영자들에게 항공기 운영과 관리에 필요한 중요한 데이터를 제공합니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '항공 경로 모니터링',
      desc: '항공기의 실시간 경로를 모니터링하고 표시하여, 항공 교통 관리와 항공 안전을 보장하는 데 중요한 역할을 합니다.',
    },
    {
      head: '',
      subHead: '공항별 스케줄 제공',
      desc: '각 공항의 도착 및 출발 스케줄, 게이트 정보 등을 포함한 공항별 항공 스케줄을 제공하여, 여행객들에게 정확한 여행 정보를 제공하고 공항 운영의 효율성을 향상시킵니다.',
    },
    {
      head: '',
      subHead: '비상 상황 관리',
      desc: '실시간 항공 데이터를 활용하여 비상 상황에 대한 신속한 대응 및 관리를 가능하게 하며, 승객과 직원의 안전을 보장하고 잠재적인 위험을 최소화합니다.',
    },
    {
      head: '',
      subHead: '고객 서비스 개선',
      desc: '실시간 항공 정보를 활용하여 승객 대응 및 서비스 개선을 촉진하며, 승객 만족도를 높이고 항공 여행 경험을 개선합니다.',
    },
    {
      head: '',
      subHead: '환경 영향 모니터링',
      desc: '항공기 운영과 관련된 환경 영향을 모니터링하고 지속 가능한 항공 운영 전략을 수립하여, 탄소 배출 감소 및 환경 보호 노력에 중요한 역할을 합니다.',
    },

  ],


  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: constructionAsset.thumb,
        data: 'constructionAsset',
        link: '/enterprise/construction/asset'
      },
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/enterprise/logistics/asset'
      }
    ]


  }

}