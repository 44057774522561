import { StyledGridColumn } from "@components/Grid/Grid.style";
import { StyledHeading, StyledLabel } from "@components/Text";
import React, { useState } from "react";
import { StyledRequestForm, StyledRequestFormGroup, StyledRequestFormInput, StyledRequestFormRow, StyledRequestFormSubmit, StyledRequestFormTextarea } from "./RequestForm.style";
import { Trans } from "react-i18next";
import { WHITE } from "@assets/styled/color.style";
import { useLocation } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";
import emailjs from 'emailjs-com';


export interface RequestFormData {
  name: string,
  title?: string,
  corporateName: string,
  email: string,
  phone: string,
  type: string,
  memo: string,
}

interface RequestFormProps {
  serviceType: string
}

const RequestForm = (props: RequestFormProps) => {


  const location = useLocation();
  let ph_name;
  let ph_title;
  let ph_company;
  let ph_mail;
  let ph_phone;
  let ph_option;

  //set placeholder
  if (location.pathname.includes('/en/')) {
    ph_name = 'John Doe';
    ph_title = 'optional';
    ph_company = 'ORBRO Corporation';
    ph_mail = 'example@orbro.io';
    ph_phone = '010-1234-5678';
    ph_option = 'Please enter any additional inquiries or comments. (Optional)';
  } else if (location.pathname.includes('/jp/')) {
    ph_name = '山田太郎';
    ph_title = 'オプション';
    ph_company = 'オブロ株式会社';
    ph_mail = 'example@orbro.io';
    ph_phone = '090-1234-5678';
    ph_option = 'その他のお問い合わせ内容を入力してください。 (任意)';
  } else if (location.pathname.includes('/cn/')) {
    ph_name = '张三';
    ph_title = "选项";
    ph_company = 'ORBRO 股份公司';
    ph_mail = 'example@orbro.io';
    ph_phone = '090-1234-5678';
    ph_option = '请输入其他咨询事项（选择）';
  } else {
    ph_name = '홍길동';
    ph_title = '선택사항';
    ph_company = '오브로 주식회사';
    ph_mail = 'example@orbro.io';
    ph_phone = '010-1234-5678';
    ph_option = '기타 문의사항을 입력해 주세요. (선택)';
  }

  let alertMsg;
  let emptyMsg;
  let successMsg;
  if (location.pathname.startsWith('/en/')) {
    alertMsg = 'Email format is invalid'
    emptyMsg = 'There are empty values'
    successMsg = `Thank you for your inquiry. We'll get back to you as soon as possible.`
  } else if (location.pathname.startsWith('/jp/')) {
    alertMsg = 'メール形式が正しくありません'
    emptyMsg = '空の値があります'
    successMsg = 'お問い合わせありがとうございます。 早いうちにご連絡いたします。'
  } else if (location.pathname.includes('/cn/')) {
    alertMsg = "电子邮箱格式不正确"
    emptyMsg = "存在空值"
    successMsg = '谢谢，我会尽快联系您的。'
  } else {
    alertMsg = '이메일 형식이 올바르지 않습니다'
    emptyMsg = '비어있는 값이 있습니다'
    successMsg = '문의 주셔서 감사합니다. 빠른 시일 내로 연락드리겠습니다.'
  }

  const { serviceType } = props;

  // States
  const [name, setName] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [corporateName, setCorporateName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [memo, setMemo] = useState<string>('');

  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }

  // Form Data Check
  const validateForm = () => {
    const data = { name, title, corporateName, email, phone, memo, type: '' };

    if (data.name === '' || data.corporateName === '' || data.email === '') {
      alert(emptyMsg)
      return;
    }

    if (!email.includes("@")) {
      alert("Invalid Email Form");
      return;
    }

    if (data.name == 'e' || data.corporateName == 'e') {
      alert("Invalid Data");
      return;
    }


    alert(successMsg);
    //inquiry 데이터
    //handleClickSubmit(data);
    //notion API
    notionSubmit(data);
    //emailjs
    sendEmail(data);
  }

  // Submit Click Event
  const handleClickSubmit = async (data: RequestFormData) => {
    const { name, title, corporateName, email, phone, memo } = data;
    const model = { name, title, corporate_name: corporateName, email, phone, memo, type: serviceType };

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: 'https://inquiry.kong-tech.com/contact/api/v1/contact/orbro',
      data: model
    };

    try {
      await axios.request(config);
    } catch (e) {
    }
  }


  // 데이터를 Notion에 전송하는 함수
  const notionSubmit = async (data: RequestFormData) => {
    try {
      const response = await fetch("https://orbro.io/submitToNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: data.name + " / " + data.title,
          corporateName: data.corporateName,
          email: data.email,
          phone: data.phone,
          memo: data.memo
        })
      });

    } catch (error) {
      console.log('error', error);
    }

  }


  const sendEmail = (data: RequestFormData) => {

    const { name, title, corporateName, email, phone, memo } = data;

    emailjs.init('SoQtE3CThBY_wpeDn');
    const mailForm = {
      name: name,
      title: title,
      corporateName: corporateName,
      email: email,
      phone: phone,
      memo: memo,
      serviceType: serviceType,
      created_time: new Date().toLocaleString()
    }

    // 이메일 보내기
    emailjs.send('service_7yv5b7d', "template_eegp92w", mailForm)
      .then((response) => {

      })
      .catch((error) => {

      });

  };


  return (
    <StyledRequestForm>
      <StyledRequestFormRow desktop={6} tablet={6} mobile={4}>

        <StyledGridColumn desktop={3} tablet={3} mobile={4}>
          <StyledRequestFormGroup>
            <StyledHeading size="xxsmall"><Trans i18nKey={"request.form.name"} /> *</StyledHeading>
            <StyledRequestFormInput
              value={name}
              placeholder={ph_name || ''}
              onInput={(e) => { handleInput(e, setName) }}
            />
          </StyledRequestFormGroup>
        </StyledGridColumn>



        <StyledGridColumn desktop={3} tablet={3} mobile={4}>
          <StyledRequestFormGroup>
            <StyledHeading size="xxsmall"><Trans i18nKey={"request.form.title"} /></StyledHeading>
            <StyledRequestFormInput
              value={title}
              placeholder={ph_title || ''}
              onInput={(e) => { handleInput(e, setTitle) }}
            />
          </StyledRequestFormGroup>
        </StyledGridColumn>



        <StyledGridColumn desktop={3} tablet={3} mobile={4}>
          <StyledRequestFormGroup>
            <StyledHeading size="xxsmall"><Trans i18nKey={"request.form.companyName"} /> *</StyledHeading>
            <StyledRequestFormInput
              value={corporateName}
              placeholder={ph_company || ''}
              onInput={(e) => { handleInput(e, setCorporateName) }}
            />
          </StyledRequestFormGroup>
        </StyledGridColumn>



        <StyledGridColumn desktop={3} tablet={3} mobile={4}>
          <StyledRequestFormGroup>
            <StyledHeading size="xxsmall"><Trans i18nKey={"request.form.email"} /> *</StyledHeading>
            <StyledRequestFormInput
              value={email}
              placeholder={ph_mail || ''}
              onInput={(e) => { handleInput(e, setEmail) }}
            />
          </StyledRequestFormGroup>
        </StyledGridColumn>



        <StyledGridColumn desktop={6} tablet={6} mobile={4}>
          <StyledRequestFormGroup>
            <StyledHeading size="xxsmall"><Trans i18nKey={"request.form.phoneNumber"} /></StyledHeading>
            <StyledRequestFormInput
              value={phone}
              placeholder={ph_phone || ''}
              onInput={(e) => { handleInput(e, setPhone) }}
            />
          </StyledRequestFormGroup>
        </StyledGridColumn>



        <StyledGridColumn desktop={6} tablet={6} mobile={4}>
          <StyledRequestFormGroup>
            <StyledHeading size="xxsmall"><Trans i18nKey={"request.form.comment"} /></StyledHeading>
            <StyledRequestFormTextarea
              value={memo}
              placeholder={ph_option || ''}
              onInput={(e) => { handleInput(e, setMemo) }}
            />
          </StyledRequestFormGroup>
        </StyledGridColumn>

      </StyledRequestFormRow>


      <StyledRequestFormSubmit onClick={validateForm}>
        <StyledLabel
          size="large"
          color={WHITE}
        >
          <Trans i18nKey={"request.form.submit"} />
        </StyledLabel>
      </StyledRequestFormSubmit>

    </StyledRequestForm>
  )
};

export default RequestForm;
