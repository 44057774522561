import { constructionAsset, constructionSafety, logisticsAsset, logisticsSafety, manufacturingAsset } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { BlogOrbroWorld } from "@assets/images/blog";

export default {


  helmet: '| 디지털트윈의 실체와 발전 가능성',
  desc: '디지털트윈은 현실 세계의 물리적 엔티티와 프로세스를 디지털로 복제하는 방식으로, 이를 통해 현실 세계의 시스템을 디지털 공간에서 모델링하고 시뮬레이션할 수 있습니다.',
  keywords: '디지털트윈, 디지털트윈 기술, 디지털트윈의 가치, 디지털트윈 활용 사례, 미래의 기술, 디지털 혁신, 미래 비전, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: '디지털트윈의 실체와 발전 가능성 :: 오브로 블로그',
  ogUrl: 'https://orbro.io/blog/digitalTwin2',
  ogKey: '',
  ogImg: 'https://lh3.googleusercontent.com/fife/AK0iWDyMknjvJ99ToTqI13n6sJ8BSaf3JZGOENkN_2JU7vwTVdJ1mg0nffpOx5r6tq6lv4F0bOXSJdbY8A6oX1AFY03LIJf2JtFF2wRcEJOJaquWjtZsd0mWpQBq_pEUGTJFnlWDkhyXb4I74BgzY1m4mqHU6tEW28PtAaGDrwMAQtY2jBxkHkkc2CElFc6iCNlhM0CBo5Q5nJ5smX5h-I7jl-DfT5xZkB0go6qpIOi66-rEsvQSbT33lnaaD5QvGwFOO0Qv2cvnKoewfm0pA5WcKdE2-m7UMQJBBnPNaQSttqkpy3a1ROL6WpobJ4hXVx6u7sgSCjox5fUSiLYwOz7Ogj48pxEWoqBOPMi8xU6SPj1GkXJlNbKSLFiPh1Gxx4gAj6yna064glGTGfkFz4utstmCQDRyUfHIBNWgoRIA6w7sZTibsxQw0kVLOs1zvoSpcVnuqfTeCeMc2zVn-RPDhOYs47NdtJw15gD-7g2SEnQoPQ0jPuPsUlQK3cZiwGJPXqaUpk_bJEHoKljrUPvZ4RskxzXd_Wd_iUR_fAsXgtfYJA2y69CplR2X8zI2xNr6o3QMeEnodvetxx_wijsIzX0VB-NY9ARHuYoDLWeuluJd3L5Jwbz7RUt9Daau7aK0xPzczhs0wCZukWXKxEHTa4vsViogKLwCwQCGRH0i-etPSDQoL6QDgCZYMpi19QP2c4EpWmal3bbzPuDTXE9c5KGhCoKeadtLOyfRIQSXO4j3qfTLZDhlfYui2qpfof_b8iQDXbrYnKdQFKCF1BsOgdB-x6BFgyxy4yiJFodhm11ZsU2hE-FHcFe57GTIa-9BoCesvhAASwxcG3Jb87SMGY20XeoYQRdKybgwKJVtyHYIyr-96-_YGoT3K0R1g8Rh_-5g8ieKsx9xYxgBP_SRy15quSjqbY_sb-Br-ABcrOvK-f_8B3Fcs4MUyawnsk1F3Iq757aU6O9vr5eRVr0p_e617DypRhC-EsoUOnfuneCpYiwISUNEh7dzIWRwMoAg0nsWPpsaZBAFDzkfkWsNzdkHNsR51jz0otfpLr1G_F5MN4-TRI9fLTeBG72qfhqwexJIJ3hty3NLh45kAfx6b4jQVDuVOEloTq3hBRamRttcG_zUXLYPChDX4_px_PqdCbBFebzcfjbJUSwJddmFGYkV8Oc0z7dze6CyvuHcWRubrB7_QHLFSrKfkJAQWWwuL6pILkMJathIpb7hFq7aW9OEeRdf7IWj4VFsGIRvc-B_Pi7Rkvw4yTyX18g6trPF-jBAa7kbSdyNvEqsfT7SJLR2-N3Gt8Y4u2rF8_YwJ8xywkibJnFJwrmqFbsLwJJbquz__yB38C0nECD5y5NUoN1ZJVQ4g2ZhUEJAKWxvXGu7SqhfmR-BssX_5pHjrMO5KNdT4GzxR6UMQmqq0P81ai916GVxC9u-klTiMQxnWqlOAVF8oxfYqPkwAQ9gjipWXh2CpHh12DVNM22CcBvVmV3r35ystH3OAURGJoOQ0RSPUw6ULmaboU1q4tLaJa08YT-WN9q9XNnbjgHFb654mIrS4AI-5Sm_OQxMxxO7bymwwgJH5VMy=w2000-h1916',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: '디지털트윈의 실체와 발전 가능성',
  date: '2023-10-26',
  tag1: '#디지털트윈',
  tag2: '#디지털전환',
  tag3: '#스마트시티',
  tag4: '#RTLS',

  // <br/>
  text: [
    {
      img: BlogOrbroWorld,
      link: "https://home.orbro.io",
      head: '',
      subHead: '',
      desc: '현대 사회는 끊임없이 발전하고 진화하는 과정을 겪고 있습니다. 이러한 변화는 주로 디지털 기술의 급속한 발전으로 인해 주도되고 있으며, 이것이 디지털트윈이라는 혁신적인 개념을 뒷받침하고 있습니다. 디지털트윈은 현실 세계의 물리적 엔티티와 프로세스를 디지털로 복제하는 방식으로, 이를 통해 현실 세계의 시스템을 디지털 공간에서 모델링하고 시뮬레이션할 수 있습니다. 이것은 우리의 업무 방식, 생활, 그리고 기술의 사용 방식에 혁명을 가져오고 있습니다.',
      dot: ''

    },
    {
      head: '1. 디지털트윈의 개념',
      subHead: '',
      desc: '디지털트윈은 물리적 실체의 디지털 복제물로, 이를 통해 실제 시스템이나 프로세스를 디지털 공간에서 재현하고 모니터링할 수 있습니다. 이 디지털 복제는 센서 데이터, IoT 장치, 시뮬레이션, 빅데이터 분석 등을 활용하여 현실 세계의 모든 측면을 포착하고 분석합니다. 이를 통해 시스템의 동작을 예측하고 최적화할 수 있습니다.',
      dot: ''

    },
    {
      head: '2. 디지털트윈의 가치',
      subHead: '',
      desc: '디지털트윈은 다양한 산업 분야에서 가치를 제공합니다. 먼저, 제조업에서는 제품의 생애 주기를 관리하고 생산 프로세스를 최적화하는 데 디지털트윈을 활용합니다. 제품의 성능을 모니터링하고 예측 분석을 통해 고장을 사전에 예방할 수 있습니다.<br/>또한, 건설 분야에서는 건물과 시설물의 건설 및 운영을 지원하는데 디지털트윈을 활용합니다. 건설 프로젝트의 계획, 시뮬레이션, 및 모니터링을 통해 프로젝트 일정을 준수하고 예산을 관리할 수 있습니다.<br/>의료 분야에서는 환자의 건강 상태를 디지털트윈을 통해 추적하고 질병의 조기 진단 및 예방을 지원합니다. 또한, 의료 기기와 의약품의 개발 및 생산 과정을 최적화하는 데 사용됩니다.<br/>에너지 분야에서는 발전소, 전력 그리드, 및 에너지 시스템의 운영을 최적화하고 에너지 효율성을 향상시키는데 디지털트윈을 활용합니다.',
      dot: ''

    },
    {
      head: '3. 디지털트윈의 활용 사례',
      subHead: '',
      desc: '오브로는 이미 수많은 기업들과 디지털트윈 적용 프로젝트를 진행하였습니다. 병원부터 제조시설 등, 주요 산업들을 비롯한 여러 현장에서 작업 효율과 생산성을 높이기 위해 최적의 솔루션을 제공하였고 결과적으로 디지털 전환은 성공적이었습니다. 뿐만 아니라 디지털트윈의 활용 사례는 계속 확대되고 있습니다. 항공우주 산업에서는 항공기의 상태를 모니터링하고 유지보수를 개선하기 위해 사용되며, 도시 계획에서는 도시의 인프라와 교통 시스템을 최적화하는 데 활용됩니다.<br/>또한, 농업 분야에서는 작물의 성장과 건강을 추적하고 농업 생산성을 향상시키는데 디지털트윈이 사용됩니다. 스마트 시티 프로젝트에서는 도시의 모든 측면을 디지털트윈으로 모델링하여 도시의 지능화와 지속 가능성을 촉진합니다.<br/>디지털트윈은 물리적 세계와 디지털 세계를 융합하는 혁신적인 방법으로, 다양한 산업 분야에서 가치를 제공하며 혁신과 효율성을 높이는데 중요한 역할을 하고 있습니다. 이 기술은 미래에 더욱 중요해질 것으로 예상되며, 기업과 조직은 디지털트윈을 적극적으로 활용하여 경쟁력을 강화하고 지속 가능한 성장을 이루어갈 것입니다.',
      dot: ''

    },
    {
      head: '결론: 디지털트윈의 미래와 가능성',
      subHead: '',
      desc: '디지털트윈은 미래의 핵심 기술 중 하나로 자리 잡을 것입니다. 그 가치는 무한하며, 다양한 산업 분야에서 혁신을 주도하고 생산성을 향상시킬 것입니다. 물론, 보안과 개인 정보 보호와 같은 중요한 고려 사항도 존재하지만, 이러한 문제들은 기술의 발전과 함께 지속적으로 해결될 것입니다.<br/><br/>디지털트윈은 우리의 일상과 비즈니스를 혁신하고 지능적으로 만들어갈 것입니다. 이것은 현실과 디지털의 경계를 무너뜨리며, 더 스마트하고 연결된 세상을 만들어갈 것입니다. 우리는 이 기술을 적극적으로 받아들이고, 미래를 형성하는 과정에서 디지털트윈을 중심으로 새로운 가능성을 탐구해야 합니다.',
      dot: ''

    },

  ],

  link: {
    page: 'rtls',
    numb: '1',
    product: [
      {
        text: productPurchase.purchase.asset.assetTracking.title,
        title: productPurchase.purchase.asset.assetTracking.title,
        serial: productPurchase.purchase.asset.assetTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.assetTracking.key1
      },
      {

      }

    ],
    solution: [
      {
        img: constructionSafety.thumb,
        data: 'constructionSafety',
        link: '/enterprise/construction/safety'
      },
      {
        img: logisticsAsset.thumb,
        data: 'logisticsAsset',
        link: '/enterprise/logistics/asset'
      },
      {
        img: manufacturingAsset.thumb,
        data: 'manufacturingAsset',
        link: '/enterprise/manufacturing/asset'
      }
    ]

  }


}