import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import { constructionAsset, constructionEquipment, constructionSafety } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import video from '@src/assets/images/solution/logisticsAsset/video/rtlsVideo.mp4';



export default {

    desc: '스마트 빌딩은 IoT 센서, 인공지능, 자동화 시스템 등의 기술을 활용하여 건물 내의 다양한 시스템을 연결하고 통합하여 건물 운영을 최적화하는 건물입니다. 이를 통해 에너지 효율성을 향상시키고, 건물의 안전성, 편의성, 효율성을 모두 향상시킵니다.',
    keywords: '스마트 빌딩, RTLS, IoT, 디지털트윈, 디지털전환',

    ogSite: '스마트 빌딩이란? | 디지털트윈 전문 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/smart-buildings',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDmNclLyXvFXAppGJYrqUeCnj1ItMcBFHWPvVUo5mAAclWqqXT2c7O3kn8SC3hs6LiHgOw7HC2-KUOHZpCjEK7FnzMSWAna_xAxwzmLzRZvzyR4y1i-8TbhmLBCUAAEfPsa5GgyVj98AunnYrTGFbIebivjQFTuGcC2LCdg_GLJkED01NGjfucz8TKJjCj8Gxa3QnH2ZcipuWUhWVUHPvgzHZe-w_TxeDOeywhDMKI9JWA2QiUM3pBtOpFLqmbd_dl7WMBDD_lw7xutd_UJTam_iuLegrr12cuEQJittPjU2a4YQ5SAqRcKbatpWFDrDsthws-65VTYSGgffeaODxqhQccffxpsoZpNQ9z5y6dydLbm02KFtQyjGQVkVztr00IBlvROvpALKGf8X4W1ZGeg7zEvsV8kyP084k_hUI6MWh6wqkVnF7SSV17RYtyumC1lq5VI-JdGn_YuyA7BZl4_Sgz8_fgeNXiC8z0qifLgrN2sczIe0CffI8piaJcUPxk8ADXBieKFniP5f3dG-AMDN8STr-B2nwFB_CNY7UDz76_s3FmVEnQTTU7MFqF5px-0ZgJhAJhWrOpAY93WGxfVRThfuMY1iGn-6_uuf6IrNAv5Cy7aGvagtrsi7F_UfGjbVbbB4bxQbcV-UqIHZP5wcgNU1JIW4Bztfjw5QUTidafK0ARwixIms3w1Dry4-O0KV-eksKbVrMtPAcL4GiiBaiuU-mpMDiT_c0yf2rCBeMOK_bAmkp7i8lrRMUQC4xYvav6-BDs8r3iuHEbvyBhO7i9Dlq1d5yT40AfmLvRs0lfU9S1d6_ltRRv3sNgUcuUiNxAMwHkco_LDoBxGg21-QCK6CahMJws-V4L60V7M-dRsIuhkIvCs0D7tSFmhBLMd33XEbOE0Jy79l0AgNMG0ChJPg8dvdSjBQ3ep_vAjQTgxJ_vWTy05yCwl6mFTl1zJWxcyjydP40t8D9SdAIQFXOXsxOaOGkauQGkO8t9igDMGIFCQWRJBW4JDC77EmIG3xHmhUnOZTUOmk2Y13SsSePHqLvXpEImCh-lG_Ehp5TgbK_mBfUUDOpZqSnEc3lHGC4z-9XMeOIGWYX689D07wskrtyNdEzShvTXWgrEPopU8c-D2XFw8X89i4f9wlVJ-Egn29eAhBCw4XaLwAA7_LUOXEVwlhYb7DmYxADRH_zXMl9gZE9_UJ_LkymE5ZoEMDT2mTv7cEUK9LPYkEBRmViKwxiFHwx2coDptiwVoR4-mLvPA7hzxRe7NjcreMJ6BEtpvhi4DwXeosAyHVQxd8R_Am7rw5Dtt5eap7Oca07-DDGWzSUzvpzt2Hde-_3NmrBxpOditdoDCuddXrXvyKByMIQmWnRtvIVhGgnGMjvYAkwNJBrd-1qxORsRMbhiBGX_l8HzgAYb6vuHjiaBhbo4a5_F-whRFJW1rO919s5ItElRg8Y6R3xI3xtKl3tza9Al_rKSvZ8lkiv44cUZ6a_rO8Zmi97pNd_aEgj1JhawhSHN3WGHkf7v5TdRC0CAZaxfDUrA4XupbrYuT1lZN7rnd_iIWvTEG2cFR08BcGyw0R9Yg=w1701-h1166',
    ogType: 'website',

    title: '스마트 빌딩이란?',
    date: '2024-01-30',
    tag1: '#스마트빌딩',
    tag2: '#IoT',
    tag3: '#위치추적',
    tag4: '#디지털트윈',

    // <br/>
    text: [
        {
            head: '스마트 빌딩이란?',
            subHead: '',
            desc: '스마트 빌딩은 IoT 센서, 인공지능, 자동화 시스템 등의 기술을 활용하여 건물 내의 다양한 시스템을 연결하고 통합하여 건물 운영을 최적화하는 건물입니다. 이를 통해 에너지 효율성을 향상시키고, 건물의 안전성, 편의성, 효율성을 모두 향상시킵니다. 이러한 기술을 통해 건물은 실시간으로 데이터를 수집하고, 조명, 난방, 냉방 및 보안 시스템과 같은 다양한 시설을 자동으로 조절할 수 있습니다. 스마트 빌딩은 에너지 효율성을 향상시키고 에너지 소비를 최적화하여 환경 친화적이며 경제적으로 운영됩니다. 사용자 편의성을 높이며 건물 운영 및 관리를 효율화하여 더 나은 생활 및 작업 환경을 제공합니다.',
            dot: ''
        },
        {
            head: '스마트 빌딩의 주요 기술',
            subHead: '센서 기술',
            desc: '스마트 빌딩은 다양한 센서를 사용하여 건물 내부 및 외부 환경을 실시간으로 모니터링합니다. 이러한 센서는 온도, 습도, 조명, 이산화탄소 농도, 미세먼지 수준, 움직임 등을 감지하며 데이터를 수집합니다. 또한 센서 데이터와 다른 기기와의 연결을 통해 스마트 빌딩은 실시간 정보 교환 및 제어를 가능하게 합니다. IoT 기술을 통해 스마트 빌딩은 다양한 시스템과 기기를 통합하여 효율적으로 운영될 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS (Real-Time Location System)',
            desc: 'RTLS 기술은 스마트 빌딩 내의 장비, 재고, 기자재 등의 위치를 실시간으로 추적할 수 있습니다. 이것은 장비의 손상, 분실, 유지 보수가 필요한 경우 등을 빠르게 감지하고 관리할 수 있는 기회를 제공합니다.<br/>만약 스마트 빌딩 내에서 비상 상황이 발생한 경우, RTLS 기술을 사용하여 대피 방향을 안내하고 건물 내의 모든 개체 및 인원의 위치를 파악할 수 있습니다. 이것은 구조 작업자나 비상 대응 팀이 효율적으로 대응할 수 있도록 도와줍니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '인공지능 (AI)',
            desc: '인공지능 기술은 스마트 빌딩의 자동화 시스템과 빅데이터 분석을 개선하는 데 사용됩니다. AI는 실시간으로 변화하는 데이터에 따라 시스템을 조정하고 사용자 요구에 맞게 최적화하는 데 도움을 줍니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '빅데이터 분석',
            desc: '수집된 데이터는 빅데이터 분석 기술을 활용하여 패턴을 식별하고 예측 모델을 개발하는 데 사용됩니다. 이를 통해 건물 운영을 최적화하고 문제를 조기에 탐지할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '디지털트윈',
            desc: '디지털트윈은 실제 건물의 물리적 모델을 디지털로 복제한 것입니다. 빌딩과 동일한 모습을 디지털 화면 속에 구현하여, 한눈에 파악하고 관리하기에 용이합니다. 이 디지털 모델은 건물의 운영과 유지보수를 지원하며, 실제 건물의 상태를 실시간으로 반영하여 문제를 진단하고 예측합니다.',
            dot: ''
        },
        {
            head: '스마트 빌딩의 장점',
            subHead: '',
            desc: '스마트 빌딩은 현대 건축 기술과 정보통신 기술의 융합을 통해 다양한 장점을 제공합니다. 이러한 장점들은 건물 운영, 효율성, 편의성, 환경 보호 등 다양한 측면에서 나타납니다.',
            dot: {
                a: '<strong> • 에너지 효율성 향상 : </strong>스마트 빌딩은 센서와 자동화 시스템을 통해 에너지 사용을 최적화합니다. 예를 들어, 조명, 난방, 냉방 시스템은 실시간 데이터를 기반으로 조절되어 에너지 소비를 줄이고 에너지 효율성을 향상시킵니다.<br/>또한 빅데이터 분석과 인공지능을 활용하여 예측 정비와 에너지 사용량 예측을 수행하여 에너지 비용을 절감할 수 있습니다.',
                b: '<strong> • 환경 친화적 : </strong>에너지 효율성 증가로 스마트 빌딩은 온실 가스 배출량을 감소시킵니다. 스마트 빌딩은 미세먼지 및 대기 오염을 모니터링하고 관리하여 공기 품질을 개선하고 건강한 환경을 조성합니다.',
                c: '<strong> • 비용 절감 : </strong>에너지 효율성 개선으로 에너지 비용이 감소하며, 자동화 시스템을 통해 인력 비용을 절감하고 유지보수 및 운영 비용을 최적화할 수 있습니다.',
                d: '<strong> • 편의성과 안전성 : </strong>스마트 빌딩은 사용자의 편의성을 향상시킵니다. 조명, 난방, 냉방, 보안 시스템 등을 자동으로 조절하므로 사용자는 편안하게 건물을 이용할 수 있습니다. 보안 시스템은 스마트 빌딩 내부 및 외부 환경을 모니터링하여 무단 침입을 방지하고 비상 상황에 대비할 수 있습니다.',
                e: '<strong> • 실시간 모니터링과 관리 : </strong>스마트 빌딩은 건물 운영자에게 실시간 데이터 및 경고를 제공하여 문제를 더 빨리 해결할 수 있도록 돕습니다. 따라서 건물의 건강 상태를 모니터링하고 유지 보수 일정을 최적화할 수 있습니다.',
            }
        },
        {
            video: video,
            link: '/enterprise/logistics/asset',
            head: '스마트 빌딩 사례',
            subHead: 'The Edge, 암스테르담, 네덜란드',
            desc: 'The Edge는 네덜란드 암스테르담에 위치한 혁신적인 스마트 빌딩으로, 최고의 에너지 효율성을 가진 건물 중 하나로 꼽힙니다. 건물 내에 수백 개의 센서가 설치되어 조명, 난방, 냉방을 최적화하고, 사용자의 위치와 활동에 따라 작동합니다.<br/>실시간 데이터와 빅데이터 분석을 통해 건물의 에너지 소비를 관리하고, 직원들의 편의성을 증가시키기 위한 다양한 서비스를 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: 'Microsoft Campus, 레드몬드, 미국',
            desc: '마이크로소프트 캠퍼스는 스마트 빌딩 및 스마트 시설 관리 기술을 사용하여 건물 및 시설을 관리합니다. 이 빌딩은 IoT 센서 및 빅데이터 분석을 활용하여 냉방, 난방, 조명 및 물 관리를 최적화하고 에너지 비용을 절감합니다. 이로써 사용자에게 건물 내 서비스를 제공하고 보안 시스템을 강화하여 직원과 손님의 편의성과 안전을 증대시킵니다.',
            dot: ''
        },
        {
            head: '',
            subHead: 'One Angel Square, 맨체스터, 영국',
            desc: 'One Angel Square은 영국의 Co-operative Group의 본사로서 스마트 빌딩 기술을 적극적으로 활용합니다. 이 빌딩은 높은 에너지 효율성을 위해 지열, 태양열 및 친환경 에너지 시스템을 통합하여 건물의 온도와 환경을 최적화합니다. 그리고 건물 내부 및 외부의 센서를 사용하여 실시간 데이터를 수집하고 빅데이터 분석을 통해 건물 운영을 관리합니다.',
            dot: ''
        },
        {
            head: '스마트 빌딩 전망',
            subHead: '',
            desc: '미래의 스마트 빌딩은 에너지 효율성을 강조하며, 빅데이터와 인공지능을 활용하여 최적화된 운영이 가능할 것입니다. IoT 기술과 센서 네트워크의 확산으로 실시간 모니터링과 조절이 가능하며, 사용자 편의성을 극대화할 것입니다. 또한 무결점의 보안 시스템을 갖추어 더 안전한 환경을 제공할 것으로 예상됩니다. 스마트 빌딩은 미래 도시 및 건물의 중심 역할을 하며, 에너지 효율성과 환경 친화성을 향상시키는 데 기여할 것입니다. 이러한 특징들은 건물 운영 및 사용자 경험을 혁신적으로 변화시킬 것입니다.',
            dot: ''
        },

    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: constructionSafety.thumb,
                data: 'constructionSafety',
                link: '/enterprise/construction/safety'
            },
            {
                img: constructionAsset.thumb,
                data: 'constructionAsset',
                link: '/enterprise/construction/asset'
            },
            {
                img: constructionEquipment.thumb,
                data: 'constructionEquipment',
                link: '/enterprise/construction/equipment'
            }
        ]


    }


}