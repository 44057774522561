import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";
import video from "@src/assets/images/solution/constructionEquipment/video/rtlsVideo.mp4"

export default {


    helmet: '| 스마트 공항 솔루션',
    desc: '여행자의 이동을 실시간으로 추적하고, 공항 내의 다양한 시스템을 통합하여 운영 효율성을 극대화합니다. 스마트 체크인, 자동화된 보안 검색, 디지털 탑승권 및 게이트 알림 등의 서비스가 가능합니다.',
    keywords: '스마트 공항, digital twin, 디지털트윈, nvr, ip, ptz, rfid, nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

    ogTitle: '',
    ogDesc: '',
    ogSite: '스마트 공항 솔루션 | 디지털트윈 전문 플랫폼, 오브로',
    ogUrl: 'https://orbro.io/blog/smart-airport-solution',
    ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDnB84Bj2f8mz7hiRJi4WKgwiYq0yzs4GDe7VfFWOdEiQPlqEtyMMpSU-r7CcKy3BQ9FE63-XkxXVh-VAf440HOH1GA2iUOdHrknQN9GTI5y3ciXRGd5tlYsj4DhnmSkYTQpZas8pKO2QTEY2Yr1aKg7FEDLt0kRSvFKTZggTvuJepKze-kTLdpe_706Vc5UevQyGXNClLc9oJpNJDRuQC6tUHKnn_FiW52O6uSCoGFs28ONxKiUPRFE5Ju29eGYw_NfFWK5qCYQkem15ivnuMMmQSfx2L1JMQGPPP__JQ8WwQTWf0fqJZrIOChm-Ytl3cTDljPsL84S4SK3ww7wvpdBVVnfeI_Hg9DIMDCgf2m4_GsjXhNuJb8f9tyjQix7woDC35BeyLXjFP9o2ERnbG7fyvuPfPIRBgK5nDmmQ-wLv8t6GiBIp0c9271KpuXrz_4fq6Pq0V18HWuznis8j9ij1ifWmZi0BufNpYrlKZ2RdY4lq_6Gr9vc4ZPYRfJ-NFKGBI03EKiA9vlIrzcQTS3aEzIVovappIABtvO8PxqCkqhF0amIzISUH2Y5efT1YtPEGCevRdIC2I9_V7SvrQTvm0Qnp6vF-xmdybPAnJr0BD0vT05fpxoeHcN_WbUEtUQcbFsq6v1TanDDnB-XLp6Hj1LBhEApO0KLMdKr6hqANaGkrweuLO-ppA1rq_sNPCwSoX3tInq-mGVn9-HfzIH4u1P52EJjPxNZh60gOpVuZqkyH0P7dKgHso5PfMXAHJJwBIhCrzzILpZN8rrqZZvgP7AKPfAS06cevU6M0PaN0pZiHywpvCcQVdty1VzWLryj5hS4cQmTfrC3GcAHFJBDMgSUdZAhbiB7MSwtMhcD4ye-9kOD85_PonAQG9QMZMyrDPuna6Rnfr3NNhtq5VLL4M2wMxmiUDvxHg1WMRsQIoR-KUoTQ7imcUxIkCvzVkMe0UgWhSU3oamalc9rvOKud9cDgvAzuC1Pi4TUFJEZoHIvWiSKf6fqf7_yqlGHA5CggXGmBUVlSUJKMz4AF25WfOC3t3lBYczZ7sdmtLr4oU5MSzzsuoU1ZRAsXu21sJkggnYNxqgP12eulaDnKgNC2v2tUoVFG3FM0dx4L9exFCMlSC4o25cBb7aCwOiAArJL-pUeJfoo0RWP79r8X-Fai40OnK_c9Mu3JLlhPbxgOsjIIIqErT8CygE-qJyrNdf7TY6j9Onn6HFhBE3IeCPrkrAbSVNLPT43ZP_PQeII9yOMYE1U9feIAsW6V2sC75YSru67__inv-Qj90ltj2FbqExBTfA2Yr-kLz4oeF05ygNcG4IyVyMb40jXPlED8eAhW-C3QHc4YQUCMvuu6Di-Lbgtce_nkq_428rQwjdjJDzejxpTy6tyIKxtYbtWMLS2siVXz5PQ07dAbC6fOPlh5x1vyFEsMroUS20vNOqxpVKPYNog4lmhOQ5_KeNNrSPhRy2_zHR0c5l3sOApfG7vMjyOHlwSXmthwYqL1_Hgz_WbJ_B1jLdHt8fpAOw6KWq_yUsDx0yS9mIzNjDitluARF04p2PJ=w2038-h1285',
    ogKey: '',
    ogType: 'website',

    twitterCard: '',
    twitterTitle: '',
    twitterDesc: '',

    title: '스마트 공항 솔루션',
    date: '2024-01-16',
    tag1: '#스마트공항',
    tag2: '#RTLS',
    tag3: '#디지털트윈',
    tag4: '#위치추적',

    // <br/>
    text: [
        {
            head: '스마트 공항 솔루션',
            subHead: '',
            desc: '스마트 공항은 현대적인 기술과 첨단 시스템을 활용하여 여행자들에게 최적의 서비스를 제공하는 공항을 의미합니다. 이는 다양한 스마트 기술과 디지털 혁신을 통해 효율성을 극대화하고 여행 경험을 혁신하는 것을 목표로 합니다.<br/>이는 실내 환경 조절, AI 기반 보안 시스템, RTLS 기술 등 다양한 스마트 기술을 활용하여 여행 경험을 혁신하고 효율성을 극대화합니다. 스마트 공항은 여행의 모든 단계에서 최적화된 서비스를 제공하여 고객 만족도를 높이는데 주력합니다.',
            dot: ''
        },
        {
            video: video,
            link: 'https://orbro.io/enterprise/construction/equipment',
            head: '스마트 공항 기술',
            subHead: '',
            desc: '스마트 공항은 다양한 첨단 기술을 활용하여 여행자들에게 혁신적이고 편리한 서비스를 제공합니다. 스마트 공항에 도입될 수 있는 기술에는 다음과 같은 것들이 있습니다:',
            dot: ''
        },
        {
            head: '',
            subHead: '실내 환경 조절 시스템',
            desc: '스마트 공항은 여행자들에게 최적의 환경을 제공하기 위해 실내 환경 조절 시스템을 도입하고 있습니다. 이를 통해 공항 내부의 온도, 습도, 공기질을 실시간으로 모니터링하고 조절함으로써 여행자들은 편안하게 대기할 수 있습니다. 또한, 스마트 조명 시스템은 자연광 모방과 에너지 효율을 향상시켜 여행자들에게 생활의 편리함을 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: 'AI 기반 보안 시스템',
            desc: '안전은 여행의 핵심 가치 중 하나입니다. 스마트 공항은 AI 카메라를 통해 고급 보안 시스템을 구축하고 있습니다. 얼굴 인식 기술, 행동 분석, 그리고 불법 행위 감지를 결합하여 보안 수준을 한층 높여 여행자들에게 안심감을 제공합니다. 또한, 실시간으로 이상 징후를 감지하여 즉각적인 조치를 취할 수 있습니다.',
            dot: ''
        },
        {
            head: '',
            subHead: 'RTLS(Real-Time Location System) 기술',
            desc: 'RTLS 이라 불리는 실시간 위치추적 기술을 활용하여, 여행자들의 실시간 위치를 추적합니다. 이를 통해 공항은 게이트, 상점, 수하물 수송 시스템과의 효율적인 연동을 가능케 하며, 여행자들의 이동을 최적화합니다. 또한, 동선 분석과 안전 관리에도 의미있는 데이터를 제공합니다.',
            dot: ''
        },
        {
            head: '',
            subHead: '데이터 분석 및 인공지능',
            desc: '빅데이터와 인공지능을 활용한 데이터 분석은 스마트 공항 운영을 최적화하는 데 큰 역할을 합니다. 여행자들의 이동 패턴, 서비스 이용 경향 등을 분석하여 효율적인 운영 및 개선점 도출에 활용됩니다. 이는 공항 관리자들이 리소스를 효과적으로 배치하고 여행자들에게 더 나은 서비스를 제공할 수 있도록 도와줍니다.',
            dot: ''
        },
        {
            head: '스마트 공항의 장점',
            subHead: '',
            desc: '',
            dot: {
                a: '<strong> • 편리한 여행 경험 : </strong>스마트 공항은 자동화된 수속 프로세스와 실시간 정보 제공 등을 통해 여행자들에게 높은 수준의 편의를 제공합니다. 자동 체크인, 얼굴 인식을 활용한 보안 검증 등은 여행자들이 원활하게 이동하고 서비스를 이용할 수 있도록 도와줍니다.',
                b: '<strong> • 안전과 보안 강화 : </strong>AI 카메라 및 얼굴 인식 기술을 활용한 스마트 보안 시스템은 여행자들의 안전을 높이고 공항 내부에서의 안전사고나 위협을 사전에 감지합니다. 이를 통해 보다 안전하고 신뢰성 있는 여행 환경을 제공합니다.',
                c: '<strong> • 효율적인 운영 및 리소스 관리 : </strong>데이터 분석 및 빅데이터 활용은 스마트 공항이 운영을 최적화하고 리소스를 효율적으로 관리하는 데 큰 도움을 줍니다. 이를 통해 인공지능 기술은 여행자들의 이동 패턴을 이해하고 필요한 자원을 최적화하여 운영 효율성을 높입니다.',
                d: '<strong> • 실내 환경 최적화 : </strong>실내 환경 조절 시스템은 여행자들이 편안한 환경에서 대기할 수 있도록 합니다. 온도, 습도, 공기질을 실시간으로 모니터링하여 조절하므로 여행자들의 편의를 높이고 품질 좋은 환경에서의 대기를 제공합니다.',
                e: '<strong> • 빠른 수속 및 출입 과정 : </strong>자동화된 수속 프로세스는 여행자들에게 번거로운 과정을 줄여주고, 빠른 체크인 및 출입 과정을 제공합니다. 이는 여행자들이 시간을 절약하고 편안하게 이동할 수 있도록 돕습니다.',

            }
        },
        {
            head: "스마트 공항 사례",
            subHead: "Hamad International Airport (DOH), 카타르",
            desc: "도하 국제공항은 스마트 기술을 적극적으로 도입하여 스마트 시티의 일부로서 고급 서비스를 제공하고 있습니다. 자동 로봇 안내 서비스, 스마트 보안 시스템, 모바일 어플리케이션을 통한 승객 편의 서비스 등이 도입되어 있습니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "Changi Airport (SIN), 싱가포르",
            desc: "창이 공항은 혁신적인 기술을 적극적으로 도입하여 세계적으로 최고의 공항 중 하나로 손꼽힙니다. 자동화된 체크인 시스템, 지능형 수하물 처리, 공항 내 스마트 스테이션 등이 여행자들에게 고급 편의를 제공하고 있습니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "Dubai International Airport (DXB), 아랍에미리트",
            desc: "두바이 국제공항은 스마트 기술과 혁신적인 서비스로 국제적인 여행 허브로 발전하고 있습니다. AI 기반의 스마트 보안 체계, 자율 주행 차량, 모바일 어플리케이션을 통한 승객 경험 개선 등이 도입되어 있습니다.",
            dot: ""
        },
        {
            head: "",
            subHead: "San Francisco International Airport (SFO), 미국",
            desc: "샌프란시스코 국제공항은 효율적인 운영과 스마트 서비스로 인해 주목받고 있습니다. IoT를 활용한 실내 환경 모니터링, 스마트 수하물 처리 시스템, 모바일 앱을 통한 승객 편의성 강화 등이 특징입니다.",
            dot: ""
        }

    ],

    link: {
        page: 'rtls',
        numb: '2',
        product: [
            {
                text: productPurchase.purchase.worker.workerTracking.title,
                title: productPurchase.purchase.worker.workerTracking.title,
                serial: productPurchase.purchase.worker.workerTracking.serial,
                desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
                img: productImg.product.workerTracking.thumbnail
            },
            {

            }

        ],
        solution: [
            {
                img: medicalPatient.thumb,
                data: 'medicalPatient',
                link: '/enterprise/medical/patient'
            },
            {
                img: retailExhibition.thumb,
                data: 'retailExhibition',
                link: '/enterprise/retail/exhibition'
            },
            {
                img: officeAsset.thumb,
                data: 'officeAsset',
                link: '/enterprise/office/asset'
            }
        ]

    }


}