export default {
  banner: {
    download: 'ダウンロード',
    request: '見積もりを取得',
  },
  modal: {

    title: '現在、データを準備中です。',
    desc: 'すぐにデータが更新される予定です。<br/>しばらくお待ちいただければ幸いです。',

    download: {
      title: 'データのダウンロード',
      desc: '以下の項目に情報を入力していただければ、メールでデータをお送りします。その他のお問い合わせは、',
      phone: 'pr@orbro.io',
      descTail: 'までお気軽にご連絡ください。',
      time: '平日 (10:00 - 19:00) (KST)',
      ph_email: 'example@orbro.io',

      agree1: '[必須]',
      agree2: '個人情報の取り扱い方針',
      agree3: 'に同意します。',

      confirm: 'ダウンロード',
      cancel: 'キャンセル'
    },
    subscribe: {
      title: '発送完了',
      desc: 'にデータを発送いたしました。<br/>オブロのニュースレターを購読して、テクノロジーや様々な情報を受け取りますか？',
      skip: 'スキップ',
      confirm: '購読する'
    },
    done: {
      title: '購読完了',
      desc: 'オブロのニュースレターを購読していただき、ありがとうございます。購読者の皆様には最新のニュースと情報をメールでお送りします。オブロと一緒にさらなる情報と知識を得てください。',
      confirm: '確認'
    }


  },

  request: {
    title: 'お問い合わせ',
    desc: '弊社のソリューションをご利用いただく場合は、以下の情報を入力し、「送信」ボタンをクリックしてください。',
    name: '山田太郎',
    placeholder: {
      name: 'お名前',
    },
  },
  contents: {
    title: 'さまざまなコンテンツを探索する',

    tag: {
      construction: '建設',
      logistics: '物流',
      office: 'オフィス',
      medical: '製造',
      manufacturing: '医療',
      retail: '小売り',

    }

  },
  news: {
    title: '最新ニュースを受け取る！',
  },
}
