import { StyledGridColumn, StyledGridContainer, StyledGridRow } from "@components/Grid/Grid.style";
import React, { useEffect, useState } from "react";
import {
  BoxLayout, BtnBox, BtnCancel, InputBox, Placeholder,
  PricingPage, PricingSpacingBottom, ProgressBar, ProgressBarBox, ProgressNumber,
} from "./Pricing.style";
import { StyledDisplay, StyledLabel } from "@components/Text";
import { Trans } from "react-i18next";
import { StyledSpacing24 } from "@pages/Enterprise/Technology/StereoVision/components/SVContent/StereoVision.style";
import pricing from "@src/lang/ko/pricing/pricing";
import { useLocation, useNavigate } from "react-router-dom";

import seo from '@src/lang/ko/seo';
import seoEN from '@src/lang/en/seo';
import seoJP from '@src/lang/jp/seo';
import seoCN from '@src/lang/cn/seo';
import SEO from '@pages/SEO';
import i18n from "@pages/i18n";
import axios from "axios";
import { ProgressedBarAnimation4_1, ProgressedBarAnimation4_2 } from "./BarAnimation";
import { DivFlexColumn, DivWidth100 } from "@pages/CommonStyle/commonDiv.style";
import { HeadingColor, LabelColor, Spacing } from "@pages/CommonStyle/common.style";


const Pricing4 = (): React.ReactElement => {


  const [ipAddress, setIp] = useState();

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        setIp(res.data.state)
      })
  }, [])


  let placeholder;
  let alertMsg;
  let emptyMsg;
  const location = useLocation();
  const currentDateTime = new Date();
  if (location.pathname.startsWith('/en/')) {
    placeholder = 'Please enter an email'
    alertMsg = 'Email format is invalid'
    emptyMsg = 'There are empty values'
  } else if (location.pathname.startsWith('/jp/')) {
    placeholder = 'メールを入力してください'
    alertMsg = 'メール形式が正しくありません'
    emptyMsg = '空の値があります'
  } else if (location.pathname.includes('/cn/')) {
    placeholder = "请输入电子邮箱。"
    alertMsg = "电子邮箱格式不正确"
    emptyMsg = "存在空值"
  } else {
    placeholder = '이메일을 입력해 주세요.'
    alertMsg = '이메일 형식이 올바르지 않습니다'
    emptyMsg = '비어있는 값이 있습니다'
  }

  const selectedIndexes = location.state && location.state.selectedIndexes ? location.state.selectedIndexes : [];
  const selectedProducts = location.state && location.state.selectedProducts ? location.state.selectedProducts : [];
  const selectedOptions = location.state && location.state.selectedOptions ? location.state.selectedOptions : [];
  const personalID = location.state && location.state.personalID ? location.state.personalID : '-';

  const name = location.state && location.state.name ? location.state.name : '';
  const phone = location.state && location.state.phone ? location.state.phone : '-';
  const memo = location.state && location.state.memo ? location.state.memo : '-';
  const schedule = location.state && location.state.schedule ? location.state.schedule : '-';
  const forward = location.state && location.state.forward ? location.state.forward : false;

  for (let i = 0; i < selectedProducts.length; i++) {
    //alert(pricing.step2.product[selectedIndexes[i]].title);
  }
  const [email, setEmail] = useState<string>('');

  useEffect(() => {

    const updateEmail = location.state && location.state.email ? location.state.email : '';

    if (updateEmail.length > 0) {
      setEmail(updateEmail);
    }
  }, [location.pathname])

  // Input event
  const handleInput = (event, callback) => {
    const value = event.target.value;
    callback(value);
  }


  const navigate = useNavigate();
  const handlePrevStep = () => {
    if (selectedProducts.length === 1 && selectedProducts.includes(5)) {
      if (currentLang === 'ko') { navigate('/pricing/2', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: false } }); }
      else { navigate(`/${currentLang}/pricing/2`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: false } }); }
    } else {
      if (currentLang === 'ko') { navigate('/pricing/3', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: false } }); }
      else { navigate(`/${currentLang}/pricing/3`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: false } }); }
    }
  }
  const handleNextStep = () => {
    if (email === '') {
      alert(emptyMsg)
      return;
    } else if (!email.includes('@') || !email.includes('.')) {
      alert(alertMsg)
      return;
    } else if (selectedProducts.length > 0) {
      notionSubmit(personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule);
      if (currentLang === 'ko') { navigate('/pricing/5', { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: true } }); }
      else { navigate(`/${currentLang}/pricing/5`, { state: { personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule, forward: true } }); }
    }
  }
  // 데이터를 Notion에 전송하는 함수
  const notionSubmit = async (personalID, selectedIndexes, selectedOptions, selectedProducts, email, name, phone, memo, ipAddress, schedule) => {

    try {
      const response = await fetch("https://orbro.io/pricingNotion", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          personalID: personalID,
          industries: convertString(selectedIndexes),
          products: convertString2(selectedProducts),
          demand: convertString3(selectedProducts, selectedOptions),
          email: email,
          name: name,
          phone: phone,
          memo: memo,
          schedule: schedule,
          ipAddress: ipAddress,
          type: '견적 문의',
          currentTime: currentDateTime.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // 24시간 형식
            timeZone: 'Asia/Seoul', // 한국 시간대
          })
        })
      });

    } catch (error) {
      console.log('error', error);
    }
  }

  const convertString = (selectedIndexes) => {
    const selectedIndustries = selectedIndexes.map(index => {
      return pricing.step1.industry[index].title;
    });
    return selectedIndustries.join(', ');
  }

  const convertString2 = (selectedProducts) => {
    const selectedProduct = selectedProducts.map(index => {
      return pricing.step2.product[index].title;
    });
    return selectedProduct.join(', ');
  }


  const convertString3 = (selectedProducts, selectedOptions) => {
    let combinedText = ''; // 문자열을 저장할 변수 초기화

    selectedProducts.forEach((i) => {
      selectedOptions[i].forEach((jData, j) => {
        jData.forEach((value, k) => {
          if (
            value === 1 &&
            j < pricing.step3.question[i].parts.length &&
            k < pricing.step3.question[i].parts[j].option.length
          ) {
            // 문자열을 추가
            combinedText += pricing.step3.question[i].parts[j].option[k].text + ' ';
            if (i !== selectedProducts.length - 1) {
              // i 값이 마지막이 아니면 쉼표를 추가
              combinedText += ', ';
            }
          }
        });
      });
    });

    return combinedText; // 결합된 문자열 반환
  };

  let seoPath;

  if (location.pathname.includes('/en/')) {
    seoPath = seoEN;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
  }
  const helmetTitle = seoPath.pricing.title;
  const ogKey = seoPath.pricing.key;
  const ogDesc = seoPath.pricing.desc;
  const ogImg = seoPath.pricing.img;
  const ogUrl = seoPath.pricing.url;
  const currentLang = i18n.language;
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "pr@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }


  const [count, setCount] = useState(forward ? 62 : 99);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count !== 89) {
        // forward 값에 따라 증가 또는 감소
        setCount(count + (forward ? 1 : -1));
      }
    }, 34); // 100ms 간격으로 증가

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 타이머 정리
  }, [count, forward]);


  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />
      <Spacing pixel="64" />
      <StyledGridContainer>

        <StyledGridRow>
          <StyledGridColumn desktop={2} tablet={1} mobile={0}>

          </StyledGridColumn>
          <StyledGridColumn desktop={8} tablet={6} mobile={4}>

            <PricingPage>
              {/** 타이틀 **/}
              <StyledDisplay size={"small"}>
                <Trans i18nKey={`pricing.step4.title`} />
              </StyledDisplay>
              <StyledSpacing24 />
              <StyledSpacing24 />

              {/** 진행 바 **/}
              <ProgressBarBox>
                <ProgressNumber>
                  <HeadingColor size="xxsmall" color={"#2A70F0"}>
                    {count + "%"}
                  </HeadingColor>
                </ProgressNumber>
                <ProgressBar>
                  {forward ? <ProgressedBarAnimation4_1 /> : <ProgressedBarAnimation4_2 />}
                </ProgressBar>

              </ProgressBarBox>

              <PricingSpacingBottom />
              <DivWidth100>
                <DivFlexColumn>
                  <StyledLabel size="small">
                    <Trans i18nKey={`pricing.step4.email`} />
                  </StyledLabel>
                  <Spacing pixel="8" />
                  <InputBox>
                    <Placeholder
                      i={1}
                      value={email}
                      placeholder={`example@orbro.io`}
                      onInput={(e) => { handleInput(e, setEmail) }}
                    />
                  </InputBox>
                </DivFlexColumn>
              </DivWidth100>


              {/** 다음 버튼 **/}
              <Spacing pixel="64" />
              <BoxLayout>
                <BtnCancel onClick={() => handlePrevStep()}>
                  <StyledLabel size="large">
                    <Trans i18nKey={`pricing.step2.prev`} />
                  </StyledLabel>
                </BtnCancel>
                <BtnBox isOk={selectedProducts.length > 0} onClick={() => handleNextStep()}>
                  <LabelColor size="large" color={"#FFF"}>
                    <Trans i18nKey={`pricing.step2.next`} />
                  </LabelColor>
                </BtnBox>
              </BoxLayout>
              <Spacing pixel="64" />

            </PricingPage>
          </StyledGridColumn>
        </StyledGridRow>
      </StyledGridContainer>

    </>
  )
};

export default Pricing4;


