import { medicalPatient, officeAsset, retailExhibition } from "@pages/Enterprise/Solution/components/Banner/BannerImages";
import productImg from "../main/product/productImg";
import productPurchase from "../main/product/productPurchase";

export default {


  helmet: '| RFID란?',
  desc: 'RFID는 "Radio-Frequency Identification"의 약자로, 무선 주파수 식별 기술을 의미하며, 무선 통신을 통해 데이터를 송수신하여 물체를 고유하게 식별하고 관리하는 데 사용됩니다.',
  keywords: 'rfid, nfc, uwb, 무선 통신, 위치 추적, 물체 위치 추적, 사람 위치 추적, 태그, 비콘, rtls, orbro, 오브로, 콩테크',

  ogTitle: '',
  ogDesc: '',
  ogSite: 'RFID란? | 위치추적과 디지털트윈 전문 플랫폼, 오브로',
  ogUrl: 'https://orbro.io/blog/rfid',
  ogImg: 'https://lh3.googleusercontent.com/fife/AGXqzDnHsX6sdpDkHidg6Qw1BNPI-8KHNunrbQX70t-RRiZFc_m0TXoYRKa1E9Qo01-Au848tK9S5Jzx1vOsreK1FoM7_WUn-aUPsNq2SoW1p0bgotbqEic-B-shh4JqyX1vRGz1BKJuuNybUGEV0Sntx7H_5LpXctuKvQDlllya8Qrt86WPh2Z8-5f8fKO1zJH2O8i96n9h1SvE8MHibKQqZzHRsPx4JboT3-j7Dq6nMb3Gsk-bMZkqaQVCKMuKmSG9s28DFXjSr2dDlwKXTgw6HPGwcGijF3sdo52c8MtyGSFhBtgtmGbjidDLlTEoYy5sTjCUl9KoRk5SbaOqCb-WHfrMzvlUE-KzSkFspk4Qe_oDLl4U2mcOBOC6tpykKzDDNY3P-si0yNBW_3kHTZd8ITgkwKE1AwK0hHOdS0FB7_AvrfgQW1VAIpkjYlJFHXsbP3Nac0v-XjWu9W-fozT8vIJC3P5c5imd_plMzSJorI4po9xbiqNGReeiJpbzilK2ym2B2i8MZT6w2SRjdf2nm2SU4Ru4aZR60ahb8bagWe_xc_ZzKNmdlZu5Avvkyc3_fUGnnqw3d3xcwaPdtRhYQul_0LDKxsgKrBjv2Co6CztB1mX2Et8-F5j68r2F9qy1yj6abH08yQokpafvTmxUsBYZ_i4OAw30nPlQkXflKfWFTob2y_o-X_FvVTGl4qerYTVBQLafVq7aSdlwkNpTvzZbXYkJMJasDN8JRO6mBJ8WhoJm9GOF5z75doXv-ds7jiNur5KLdA_UC2SR6-GpDrQPew6gUqn_1NvNGCXxkH9skkPX6gIuzc-9EAaXkSNajXWEc9_cRppxNODr4m1x_rl00fnHmVra7BhMXTjEgsB7rUJJw9OdKbTvB565TUXJXGMIfiFUAJfYBgm3FRZfJobff73T4LEa7d6e-znqcf64EINs_qnz2n-6yqf6_8Ww7A3P2K_iKCJymzKYy-dmjnxIW7c_msNP3sQZ0Ev0CpMW2neef16CpS2qPJjSc1Hv977oz4JSkrCL2GJ3RIUILnczDL8Ww96raGuIs058uNZu-pJwbIEbr9995dIPiHY-a6wstUW8xKthzZmdCFJ2JInX_Mos6jxXNorixlMOiseVcCXzuEcH_QEXGn9Mj7GzKkhAwgYeEq3D7EMlwjQ56-8mACmRW1ein59VVTW8JoXlBZ0enRBVmONwC4Y0aUUc_Hn70Zbktx4y1pKsehMCWy3GnGikZej6coDNKuKPnv8VE2ZBfCy8QdoI0i4P9rH2zFPqJWJEEgYmy3MpmnS5Asf8f1aScgjVS1S06wmBN_9Lpa-3cUJ-epljLU28BaHQWk9f2z4XojkWBWXVnGCidPhbxH-Cx4l-mNjXL8bh9PixgnFknFkWbwaqqGfjgS-GvOPqF0uGlIlEavfeovi1d_1opq7fxuM6wc-y17eImZEycGDKQuMRds6xvylNau1IgJTWCxWfnHArzBoJMxU91IlULuYkMFPAmoA-U2JjLAeiz35GX1eHmMQ3k_2JZmlfIvGQ73K2zf9ErNr-QzQPqixYph1iRaJi=w1984-h1214',
  ogKey: '',
  ogType: 'website',

  twitterCard: '',
  twitterTitle: '',
  twitterDesc: '',

  title: 'RFID란?',
  date: '2024-01-11',
  tag1: '#rfid',
  tag2: '#rtls',
  tag3: '#무선통신',
  tag4: '#위치추적',

  // <br/>
  text: [
    {
      head: 'RFID란?',
      subHead: '',
      desc: 'RFID는 "Radio-Frequency Identification"의 약자로, 무선 주파수 식별 기술을 의미하며, 무선 통신을 통해 데이터를 송수신하여 물체를 고유하게 식별하고 관리하는 데 사용됩니다.<br/>RFID 시스템은 주로 태그, 리더(또는 인터로게이터), 그리고 데이터베이스로 구성되어 있습니다. RFID 시스템의 주요 구성 요소인 태그는 소형 칩과 안테나로 구성되어 있습니다. 이 칩은 물체에 부착되거나 내장되어 있으며, 안테나는 무선 신호의 전송과 수신을 담당합니다. 리더는 안테나를 통해 태그와 통신하고, 데이터를 읽거나 쓰며, 이 정보를 데이터베이스와 연결하여 실시간으로 정보를 추적하거나 관리할 수 있습니다.',
      dot: ''
    },
    {
      head: 'RFID 활용 사례',
      subHead: '',
      desc: 'RFID 기술은 다양한 산업 분야에서 다양하게 활용되고 있습니다. 아래는 RFID의 주요 활용 사례 중 일부입니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '유통 및 물류 관리',
      desc: '',
      dot: {
        a: '<strong> • 재고 관리 : </strong>RFID를 사용하여 제품에 태그를 부착하고, 리더를 통해 실시간으로 재고를 추적함으로써 정확한 재고 정보를 제공합니다.',
        b: '<strong> • 자동 물류 처리 : </strong>물류 센터에서 제품을 운반하고 분류하는 과정을 자동화하여 물류 작업의 효율성을 높입니다.',
      }
    },
    {
      head: '',
      subHead: '제조업',
      desc: '',
      dot: {
        a: '<strong> • 제품 추적 및 생산 관리 : </strong>제조업체는 제품에 RFID 태그를 부착하여 생산 라인을 추적하고, 제품의 제조 및 배송 단계에서 실시간 모니터링을 수행합니다.',
      }
    },
    {
      head: '',
      subHead: '소매업',
      desc: '',
      dot: {
        a: '<strong> • 지능형 매장 : </strong>고객이 제품을 직접 가져가면 자동으로 청구서를 작성하고 결제하는 지능형 매장을 구현하기 위해 RFID 기술을 사용합니다.',
        b: '<strong> • 고객 경험 향상 : </strong>RFID를 이용하여 상품을 쉽게 찾을 수 있도록 하거나, 할인 및 프로모션을 자동으로 적용하여 고객 경험을 향상시킵니다.',
      }
    },
    {
      head: '',
      subHead: '의료 및 병원 관리',
      desc: '',
      dot: {
        a: '<strong> • 환자 식별 및 의료기기 관리 : </strong>RFID를 사용하여 환자를 정확하게 식별하고 의료기기의 위치와 상태를 모니터링하여 의료 기관의 효율성을 향상시킵니다.',
        b: '<strong> • 의약품 추적 : </strong>제조에서부터 환자에게 이르는 과정에서 의약품의 추적성을 강화하여 안전성을 확보합니다.',
      }
    },
    {
      head: '',
      subHead: '차량 및 교통 시스템',
      desc: '',
      dot: {
        a: '<strong> • 자동 통행 요금 : </strong>RFID를 이용하여 자동으로 통행 요금을 부과하고 교통체증을 줄이기 위해 차량을 추적합니다.',
        b: '<strong> • 차량 관리 및 유지보수 : </strong>차량에 RFID 태그를 부착하여 유지보수 이력을 추적하고 차량의 위치를 모니터링하여 효율적인 운영을 돕습니다.',
      }
    },
    {
      head: 'RFID 장점',
      subHead: '자동화 및 실시간 추적',
      desc: 'RFID는 물체를 무선으로 신속하게 식별하고 데이터를 수집할 수 있어, 수동 프로세스에 비해 빠른 속도로 작동합니다. 또한, 물체의 위치와 상태를 실시간으로 추적할 수 있어, 물류, 생산, 및 재고 관리를 효율적으로 수행할 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '정확한 데이터 수집',
      desc: '바코드 스캔과 비교하여 더 빠르고 정확한 데이터 수집이 가능하며, 이로 인해 데이터 입력 오류가 감소합니다. RFID는 시각적 가시성이 없는 상황에서도 자동으로 물체를 식별할 수 있습니다',
      dot: ''
    },
    {
      head: '',
      subHead: '비전 및 가시성 향상',
      desc: 'RFID를 활용하면 전체 공급망에서 물체의 이동을 투명하게 추적할 수 있어 가시성이 향상됩니다. 생산 라인이나 창고에서 RFID를 사용하여 실시간으로 작업 상태를 모니터링할 수 있습니다.',
      dot: ''
    },
    {
      head: '',
      subHead: '보안 강화',
      desc: 'RFID는 물체의 무단 이동을 탐지하고, 보안이 필요한 지역에 적용되어 무단 접근을 방지할 수 있습니다. RFID는 태그에 개별적인 권한을 부여하여 특정 사용자나 그룹만이 접근 가능하도록 설정할 수 있습니다.',
      dot: ''
    },
  ],

  link: {
    page: 'rtls',
    numb: '2',
    product: [
      {
        text: productPurchase.purchase.worker.workerTracking.title,
        title: productPurchase.purchase.worker.workerTracking.title,
        serial: productPurchase.purchase.worker.workerTracking.serial,
        desc: 'UWB 단말기를 작업자 또는 장비에 부착하여, 실시간으로 위치 추적을 돕는 제품입니다.',
        img: productImg.product.workerTracking.thumbnail
      },
      {

      }

    ],
    solution: [
      {
        img: medicalPatient.thumb,
        data: 'medicalPatient',
        link: '/enterprise/medical/patient'
      },
      {
        img: retailExhibition.thumb,
        data: 'retailExhibition',
        link: '/enterprise/retail/exhibition'
      },
      {
        img: officeAsset.thumb,
        data: 'officeAsset',
        link: '/enterprise/office/asset'
      }
    ]

  }


}