export default {

    banner: {
        download: '资料下载',
        request: '获取报价'
    },

    modal: {

        title: '当前正在准备数据',
        desc: '数据将很快更新。<br/>感谢您的耐心等待。',

        download: {
            title: '下载数据',
            desc: '请提供以下信息，我们将通过电子邮件将数据发送给您。如有其他问题，请',
            phone: 'pr@orbro.io',
            descTail: '与我们联系。',
            time: '工作日 (10:00 - 19:00) (KST)',
            ph_email: 'example@orbro.io',

            agree1: '[必填]',
            agree2: '隐私政策',
            agree3: '我同意。',

            confirm: '下载',
            cancel: '取消'
        },
        subscribe: {
            title: '发送完成',
            desc: '我们已向您的电子邮件发送了数据。<br/>您是否愿意订阅 Orbro 的通讯，获取技术和各种信息？',
            skip: '跳过',
            confirm: '订阅'
        },
        done: {
            title: '订阅完成',
            desc: '感谢您订阅 Orbro 的通讯。我们将通过电子邮件向您发送最新的新闻和信息。与 Orbro 一起探索更多信息和知识。',
            confirm: '确认'
        }
    },


    request: {
        title: '联系我们',
        desc: '如果您需要采用我们的解决方案，请填写以下信息后点击“提交”按钮。',

        name: '张三',

        placeholder: {
            name: '姓名'
        }
    },

    contents: {
        title: '了解更多内容',

        tag: {
            construction: '建筑',
            logistics: '物流',
            office: '办公',
            medical: '医疗',
            manufacturing: '制造',
            retail: '零售',
        }
    },

    news: {
        title: '获取最新消息！'
    }
}
