import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from 'react-helmet';
import NewsLetter from "../Common/NewsLetter/NewsLetter";
import { MainZlayout, StyledHome } from "./Home.style";
import i18n from "@pages/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import SEO from "@pages/SEO";

import seo from "@src/lang/ko/seo";
import seoEng from "@src/lang/en/seo";
import seoJP from "@src/lang/jp/seo";
import seoCN from "@src/lang/cn/seo";

import RTLS from "./components/RTLS/RTLS";
import Access from "./components/Access/Access";
import Beacon from "./components/Beacon/Beacon";
import Solution from "./components/Solution/Solution";
import Banner from "./components/Banner/Banner";
import ModalDone from "@pages/Enterprise/Download/ModalDone/ModalDone";
import { StyledGridContainer } from "@pages/Enterprise/Blog/Detail/BlogDetail.style";
import OS from "./components/OS/OS";
import DT from "./components/DT/DigitalTwin";

const Home = (): React.ReactElement => {

  const [windowWidth, setWindowWidth] = useState(650);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const onScrollButton = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const location = useLocation();
  const isEnglishPage = location.pathname.startsWith('/en/');
  const isJapanPage = location.pathname.startsWith('/jp/');
  const isChinaPage = location.pathname.startsWith('/cn/');

  const navigate = useNavigate();
  useEffect(() => {
    if (isEnglishPage) {
      i18n.changeLanguage("en").then(() => {
        navigate("", { replace: true });

      });
    } else if (isJapanPage) {
      i18n.changeLanguage("jp").then(() => {
        navigate("", { replace: true });

      });
    } else if (isChinaPage) {
      i18n.changeLanguage("cn").then(() => {
        navigate("", { replace: true });

      });
    } else {
      i18n.changeLanguage("ko").then(() => {
        navigate("", { replace: true });
      });
    }
  }, [location.pathname]);


  let seoPath;
  if (location.pathname.includes('/en/')) {
    seoPath = seoEng;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
  }

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const onSuccess = useCallback(() => {
    setSuccess(!isSuccess);
  }, [isSuccess]);


  const helmetTitle = seoPath.default.title;
  const ogKey = seoPath.default.key;
  const ogDesc = seoPath.default.desc;
  const ogImg = seoPath.default.img;
  const ogUrl = seoPath.default.url;
  const currentLang = i18n.language;
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ORBRO",
    "founder": {
      "@type": "Person",
      "name": {
        "ko": "이학경",
        "en": "Hak-kyoung LEE"
      }
    },

    "foundingDate": "2014-12-19",
    "description": "uwb, rtls, 비콘, 출입 시스템, 위치추적 등, 최고 수준의 기술로 만들어진 제품과 스마트 시티, 디지털트윈 솔루션을 제공합니다.",
    "url": "https://orbro.io",
    "logo": "https://orbro.io/images/c3f082e354b6ac033ed5147af21125ed.png",
    "image": "https://lh3.googleusercontent.com/fife/APg5EOYh9aOqkd00fKa4XLU2K8XNNXsW-hlEXH4NtB_sUseruIY4e9Bkzcz5ndnxOuKaLUc9lYLTSWYL4iES_8aAgOByVsue7toQpeIQlLIWRfBuSMQIGkoc53B9l90YjGlN2shwlaPtIrDkfxRVGIAXds8dga3dJZlnA3YUrD4TWi72j7S3HrpXdfJ6jxjgNiLo2i9-apXr8f1RM_1hFFPjMHSxeGbMdmKvqeU8ZuiuHi2Rsw2x5J62F2ni9sgjVLy3Uab-a1g8XkfcGHpuTi3c7cU4nJtiw-M2W7Q1TKkrFEYYW2Xm_FEwwEM3afo6934PgYkQ93BGZFCXm8BrSP0S7hdafO7oVIJ_N_4YWo97thiweofjssKRhYiKUSN43xg1B_PVwoovVcUWCMUvu6PMa_4tlX3UmHPQuBAiNcWsaQXWpUJsjZ_exvU0NeiX24Zo0PpHYleSklyMRuucH_orx1pxcuV_iSFWzvhsUCTk6SPeMNJZjuJh0DzYK9uHX1bzgpyIslvDLQnuUNObMy_laqHzk-y95TkQVtzcgLasj-E4TPfGnF-xATzL1cwzoL0wpghsJFbOfiaZxa-Mtkw_osdEVfieHqjW6AYM-Tx1u48S6WrG_TyOuUMQvZ4RDREspVX97yLnC1ublU1it5U6b3WV_RFTPaRHeDVhkGi1DYIW1XAr5zyapQ_AqDO8x0IRHGsJGnAE1fWI6XRRW-uUsCZlZGpSzHTTmAcQ3Ktq5vJq_LUINfe4_9Zj65XSizDXf6UfKCbyu5ahQXNZOIIhsXhVTYCW0TGracb_p7l2R5buSjoGxc7tNVvI9QkS3HeFMpLEFAsQADSDIusx2-JDigyRoNQgMF6IWxud2VXznSHLQXxP3VICvRXXCQCl7qNqfRdvtSoOHZ-Kfi38skS1Btj5zBmoxOgAZADxHq4wE2vK5y0pMZIvT_c7j3xTGKhmpT0XiTzhcLRWbgKszwOvByyQXWCukRjMQgsxS15GioyVRq6ZmRYHWUMRh_eI4B4VsKA12SXVbEDOETuYWZ51N6j8_CwL4kZ7LVKWlAa9-s4dgTSHDQzJv4qxdQAotcLm0cRbmn-at8Aq812zlS8BXrVA3Kv6DqfclPdPnwgg4np3i2IPtYbU7VxvhV75EfnUloa13iuEofBbQqx4x7CLdCWjbE27tyOXk8p_HaSOtYLxgOrW__l7HXdHLQNcvCIRv2lqNUpMIBAyDKGOw_LXmmU788M5a4P48DNHWIaBy_7tunMRescONY78i3ddmJY3MzMMHAnmqsNTiOAptLZv3kTv8eoGmXMGO2QPISQJZhAt9dScPcy6ujzQJz3pnbVpCPXyRb9BQuRbvSr-ScijGIF5c4TtqYCc57B39VXVRrN99dF3VbARUW4YOC1BzUbhfwjNxYsnaRCxPkrYAyV7hJfcvjeg9sFzC1B00e582ULnkQVjX4-n2tLCp1eNUryg4-_ZqmZ4qmU3iJolXpdd2Gys1sB0kjWA2Hx1JuAytbJZFaHfkX1Ij0Ya45t5y4txuyehBhw1=w2006-h1238",

    "email": "pr@orbro.io",
    "contactPoint": [{
      "@type": "ContactPoint",
      "addressCountry": "South Korea",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": [
      "https://orbro.io/en/",
      "https://www.kong-tech.com",
      "https://orbrohome.io",
      "https://orbrooffice.io",
      "https://plutocon.io",
      "https://blog.naver.com/orbro_platform"
    ]
  }

  return (
    <>

      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />

      <Helmet>
        <meta name="naver-site-verification" content="04fe05d2967f4106d1208b12dd340a588f5e0a36" />
        <meta name="google-site-verification" content="gPlJVme2PiHKi91PN-uqbfxwHTD3H1tQgt5-U35fWKI" />
        <meta name="google-site-verification" content="8QL5TXpyZhWp7hzmtfb7Ec1TWEE7NcLGyViyPMNBBiY" />
      </Helmet>

      <StyledHome>
        <Banner onScrollButton={onScrollButton} />
        <MainZlayout>
          <OS />
          <div ref={scrollRef}>
            <RTLS />
          </div>
          <Access />
          <Beacon />
          <DT />
          <Solution />
          <StyledGridContainer>
            {isSuccess && (
              <ModalDone onClickToggleModal={onSuccess}>
              </ModalDone>
            )}
            <NewsLetter onSuccess={onSuccess} />
          </StyledGridContainer>
        </MainZlayout>
      </StyledHome>
    </>
  )
};

export default Home;
