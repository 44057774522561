import React, { FC, useCallback, useEffect, useState } from "react";
import os from "@src/lang/ko/main/os/os"
import i18n from "@pages/i18n";
import seo from "@src/lang/ko/seo";
import seoEng from "@src/lang/en/seo";
import seoJP from "@src/lang/jp/seo";
import seoCN from "@src/lang/cn/seo";
import SEO from "@pages/SEO";
import CommonLang from "@pages/CommonStyle/commonLang";
import { Spacing, SpacingDTM } from "@pages/CommonStyle/common.style";
import { useLocation } from "react-router-dom";
import { CommonState } from "@redux/reducers/commonReducer";
import { useSelector } from "react-redux";
import BannerScroll from "../Common/Banner/BannerScroll";
import Banner from "./components/Banner/Banner";
import { StyledGridContainer } from "@components/Grid/Grid.style";
import NewsLetter from "@pages/Enterprise/Solution/components/Newletter/NewsLetter";
import Section from "./components/Section/Section";
import Functions from "./components/Functions/Functions";
import { DivHeight, FontPage } from "@pages/CommonStyle/commonDiv.style";



interface ProductProps {
  category: string;

}

const Os: FC<ProductProps> = ({ category }) => {


  const layoutType = useSelector((state: CommonState) => state.gridLayoutType);
  const location = useLocation();
  CommonLang();


  let seoPath;
  if (location.pathname.includes('/en/')) {
    seoPath = seoEng;
  } else if (location.pathname.includes('/jp/')) {
    seoPath = seoJP;
  } else if (location.pathname.includes('/cn/')) {
    seoPath = seoCN;
  } else {
    seoPath = seo;
  }

  const selectedOs = useSelector((state: CommonState) => state.selectedOs);
  const [product, setProduct] = useState<string>(selectedOs);

  useEffect(() => {
    const initialSelectedOs = localStorage.getItem('selectedOs');
    if (initialSelectedOs) {
      setProduct(initialSelectedOs);
    }
  }, []);


  const helmetTitle = seoPath.orbroOs.title;
  const ogKey = seoPath.orbroOs.key;
  const ogDesc = seoPath.orbroOs.desc;
  const ogImg = seoPath.orbroOs.img;
  const ogUrl = seoPath.orbroOs.url;


  const selectedValue = useSelector((state: CommonState) => state.selectedValue);
  const [selectedGroup, setSelectedGroup] = useState<number>(selectedValue);

  const handleIconClick = useCallback((group) => {
    setSelectedGroup(group);
  }, []);

  useEffect(() => {
    setSelectedGroup(selectedValue); // selectedValue 값을 selectedGroup에 할당

  }, [selectedValue]);


  const [isSuccess, setSuccess] = useState<boolean>(false);
  const onSuccess = useCallback(() => {
    setSuccess(!isSuccess);
  }, [isSuccess]);



  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": `${helmetTitle}`,
    "description": `${ogDesc}`,
    "alternateName": `${helmetTitle}`,
    "url": `${ogUrl}`,
    "keywords": `${ogKey}`,
    "image": `${ogImg}`,
    "address": "#B-1203, 660, Daewangpangyo-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea",
    "email": "pr@orbro.io",

    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+8215229928",
      "contactType": "a sales contact point",
      "areaServed": "KR",
      "availableLanguage": "Korean"
    }],
    "sameAs": "https://kr.linkedin.com/company/kongtech"
  }

  const currentLang = i18n.language;
  const count = os[product].index;

  return (
    <>
      <SEO
        lang={currentLang}
        helmetTitle={helmetTitle}
        ogKey={ogKey}
        ogDesc={ogDesc}
        ogImg={ogImg}
        ogUrl={ogUrl}
        jsonLd={jsonLd}
      />

      <BannerScroll product={product} selectedGroup={count} />

      <Banner layoutType={layoutType} />

      <FontPage>
        <StyledGridContainer>
          <Section section={'section1'} layoutType={layoutType} />
          <Section section={'section2'} layoutType={layoutType} />
          <Section section={'section3'} layoutType={layoutType} />
          <Section section={'section4'} layoutType={layoutType} />
          <DivHeight h={"64"} />
          <Functions layoutType={layoutType} />

        </StyledGridContainer>
      </FontPage>

      <Spacing pixel="64" />
      <NewsLetter />

    </>
  )
};

export default Os;